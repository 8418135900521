import AudioPauseIcon from "../../../../assets/icons/AudioPauseIcon";
import AudioPlayIcon from "../../../../assets/icons/AudioPlayIcon";

const AudioControls = ({ isPlaying, onPlayPauseClick }) => (
  <div className="audio-controls">
    {isPlaying ? (
      <button
        type="button"
        className="bg-white rounded-md p-3"
        onClick={() => onPlayPauseClick(false)}
        aria-label="Pause">
        <AudioPauseIcon />
      </button>
    ) : (
      <button
        type="button"
        className="bg-white rounded-md p-3"
        onClick={() => onPlayPauseClick(true)}
        aria-label="Play">
        <AudioPlayIcon />
      </button>
    )}
  </div>
);

export default AudioControls;
