// db.js
import Dexie, { Table } from "dexie";
export interface CachedToken {
  id?: number;
  token: string;
}
export class TokenRepo extends Dexie {
  // 'friends' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  appToken!: Table<CachedToken>;

  constructor() {
    super("linkcxo");
    this.version(1).stores({
      appToken: "++id, token", // Primary key and indexed props
    });
  }
}

export const tokenRepo = new TokenRepo();
