const LanguageCard = ({ userLanguages }) => {
  return (
    <div className="my-3">
      <div className="flex flex-wrap gap-4">
        {userLanguages.map((lang, index) => (
          <div
            key={index}
            className="w-[161px] h-[56px] border border-white rounded-xl text-center px-3 py-1 flex flex-col justify-center">
            <h1 className="text-base text-white font-semibold">
              {lang.language}
            </h1>
            <p className="text-[10px] text-white font-normal">{`${
              lang.reading === "Yes" ? "Read," : ""
            } ${lang.writing === "Yes" ? "Write," : ""} ${
              lang.speaking === "Yes" ? "Speak," : ""
            }`}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LanguageCard;
