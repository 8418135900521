import React from "react";
import OtpVerificationPage from "./OtpVerificationPage";
import CrossIcon from "../../assets/icons/CrossIcon";

const OtpVerificationPopup = ({ isOpen, onClose,emailOrPhone}) => {
  console.log(isOpen, ":isOpen");
  if (!isOpen) return null;

  return (
    <div className="">
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="sm:w-1/2 bg-lxgray-700 p-4 rounded shadow-lg relative">
          {/* <h2 className="text-lg font-bold">Add {type}</h2> */}
          <OtpVerificationPage emailOrPhone={emailOrPhone}/>
          <button
            onClick={onClose}
            className="mt-4  text-white px-4 py-2 rounded absolute top-0  right-0"
          >
            <CrossIcon width="10"/>
          </button>
        </div>
      </div>
    </div>
  );
};

export default OtpVerificationPopup;