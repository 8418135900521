import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";

import LxButton from "../../components/basic/LxButton";
import { useAppSelector } from "../../config/hooks";
import { LoginUIReduxState } from "../../slices/loginSlice";
// import LxInput from "@linkcxo/core/src/components/basic/LxInput";
import { customColor } from "../../utils/contant";
import InlineError from "../../components/basic/InlineError";

export interface OtpVerificationProps {
  onContinue: Function;
  onBack: Function;
  onResend: Function;
  ui: LoginUIReduxState;
  emailOrPhone:string
}

export default function OtpVerification({
  onContinue,
  onBack,
  onResend,
  ui,
  emailOrPhone
}: OtpVerificationProps) {
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(90);
  const [resendOtp, setResendOtp] = useState(true);
  const [btnActive, setBtnActive] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const timerRef = React.useRef(timer);

  const theme = useAppSelector((state) => state.appConfig.theme);
  useEffect(() => {
    if (ui.apiState.error && ui.apiState.message) {
      console.log(ui.apiState,"APISTATE")
      setTimer(0);
      setBtnActive(false);
      setShowError(true);
      setErrorMsg(ui.apiState.message);
    }
  }, [ui]);

  // Auto-focus the first input when the component mounts
  useEffect(() => {
    const firstInput = document.querySelector('input[name="otp-input-0"]') as HTMLInputElement;
    if (firstInput) {
      firstInput.focus();
    }
  }, []);

  const onChangeOTP = (text: string) => {
    setOtp(text);
    if (text.length == 6) {
      setBtnActive(true);
      onContinue({
        otp: parseInt(text),
        requestId: ui.requestId,
      });
    }
  };
  const onClickVerifyOtp = () => {
    if (otp.length == 6) {
      onContinue({
        otp: parseInt(otp),
        requestId: ui.requestId,
      });
    } else {
      setShowError(true);
      setErrorMsg("Invalid OTP");
    }
  };

  useEffect(
    function () {
      if (!resendOtp) {
        return;
      }

      const intervalId = setInterval(() => {
        timerRef.current -= 1;
        if (timerRef.current < 0) {
          clearInterval(intervalId);
          setResendOtp(false);
        } else {
          setTimer(timerRef.current);
        }
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    },
    [resendOtp]
  );

  const onResendClick = () => {
    onResend();
    setTimer(30);
    const intervalId = setInterval(() => {
      if (timer < 0) {
        clearInterval(intervalId);
        setResendOtp(false);
        //setTimer(20)
      } else {
        setTimer((timer) => timer - 1);
      }
    }, 1000);
  };

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex my-4 justify-end flex-col">
        <label className="sm:text-4xl mb-4 font-bold text-lxgray-600 dark:text-lxwhite-100 text-center">
          Enter OTP
        </label>
        <label className="text-xs sm:text-sm text-center font-medium text-lxgray-100 ">
         OTP sent on your {emailOrPhone}
        </label>
      </div>
      <div className=" ">
        <div className="flex  flex-1 my-2 justify-between items-center mb-8">
          <OtpInput
            containerStyle={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor:
                theme === "dark"
                  ? customColor.lxgray[700]
                  : customColor.lxwhite[100],
             
            }}
            inputStyle={{
              width: "45px",
              height: "45px",
              //color: customColor.lxwhite.
            }}
            onChange={(value) => onChangeOTP(value)}
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={(props,index) => {
              return (
                <input
                  {...props}
                  name={`otp-input-${index}`} 
                  autoFocus={index === 0}
                  className="dark:text-lxwhite-100 text-base ring-0 focus:ring-0 dark:focus:border-transparent
                  focus:outline-none border-2 border-lxgray-300
              dark:bg-lxgray-300 focus:dark:bg-lxwhite-100 rounded-full font-bold"
                />
              );
            }}
            value={otp}
          />
        </div>
        {showError && ui.apiState.error && <InlineError error={errorMsg} />}

        <LxButton
          color={btnActive ? "primary" : "secondary"}
          onClick={onClickVerifyOtp}
        >
          Confirm to Verify
        </LxButton>
      </div>
      <div className="mt-6 flex flex-col justify-center items-center">
        {timer > 0 ? (
          <label className="text-sm sm:text-lg font-bold text-lxgray-600 dark:text-lxwhite-100">
            <label className="sm:px-2 text-golden/[0.4]">Resend OTP </label>
            in {timer} seconds
          </label>
        ) : (
          <label className="sm:text-lg font-bold">
            <div onClick={() => onResendClick()}>
              <label className="text-sm sm:text-lg font-bold text-golden">
                Resend OTP
              </label>
            </div>
          </label>
        )}
        <label className="text-xs sm:text-sm text-lxgray-300 mt-3 mb-5">
          Didn't recieved the OTP
        </label>
      </div>
    </div>
  );
}
