import { Device } from "@capacitor/device";
import { Geolocation } from "@capacitor/geolocation";
import { Network } from "@capacitor/network";
import { useAppDispatch, useAppSelector } from "../config/hooks";
import {
  setDarkMode,
  setDevice,
  setLocation,
  setNetwork,
} from "./../slices/appSlice";
import { logEvent } from "./../slices/eventLoggerSlice";
import { ReactElement, useEffect } from "react";

export default function AppWrapper({ children }: { children: ReactElement }) {
  const dispatch = useAppDispatch();
  const theme = useAppSelector((state) => state.appConfig.theme);
  let isDarkMode = true;
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    isDarkMode = true;
  }

  if (isDarkMode) {
    dispatch(setDarkMode("dark"));
  } else {
    dispatch(setDarkMode("light"));
  }

  useEffect(() => {
    Device.getInfo().then((i: any) => {
      // set if paytm
      const ua = window.navigator.userAgent;
      // set if ios14
      if (i.operatingSystem === "ios" && i.osVersion.startsWith("14")) {
        i.ios14 = true;
      } else {
        i.ios14 = false;
      }
      dispatch(setDevice(i));
    });
    Network.addListener("networkStatusChange", (status) => {
      dispatch(setNetwork(status));
    });
  }, []);

  useEffect(() => {
    Geolocation.getCurrentPosition()
      .then((data: any) => {
        fetch(
          `https://nominatim.openstreetmap.org/reverse?lat=${data.coords.latitude}&lon=${data.coords.longitude}&format=json`
        )
          .then((response) => response.json())
          .then((data) => {
            dispatch(setLocation(data));
          })
          .catch((err) => {
            console.log(err);
          });
        dispatch(logEvent({ action: "location_permission_allow" }));
      })
      .catch((err) => {
        dispatch(logEvent({ action: "location_permission_deny" }));
      });
  }, []);
  return <div className={theme}>{children}</div>;
}
