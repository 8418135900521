import React from 'react'
import { useAppSelector } from '../../../config/hooks'
import CommonCard from '../BothCommon/CommonCard'
import { Link } from 'react-router-dom'

const AllEvents = () => {
    const {events} = useAppSelector((state)=>state?.events)
  return (
    <div className='flex flex-wrap gap-2 mx-auto px-7 py-11'>
       {
          events?.map((event,index)=> 
          <Link  key={event.id} to={ `/tab/events/${event.id}`}>
           <CommonCard details={{title:event?.title,detail:event?.details,offline:event?.isOffline,venue:event?.venue,author:`${event.author.firstName} ${event.author.lastName}`,startDate:event?.parsedStartTime,bannerImageUrl:event?.bannerImageUrl,members:event?.members,cardType:"event"}}/>
          </Link>
        )
        }
    </div>
  )
}

export default AllEvents
