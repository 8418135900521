import React, { useRef, useState } from 'react'
import OtpEmailPhone from './OtpEmailPhone'

const AddEmailPhone = ({type}:{type:string}) => {
    const [isOtpScreenOpen,setIsOptScreenOpen]=useState(false)
    const email=useRef()
    const phone=useRef()

    const handleSubmit=(e)=>{
        e.preventDefault()
    //    console.log(email.current?.value)
    setIsOptScreenOpen(true)
    }

  return (
    <div>
            {!isOtpScreenOpen && <form onSubmit={handleSubmit}>
                { type=="Email" &&
                    <div className='flex flex-col gap-5'>
                        <h1 className='text-lxgray-100 text-lg'>Email Address</h1>
                        <p className='text-xs text-lxgray-200'>Please note that your email address is considered the primary ID. You can modify it as per your preference.</p>
                         <div className='flex flex-col'>
                            <label className='text-xs text-lxgray-200'>Email ID</label>
                            <input type="text"
                            ref={email}
                            placeholder='Enter Email here '
                            required
                            className='bg-lxgray-700 border text-white border-lxgray-500 rounded p-2 ' />
                            </div>
                    </div>
                }
                { type=="Phone" &&
                    <div className='flex flex-col gap-5'>
                        <h1 className='text-lxgray-100 text-lg'>Phone Number</h1>
                        <p className='text-xs text-lxgray-200'>Your phone number helps us keep your account secure by adding an additional layer of verification. It also helps others, who already have your phone number, discover and connect with you. You can always decide how you want your phone number used.</p>
                        <div className='flex items-center gap-4'>
                             <label className='border text-white border-lxgray-500 p-2 rounded-lg ' >+91</label>
                            <input type="number"
                            ref={phone}
                            placeholder='Enter Phone Number'
                            className='bg-lxgray-700 border text-white border-lxgray-500 rounded p-2 w-full '
                            required />
                        </div>
                </div>

                }

                <div className='flex justify-end'>
                    <button className='bg-golden text-white px-10 py-2 rounded-lg font-semibold mt-4'>Save</button>
                </div>
            </form>}
            {
                isOtpScreenOpen && (
                    <OtpEmailPhone/>
                )
            }
                
    </div>
  )
}

export default AddEmailPhone
