import { baseUrl, contentUrl } from "../../../utils/contant";
import newimage from "../../../assets/images/Blank_Placeholders.png";
import background from "../../../assets/images/Blank_Placeholders.png";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { actionsOnMembers, removeMemberApi } from "../../../slices/eventSlice";
import {
  actionsOnClubMembers,
  removeClubMembersApi,
} from "../../../slices/clubSlice";
import ThreeDots from "../../../assets/icons/ThreeDots";
import EditDots from "../../../assets/icons/EditDots";
import { memo, useState } from "react";
import {
  checkConversationApi,
  createConversationApi,
} from "../../../slices/chatSlice";
import { useNavigate } from "react-router-dom";
import {
  CheckConversationApiFulfilledAction,
  CreateConversationAction,
} from "../../../models/chat";
import { dropDownByTitle } from "../../../utils/nocoFunctions";

interface Props {
  type: string | "INVITE" | "REQUEST" | "PARTICIPANT";
  fullName: string;
  picUrl: string;
  designation: string;
  organization: string;
  eventId?: string;
  clubId?: string;
  memberId: string;
  status: string;
  authorId: string;
  memberUserId: string;
}

const Participants = ({
  type,
  fullName,
  picUrl,
  designation,
  organization,
  eventId,
  clubId,
  memberId,
  status,
  authorId,
  memberUserId,
}: Props) => {
  const { token, userId } = useAppSelector((state) => state.login.session);
  const {designations } = useAppSelector((state) => state.appConfig);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isOpen, setOpen] = useState(false);

  const handleMessage = async (otherUserId) => {
    const response = (await dispatch(
      checkConversationApi({ members: [userId, otherUserId] })
    )) as CheckConversationApiFulfilledAction;

    if (response.payload === "No Conversations Found") {
      const newResponse = (await dispatch(
        createConversationApi({ members: [userId, otherUserId] })
      )) as CreateConversationAction;
      //  setShowNetwork(false)
      navigate(`/tab/messages/${newResponse.payload?.data.id}`);
    } else {
      // setShowNetwork(false)
      navigate(`/tab/messages/${response.payload?.data.id}`);
    }
  };

  const handleEventRequest = (action) => {
    dispatch(
      actionsOnMembers({ eventId: eventId, memberId: memberId, action: action })
    );
  };

  const handleEventCancelRequest = () => {
    dispatch(removeMemberApi({ eventId: eventId, memberId: memberId }));
    setOpen(!isOpen);
  };

  const handleClubRequest = (action) => {
    dispatch(
      actionsOnClubMembers({
        clubId: clubId,
        memberId: memberId,
        action: action,
      })
    );
    setOpen(!isOpen);
  };

  const handleClubCancelRequest = () => {
    dispatch(removeClubMembersApi({ clubId: clubId, memberId: memberId }));
    setOpen(!isOpen);
  };

  return (
    <div>
      {eventId && (
        <div className="flex gap-4 min-w-[360px] max-w-[350px] min-h-[150px] p-2 bg-lxgray-500 rounded ">
          <img
            src={picUrl ? `${baseUrl}/${contentUrl}/${picUrl}?t=${token}` : background}
            alt="ProfilePic"
            className="object-contain min-w-24 max-w-24 h-32 rounded-lg bg-lxgray-700"
          />
          <div className="flex flex-col gap-1 w-full ">
            <div className="flex items-center relative  justify-between ">
              <h1 className="text-white text-2xl font-semibold line-clamp-1">{fullName}</h1>
              {status === "ACCEPTED" && authorId !== memberUserId && (
                <div
                  className="cursor-pointer"
                  onClick={() => setOpen(!isOpen)}
                >
                  <EditDots />
                </div>
              )}
              {isOpen && (
                <div className="absolute right-4 top-3">
                  <ul className="bg-lxgray-400 cursor-pointer rounded">
                    <li
                      className="py-2 px-6 "
                      onClick={handleEventCancelRequest}
                    >
                      Remove
                    </li>
                    {/* <li className="py-2 px-6">Report</li> */}
                  </ul>
                </div>
              )}
            </div>

            <p className="text-lxgray-100 text-sm line-clamp-1">{dropDownByTitle(designation,designations)}</p>
            <p className="text-golden text-sm line-clamp-1">{organization}</p>
            {(type === "REQUEST" || type === "INVITE") &&
              status === "ACCEPTED" && (
                <button
                  onClick={() => handleMessage(memberUserId)}
                  className="text-white bg-golden w-fit px-10 py-2 font-semibold rounded"
                >
                  Message
                </button>
              )}

            {type === "REQUEST" && status !== "ACCEPTED" && (
              <div className="flex gap-3">
                <button
                  className="text-white bg-golden w-fit  px-4 py-2 font-semibold rounded hover:bg-white hover:text-golden"
                  onClick={() => handleEventRequest("accept")}
                >
                  Accept
                </button>
                <button
                  className="text-white bg-golden w-fit px-4 py-2 font-semibold rounded hover:bg-white hover:text-golden"
                  onClick={() => handleEventRequest("reject")}
                >
                  Reject
                </button>
              </div>
            )}

            {/* {(type==="REQUEST"||type==="INVITE") && status==="ACCEPTED" && <div className="flex gap-2">
            <button className="text-white bg-golden w-fit px-10 py-2 font-semibold rounded" onClick={handleEventCancelRequest}>Remove</button>
          </div>} */}

            {type === "INVITE" && status !== "ACCEPTED" && (
              <div className="flex gap-2">
                <button
                  className="text-white bg-golden w-fit px-10 py-2 font-semibold rounded"
                  onClick={handleEventCancelRequest}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {clubId && (
        <div className="flex gap-4 min-w-[360px] max-w-[350px] min-h-[150px] p-2 bg-lxgray-500 rounded border-b border-lxgray-200">
          <img
            src={picUrl ? `${baseUrl}/${contentUrl}/${picUrl}?t=${token}` : background}
            alt="ProfilePic"
            className="object-cover min-w-24 max-w-24 h-32 rounded-lg bg-lxgray-700"
          />
          <div className="flex flex-col   w-full">
            <div className="flex items-center relative  justify-between ">
              <h1 className="text-white text-2xl font-semibold ">{fullName}</h1>
              {status === "ACCEPTED" && authorId !== memberUserId && (
                <div
                  className="cursor-pointer"
                  onClick={() => setOpen(!isOpen)}
                >
                  <EditDots />
                </div>
              )}
              {isOpen && (
                <div className="absolute right-4 top-3">
                  <ul className="bg-lxgray-400 cursor-pointer rounded">
                    <li
                      className="py-2 px-6 "
                      onClick={handleClubCancelRequest}
                    >
                      Remove
                    </li>
                    {/* <li className="py-2 px-6">Report</li> */}
                  </ul>
                </div>
              )}
            </div>
            <p className="text-lxgray-100 text-sm line-clamp-1">{dropDownByTitle(designation,designations)}</p>
            <p className="text-golden text-sm line-clamp-1">{organization}</p>
            {(type === "REQUEST" || type === "INVITE") &&
              status === "ACCEPTED" && (
                <button
                  onClick={() => handleMessage(memberUserId)}
                  className="text-white bg-golden w-fit px-10 py-2 font-semibold rounded"
                >
                  Message
                </button>
              )}

            {type === "REQUEST" && status !== "ACCEPTED" && (
              <div className="flex gap-2">
                <button
                  className="text-white bg-golden w-fit  px-4 py-2 font-semibold rounded hover:bg-white hover:text-golden"
                  onClick={() => handleClubRequest("accept")}
                >
                  Accept
                </button>
                <button
                  className="text-white bg-golden w-fit px-4 py-2 font-semibold rounded hover:bg-white hover:text-golden"
                  onClick={() => handleClubRequest("reject")}
                >
                  Reject
                </button>
              </div>
            )}

            {/* {(type==="REQUEST"||type==="INVITE") && status==="ACCEPTED" && <div className="flex gap-2">
              <button className="text-white bg-golden w-fit px-10 py-2 font-semibold rounded" onClick={handleClubCancelRequest}>Remove</button>
            </div>} */}

            {type === "INVITE" && status !== "ACCEPTED" && (
              <div className="flex gap-2">
                <button
                  className="text-white bg-golden w-fit px-10 py-2 font-semibold rounded"
                  onClick={handleClubCancelRequest}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Participants;
