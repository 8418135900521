import { useMemo } from "react";
import EditIcon from "../../../assets/icons/EditIcon";
import Delete_Icon from "../../../assets/icons/Delete_Icon";
import { openProfileModal } from "../../../slices/profileSlice";
import { useAppDispatch } from "../../../config/hooks";
import useReadMoreToggle from "../../../utils/hooks/useReadMoreToggle";

const PublicationsCard = ({ editPublicationDetail, publicationData }) => {
  const dispatch = useAppDispatch();
  const { title, publisher, publicationDate, description, coAuthors, id } =
    publicationData;

  const { contentRef, showMore, isOverflowing, textToggleHandler } =
    useReadMoreToggle(description);

  const publishedDate = useMemo(() => {
    const inputDate = publicationDate;
    const dateObject = new Date(inputDate);

    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const formattedDate = dateObject.toLocaleDateString("en-US", options);
    return formattedDate;
  }, [publicationDate]);

  const toggleModalHandler = (type: string, isEdit: boolean) => {
    const modalTypes = {
      profileModalType: type,
      editForm: isEdit,
      editFormId: id,
    };
    dispatch(openProfileModal(modalTypes));
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="text-xl text-white font-bold leadin">{title}</h1>
        {editPublicationDetail && (
          <div className="flex flex-row-reverse  gap-4 self-start items-center justify-center">
            <div
              className="cursor-pointer"
              onClick={() => toggleModalHandler("publication", true)}>
              <EditIcon />
            </div>
            <div
              className="cursor-pointer"
              onClick={() => toggleModalHandler("publication?=delete", true)}>
              <Delete_Icon />
            </div>
          </div>
        )}
      </div>
      <p className="text-base text-golden font-semibold">
        By {publisher} | Coauthor - {coAuthors && coAuthors[0]}
      </p>
      <p className="text-base text-white font-normal my-2">
        Published on {publishedDate}.
      </p>
      <div>
        <p
          ref={contentRef}
          className={`text-sm text-lxgray-100 font-normal ${
            showMore ? "line-clamp-none" : "line-clamp-3"
          }`}>
          {description}
        </p>
        {description && isOverflowing && (
          <div className="flex justify-end items-center">
            <button
              className="border-none text-golden text-base font-normal w-[100px] text-center"
              onClick={textToggleHandler}>
              {showMore ? "View Less" : "View More"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PublicationsCard;
