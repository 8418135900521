import React from 'react'
import { baseUrl, contentUrl } from '../../utils/contant'
import { useAppSelector } from '../../config/hooks'
import { Link } from 'react-router-dom'



const OtherLists = ({title,imgUrl,description,description2,buttonText,urlLink}:{title:string,imgUrl:string,description:string,description2?:string|string[],buttonText:string,urlLink:string}) => {
  
    const {token}= useAppSelector((state)=>state.login.session)
    return (
    <div className='flex  items-center   '>
                  <img
                    src={                       
                         `${baseUrl}/${contentUrl}/${imgUrl}?t=${token}`
                    }
                    alt=""
                    className='w-20 h-20 rounded-full object-cover'
                    />
                  <div className='w-2/3 ml-5'>
                    <h2 className='text-base '>{title}</h2>
                    <p className=' text-sm text-lxgray-100 line-clamp-1 '>{description}</p>
                    <h3 className='text-sm text-lxgray-200'>{description2}</h3>
                  </div>
                  <div className='ml-1'>
                    <Link to={urlLink}>
                      <button className='text-golden border border-golden rounded-full px-7 py-1 hover:bg-golden hover:text-white'>{buttonText}</button>
                    </Link>
                  </div>
                 </div>
  )
}

export default OtherLists
