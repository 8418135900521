import React, { useEffect, useState } from "react";
import PublicationsCard from "../components/PublicationsCard";
import { CreatePublication } from "../../../models/user";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { useParams } from "react-router-dom";
import { getOtherUserDetailApi } from "../../../slices/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";

const UserPublication = () => {
  const dispatch = useAppDispatch();
  const { profileId } = useParams();
  const userData = useAppSelector((state) => state.user);

  const { userPublications } = userData;

  const [otherUserDetails, setOtherUserDetails] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(
        getOtherUserDetailApi({
          userId: profileId,
        })
      ).then(unwrapResult);
      setOtherUserDetails(response.data.data.userPublications);
    };
    if (profileId) fetchData();
    else setOtherUserDetails(null);
  }, [profileId]);

  if (profileId) {
    return (
      otherUserDetails && (
        <div className="w-full bg-lxgray-700 mt-5 self-start pb-4">
          <div className="w-[95%] mx-auto">
            <div className="w-full my-7">
              <h1 className="text-white text-xl font-bold">Publication</h1>
            </div>
            <div className="flex flex-col gap-4">
              {otherUserDetails.map(
                (publicationData: CreatePublication, index: number) => {
                  return (
                    <PublicationsCard
                      key={index}
                      publicationData={publicationData}
                      editPublicationDetail={false}
                    />
                  );
                }
              )}
            </div>
          </div>
        </div>
      )
    );
  }
  return (
    <div className="w-full bg-lxgray-700 mt-5 self-start pb-4">
      <div className="w-[95%] mx-auto">
        <div className="w-full my-7">
          <h1 className="text-white text-xl font-bold">Publication</h1>
        </div>
        <div className="flex flex-col gap-4">
          {userPublications &&
            userPublications.map(
              (publicationData: CreatePublication, index: number) => {
                return (
                  <PublicationsCard
                    key={index}
                    publicationData={publicationData}
                    editPublicationDetail={false}
                  />
                );
              }
            )}
        </div>
      </div>
    </div>
  );
};

export default UserPublication;
