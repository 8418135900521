import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseApiState } from "../models/base";
import { PaginatedPostMetadata, PaginatedPostRequest } from "../models/post";
import { addPosts, updatePosts } from "../slices/postSlice";
import feedService from "../services/feed.service";
import postService from "../services/post.service";
import { logout } from "./loginSlice";

interface HomeReduxState {
  postIds: string[];
  metadata?: PaginatedPostMetadata;
  apiState: BaseApiState;
}

const initialState: HomeReduxState = {
  postIds: [],
  metadata: {
    authorId: null,
    maxId: null,
    minId: null,
    size: 10,
    nextResultURL: null,
    previousResultURL: null,
  },
  apiState: {
    error: false,
    loaded: false,
    loading: false,
  },
};

export const getFeedsApi = createAsyncThunk(
  "home/getFeedsApi",
  async (
    req: PaginatedPostRequest,
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await feedService.getAllPost(req);
      // dispatch all posts to add it in postSlice
      dispatch(addPosts(response.data.data.posts));
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNextFeedApis = createAsyncThunk(
  "home/getNextFeedApis",
  async (a: any, { getState, rejectWithValue, dispatch }) => {
    try {
      const state: any = getState();
      const response = await postService.getPostByUrl(
        state.home.metadata.nextResultURL
      );
      // dispatch all posts to add it in postSlice
      dispatch(updatePosts(response.data.data.posts));
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getMyAllPost = createAsyncThunk(
  "home/getFeedsApi",
  async (
    req: PaginatedPostRequest,
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await postService.getAllPost(req);
      // dispatch all posts to add it in postSlice
      dispatch(addPosts(response.data.data.posts));
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFeedsApi.pending, (state: HomeReduxState, action) => {
      state.apiState = {
        error: true,
        loaded: true,
        loading: false,
      };
    });
    builder.addCase(getFeedsApi.rejected, (state: HomeReduxState, action) => {
      state.apiState = {
        error: true,
        loaded: true,
        loading: false,
      };
    });
    builder.addCase(getFeedsApi.fulfilled, addPostsToHomeSlice);
    builder.addCase(getNextFeedApis.fulfilled, addPostsToHomeSlice);
    builder.addCase(logout, (state) => {
      // Reset this slice's state to initial state
      return initialState;
    });
  },
});
const addPostsToHomeSlice = (state: HomeReduxState, action) => {
  state.apiState = {
    error: false,
    loaded: true,
    loading: false,
  };
  const arg = action.meta.arg;
  const apiRes = action.payload.data.data;
  if (apiRes.posts) {
    if (arg && arg.from == "load") {
      state.postIds = [...apiRes.posts.map((p) => p.id)];
    } else {
      state.postIds = [...state.postIds, ...apiRes.posts.map((p) => p.id)];
    }
  }
  state.metadata = apiRes.metadata;
};

export default homeSlice.reducer;
