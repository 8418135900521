import React from "react";

const InsightsIcon = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="34" height="34" rx="8" fill="#EBEBEB" />
      <path
        d="M2 28.5C2 29 2.375 29.375 2.87501 29.375H5.56252V27.625H2.87501C2.375 27.625 2 28 2 28.5Z"
        fill="url(#paint0_linear_1836_12771)"
      />
      <path
        d="M31.1251 27.625H9.625V29.375H31.1251C31.6251 29.375 32.0001 29 32.0001 28.5C32.0001 28 31.6251 27.625 31.1251 27.625Z"
        fill="url(#paint1_linear_1836_12771)"
      />
      <path
        d="M3.87501 25H30.1252C31.1877 25 32.0002 24.1875 32.0002 23.125V7.62489C32.0002 6.56238 31.1877 5.74988 30.1252 5.74988H3.87501C2.81251 5.74988 2 6.56238 2 7.62489V23.125C2 24.1875 2.81251 25 3.87501 25ZM13.5626 12.1874C13.5626 11.3124 14.5626 10.7499 15.3126 11.2499L20.0001 14.4374C20.6251 14.8749 20.6251 15.8124 20.0001 16.2499L15.3126 19.4375C14.5626 19.9375 13.5626 19.4375 13.5626 18.5V12.1874Z"
        fill="url(#paint2_linear_1836_12771)"
      />
      <path
        d="M7.56251 26.8125C7.125 26.8125 6.75 27.1875 6.75 27.625V29.4375C6.75 29.875 7.125 30.25 7.56251 30.25C8.00001 30.25 8.37501 29.875 8.37501 29.4375V27.625C8.37501 27.125 8.00001 26.8125 7.56251 26.8125Z"
        fill="url(#paint3_linear_1836_12771)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1836_12771"
          x1="3.78126"
          y1="27.625"
          x2="3.78126"
          y2="29.375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EB1F31" />
          <stop offset="1" stopColor="#EE3742" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1836_12771"
          x1="20.8126"
          y1="27.625"
          x2="20.8126"
          y2="29.375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EB1F31" />
          <stop offset="1" stopColor="#EE3742" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1836_12771"
          x1="17.0001"
          y1="5.74988"
          x2="17.0001"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EB1F31" />
          <stop offset="1" stopColor="#EE3742" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1836_12771"
          x1="7.56251"
          y1="26.8125"
          x2="7.56251"
          y2="30.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EB1F31" />
          <stop offset="1" stopColor="#EE3742" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default InsightsIcon;
