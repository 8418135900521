import PostCard from "../../../components/PostCard";
import ImagePost from "../../../components/ImagePost";
import PollCard from "../../../components/PollCard";

import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import QueryPost from "../../../components/QueryPost";
import { useRef } from "react";
import SurveyPost from "../../../components/SurveyPost";
import ArticlePost from "../../../components/ArticlePost";
import InfiniteScroll from "react-infinite-scroll-component";
import { getPostByUrl } from "../../../slices/postSlice";

const UserPosts = ({ feeds }) => {
  const dispatch = useAppDispatch();
  const openComments = useRef(null);
  const edit = useAppSelector((state) => state.profileData);
  const { postId } = edit;

  const getMoreDataHandler = () => {
    console.log("feeds :>> ", feeds?.metadata.nextResultURL);
    dispatch(getPostByUrl(feeds?.metadata.nextResultURL));
  };

  return (
    <div className="w-full mx-auto my-4 space-y-2">
      <InfiniteScroll
        className="space-y-2 pb-4 scrollbar-hidden"
        dataLength={feeds?.posts?.length}
        next={() => getMoreDataHandler()}
        hasMore={feeds?.metadata?.nextResultURL ? true : false}
        height={1800}
        loader={""}
      >
        {feeds.posts &&
          feeds.posts.map((userContent) => {
            const { postType, id, content, metadata } = userContent;
            const { attachments } = metadata;

            if (postType === "POST") {
              return (
                <PostCard userContent={userContent} key={id} postId={postId}>
                  <ImagePost userContent={userContent} attachments={attachments} />
                </PostCard>
              );
            } else if (postType === "POLL") {
              return (
                <PostCard userContent={userContent} key={id} postId={postId}>
                  <PollCard userContent={userContent} />
                </PostCard>
              );
            } else if (postType === "QUERY") {
              return (
                <PostCard
                  userContent={userContent}
                  key={id}
                  postId={postId}
                  openComments={openComments}
                >
                  <QueryPost
                    userContent={userContent}
                    openComments={openComments}
                  />
                </PostCard>
              );
            } else if (postType === "SURVEY") {
              return (
                <PostCard userContent={userContent} key={id} postId={postId}>
                  <SurveyPost userContent={userContent} />
                </PostCard>
              );
            } else if (postType === "ARTICLE") {
              return (
                <PostCard userContent={userContent} key={id} postId={postId}>
                  <ArticlePost userContent={userContent} />
                </PostCard>
              );
            }
          })}
      </InfiniteScroll>
    </div>
  );
};

export default UserPosts;
