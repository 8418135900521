const error = {
  "AAS-00105": "Country not allowed",
  "AAS-00106": "Invalid Phone no",
  "AAS-00107": "Invalid Email address",
  "AAS-00108": "Either Email of Phone no is required",
  "AAS-00109": "Invalid credentials",
  "AAS-00110": "Invalid OTP",
  "AAS-00111": "OTP expired",
  "AAS-00112": "Invalid token",
  "AAS-00113": "Unable to validate token",
  "AAS-00115": "Email or Phone already exists",
};

export default function apiErrorToText(code: string) {
  return error[code] ? error[code] : code ? code : "Something went wrong";
}
