import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { baseUrl ,contentUrl} from '../../utils/contant'
import background from "../../assets/images/default_profile_banner.png"
import SmallCross from '../../assets/icons/SmallCross'
import Search from '../../assets/icons/Search'
import { addEmoloyeeApi, getAllEmployeesApi, removeEmployeeApi, updateEmployeeRoleApi } from '../../slices/companySlice'
import { getMyNetworksApi } from '../../slices/networkSlice'
import { dropDownByTitle } from '../../utils/nocoFunctions'

const ManageAdmin = () => {
    const dispatch=useAppDispatch()
    const {company,employees}=useAppSelector((state)=>(state.company))
    const {designations}=useAppSelector((state)=>(state.appConfig))
    const {myNetworks}=useAppSelector((state)=>state.networks)
    const { token } = useAppSelector((state) => state.login.session);
    const [selectedRole, setSelectedRole] = useState("");
    const [showAdd,setShowAdd]=useState("")
    const [open,setOpen]=useState(false)
    const [searchQuery, setSearchQuery] = useState("");
    useEffect(()=>{

        dispatch(getAllEmployeesApi({id:company?.id}))
        dispatch(getMyNetworksApi())
        
    },[company?.id])

    const handleRoleChange=(event,userId)=>{
        const pageRole = event.target.value;
        setSelectedRole(pageRole);
        console.log(pageRole,userId)
        dispatch(updateEmployeeRoleApi({userId,pageRole,companyId:company?.id}))

    }

    const handleRemoveEmployee=(userId)=>{
        dispatch(removeEmployeeApi({companyId:company?.id,userId:userId}))
    }

    const handleAddEmployee=(pageRole,userId)=>{
        console.log(userId)
        dispatch(addEmoloyeeApi({pageRole,companyId:company?.id,userId:userId}))
        setTimeout(()=>{
            dispatch(getAllEmployeesApi({id:company?.id}))
        },1000)
        setOpen(false)
    }

    // console.log(myNetworks?.filter((network)=>!employees?.some(emp=>emp.userId===network.otherUserDetails.userId)),"networks")
    const filteredNetworks = myNetworks?.filter(network =>
        network.otherUserDetails.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        network.otherUserDetails.lastName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    console.log(company)
  return (
    <>
    
    <div className='flex justify-center pt-16 px-2 pb-4 min-h-[400px]'>
        <div className=' w-1/2  px-4 border-r border-lxgray-200'>
            <h1 className='text-white font-semibold relative -top-10'>Manage Admin</h1>
           
            <div className='flex items-center flex-col border-b border-lxgray-200 '>
                <img
                    src={`${company?.profileImage? `${baseUrl}/${contentUrl}/${company.profileImage}?t=${token}`:background}`}
                    alt="Banner Image"
                    className=" h-16 w-16 rounded-full object-cover border border-white"
                />
                <h2 className=" font-semibold ">{company?.title}</h2>
                <h2 className='text-lxgray-200 text-sm mt-2'>{company?.location}</h2>
                <h1 className='text-lxgray-100 text-sm mb-4'>{company?.tagline}</h1>
            </div>

            
            <div className='max-h-72 overflow-x-scroll no-scrollbar'>
                {  employees?.map((ele)=>{
                    return (
                        <div className='flex items-center justify-between gap-2 py-3 border-b border-lxgray-200 min-w-64'>
                        <div className='flex items-center gap-2 w-2/3'>
                            <img
                                src={`${ele.user.picUrl? `${baseUrl}/${contentUrl}/${ele.user.picUrl}?t=${token}`:background}`}
                                alt="Banner Image"
                                className=" h-12 w-12 rounded-full object-cover border border-white"
                            />
                            <div>
                                <h2 className="text-lxgray-100 text-sm line-clamp-1">{`${ele.user.firstName} ${ele.user.lastName}`}</h2>
                                <h2 className='text-lxgray-200 text-xs line-clamp-1'>{dropDownByTitle(ele.user.designation,designations)}</h2>
                                <h3 className='text-golden text-xs line-clamp-1'>{ele.user.organization}</h3>
                            </div>
                        </div>
                        <div >
                            {(ele.pageRole==="superadmin")  && <p className='text-lxgray-100 text-xs'>Superadmin</p>}
                            { (ele.pageRole==="admin" && company?.PageRole==="superadmin") &&  <div className='flex justify-center items-center gap-3'>
                                
                                { <select value={ele.pageRole} onChange={(e)=>handleRoleChange(e,ele.id)} className='bg-lxgray-500 text-lxgray-100 text-xs border border-lxgray-100 rounded'>
                                    <option value="superadmin" className=''>Super Admin</option>
                                    <option value="admin">Admin</option>
                                    <option value="contentadmin">Content Admin</option>
                                    <option value="hiringmanger">Hiring Manager</option>
                                </select>}
                                <div onClick={(e)=>handleRemoveEmployee(ele.id)} className='cursor-pointer'><SmallCross/></div>
                            </div>
                            }
                            {
                              (ele.pageRole!=="admin" && ele.pageRole!=="superadmin" && company?.PageRole!=="superadmin") &&  <div className='flex justify-center items-center gap-3'>
                                
                              { <select value={ele.pageRole} onChange={(e)=>handleRoleChange(e,ele.id)} className='bg-lxgray-500 text-lxgray-100 text-xs border border-lxgray-100 rounded'>
                                  <option value="superadmin" className=''>Super Admin</option>
                                  <option value="admin">Admin</option>
                                  <option value="contentadmin">Content Admin</option>
                                  <option value="hiringmanager">Hiring Manager</option>
                              </select>}
                              <div onClick={(e)=>handleRemoveEmployee(ele.id)} className='cursor-pointer'><SmallCross/></div>
                          </div>  
                            }
                            
                            {(ele.pageRole==="admin" && company?.PageRole!=="superadmin")  && <p className='text-lxgray-100 text-xs'>admin</p>}

                            { (ele.pageRole==="hiringmanger" && company?.PageRole==="superadmin") &&  <div className='flex justify-center items-center gap-3'>
                                
                                { <select value={ele.pageRole} onChange={(e)=>handleRoleChange(e,ele.id)} className='bg-lxgray-500 text-lxgray-100 text-xs border border-lxgray-100 rounded'>
                                    <option value="superadmin" className=''>Super Admin</option>
                                    <option value="admin">Admin</option>
                                    <option value="contentadmin">Content Admin</option>
                                    <option value="hiringmanger">Hiring Manager</option>
                                </select>}
                                <div onClick={(e)=>handleRemoveEmployee(ele.id)} className='cursor-pointer'><SmallCross/></div>
                            </div>
                            }

                            { (ele.pageRole==="contentadmin" && company?.PageRole==="superadmin") &&  <div className='flex justify-center items-center gap-3'>
                                                            
                                { <select value={ele.pageRole} onChange={(e)=>handleRoleChange(e,ele.id)} className='bg-lxgray-500 text-lxgray-100 text-xs border border-lxgray-100 rounded'>
                                    <option value="superadmin" className=''>Super Admin</option>
                                    <option value="admin">Admin</option>
                                    <option value="contentadmin">Content Admin</option>
                                    <option value="hiringmanger">Hiring Manager</option>
                                </select>}
                                <div onClick={(e)=>handleRemoveEmployee(ele.id)} className='cursor-pointer'><SmallCross/></div>
                            </div>
                            }
                            
                        </div>

                    </div>
                    )
                }) 
                }
            </div>
        </div>



        <div className='w-1/2 px-4'>
            <div className='relative'>
                <input value={searchQuery} onChange={(e)=>setSearchQuery(e.target.value)} type="text" placeholder='Search your connection' className='text-lxgray-500 rounded-full w-full py-1 px-8'/>
                <div className='absolute top-2 left-2'><Search/></div>
                <div>
                    <p className='text-xs text-lxgray-200 px-2 my-2'>Based on your connections, the following list of members is provided below.Additionally, to expand beyond your existing connections, you'll need to establish a connection with the admin profile</p>
                </div>
            </div>
            
            <div className='max-h-80  overflow-x-scroll no-scrollbar pt-2'>
                {  filteredNetworks?.filter((network)=>!employees?.some(emp=>emp.userId===network.otherUserDetails.userId)).map((ele)=>{
                    return (
                        <div className='flex  justify-between items-center py-3 border-b border-lxgray-200'>
                        <div className='flex items-center gap-2 w-2/3'>
                                    <img
                                        src={`${ele.otherUserDetails.picUrl? `${baseUrl}/${contentUrl}/${ele.otherUserDetails.picUrl}?t=${token}`:background}`}
                                        alt="Banner Image"
                                        className=" h-12 w-12 rounded-full object-cover border border-white"
                                    />
                                    <div>
                                        <h2 className="text-lxgray-100 text-sm line-clamp-1 ">{`${ele.otherUserDetails.firstName} ${ele.otherUserDetails.lastName}`}</h2>
                                        <h2 className='text-lxgray-200 text-xs line-clamp-1'>{dropDownByTitle(ele.otherUserDetails.designation,designations)}</h2>
                                        <h3 className='text-golden text-xs line-clamp-1'>{ele.otherUserDetails.organization}</h3>
                                    </div>
                        </div>
                        <div className='relative'>
                            <button onClick={()=>{setShowAdd(ele.id); setOpen(!open)}} className='bg-lxgray-400 text-white px-4 py-1 rounded text-sm'>+ Add</button>
                            
                            { open && showAdd===ele.id && <div className='absolute -top-8 -left-28 bg-lxgray-200 text-white text-sm z-10 text-center rounded'>
                              
                                <p onClick={()=>handleAddEmployee("superadmin",ele.otherUserDetails.userId)} className='hover:text-lxgray-100 cursor-pointer hover:bg-lxgray-400 px-2 '>Super Admin</p>
                                <p  onClick={()=>handleAddEmployee("admin",ele.otherUserDetails.userId)} className='hover:text-lxgray-100 cursor-pointer hover:bg-lxgray-400 px-2 '>Admin</p>
                                <p  onClick={()=>handleAddEmployee("contentadmin",ele.otherUserDetails.userId)} className='hover:text-lxgray-100 cursor-pointer hover:bg-lxgray-400 px-2 '>Content Admin</p>
                                <p  onClick={()=>handleAddEmployee("hiringmanger",ele.otherUserDetails.userId)} className='hover:text-lxgray-100 cursor-pointer hover:bg-lxgray-400 px-2 '>Hiring Manager</p>
                           
                            </div>}
                        </div>

                    </div>
                    )
                })
                
                    }
              </div>
        </div>
      
    </div>
    {/* <div className='py-2 border-t border-lxgray-200 flex justify-end '>
        <button className='text-white bg-golden px-6 py-1 rounded mr-6'>Save</button>
    </div> */}
    </>
  )
}

export default ManageAdmin
