import apiClient from "../config/axiosConfig";
import { AxiosPromise } from "axios";
import { ApiResponse } from "..//models/base";
import {
  PaginatedPostRequest,
  PaginatedPostResponse,
  Post,
  PostResponse,
} from "../models/post";

class FeedService {
  getAllPost(
    req: PaginatedPostRequest
  ): AxiosPromise<ApiResponse<PaginatedPostResponse>> {
    return apiClient.get(`/v1/feeds`);
  }
  getPostByUrl(
    nextUrl: string
  ): AxiosPromise<ApiResponse<PaginatedPostResponse>> {
    return apiClient.get(nextUrl);
  }
}

const feedService = new FeedService();
export default feedService;
