import React from 'react'
import banner from "../../assets/images/Blank_Placeholders.png"

const Advertisement = () => {
  return (
    <div className='rounded '>
          <div className='h-fit  flex flex-col items-center bg-lxgray-500 rounded text-lxgray-200'>
            <img src={banner} alt="BANNER" className='w-80 h-60 object-cover p-2' />
            <h1>AdvertiseMent</h1>
            <p>You may also Know</p>
          </div>  
        </div>
  )
}

export default Advertisement
