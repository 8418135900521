import React from "react";
import { Link } from "react-router-dom";
import {baseUrl,contentUrl}from  '../../../utils/contant'
import vector from "../../../assets/icons/Vector.png";
import jobLogo from "../../../assets/images/Blank_Placeholders.png";
import { useAppSelector } from "../../../config/hooks";
import { LastAppliedDay } from "../../../utils/dateUtils";
import { multipleValueDropdown } from "../../../utils/nocoFunctions";

const arr = [1, 2, 3];

const RecommendedJobs = () => {
  // const { path } = useRouteMatch();
  const {userId,token}=useAppSelector((state)=>state.login.session)
  const {preferredLocation}=useAppSelector((state)=>state.appConfig)
  const data = useAppSelector((state) => state.job?.recommendedJobList);

  const jobs = Object.values(data?.entities);

  return (
    <div className="dark:bg-lxgray-700 px-4">
      <div className="flex justify-between py-2">
        <h2 className="text-white font-bold">
          Recommended Jobs <span className="tracking-wider">({jobs.length})</span>
        </h2>
      </div>

      
      <div className="grid grid-cols-2 gap-2">   
      {jobs.slice(0, 8).map((ele, i) => {
        const { title, company,  jobType ,authorType,author} = ele;
        return (
          <Link key={ele.id} to={`/tab/jobs/description/Recommended/${ele.id}`}>
            <div key={i} className="flex  bg-lxgray-600 p-1 mb-1 items-center hover:bg-lxgray-800/50 rounded pl-4 ">
              <div>
                <img src={`${authorType==="COMPANY"?`${baseUrl}/${contentUrl}/${author.picUrl}?t=${token}`:jobLogo}`} alt="image" className="object-cover border border-lxgray-200 rounded-lg w-24 h-24" />
              </div>
              <div className="px-6  py-2">
                <p className="text-lg text-white line-clamp-1">{title.length<28?title:`${title.slice(0,28)}...`}</p>
                <p className="text-sm text-lxgray-400">{(!company||ele.authorId===userId)?company:"Confidential"}</p>
                <p className="text-xs text-lxgray-400 mb-2 line-clamp-1">
                  {multipleValueDropdown(ele?.location,preferredLocation)} <span className="text-golden"> ({jobType})</span>
                </p>
                <div className="flex items-center">
                  <img src={vector} alt="vector" className="pr-1" />
                  <p className="text-xs text-lxgray-400">
                    {LastAppliedDay(ele.createdAt) === 0
                      ? "Today"
                      : `${LastAppliedDay(ele.createdAt)} day ago`}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        );
      })}

    </div>
      <div className="text-end text-lg font-semibold text-lxgray-400 py-2 hover:text-golden ">
        <Link to={`/tab/jobs/recommended`}>
          <button className="">Show all</button>
        </Link>
      </div>
    </div>
  );
};

export default RecommendedJobs;
