import React, { useEffect, useRef, useState } from 'react'
import { useForm,Controller } from 'react-hook-form';
import UploadIcon from '../../../assets/icons/UploadIcon';
import Dropdown from '../../job/components/Dropdown';
import uploadContent from '../../../config/UploadContent';
import { useAppDispatch, useAppSelector } from '../../../config/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { getEventDetailsApi, updateEventAPi } from '../../../slices/eventSlice';
import { baseUrl, contentUrl } from '../../../utils/contant';
import ReactDatePicker from 'react-datepicker';
import Switch from '../../job/Switch';
import { getFormattedDate, getFormattedTime } from '../../../utils/dateUtils';
import Advertisement from '../../advertisement/Advertisement';


const UpdateEvent = () => {

    const dispatch=useAppDispatch()
    const navigate=useNavigate()
    const { industries, categories, functions ,currencies} = useAppSelector(
        (state) => state.appConfig
      );
  const {eventDetail} =useAppSelector((state)=>state.events)

  const { userId ,token} = useAppSelector((state) => state.login.session);
  const {eventId}=useParams()
    
const {register,handleSubmit,control,setValue,watch}=useForm()
const [isUploading, setIsUploading] = useState<boolean>(false);
const [isNextPage,setIsNextPage]=useState<boolean>(false)
const [updatedBanner,setUpdatedBanner]=useState("")
const inputRef = useRef(null);
const isOnline = watch("isOffline",`${eventDetail?.isOffline}`) ;
const startDateWatch=watch("startDate") || new Date()
const isPaid=useRef(null)
const isPaidEvent=watch("switch");

const onSubmit=async (data)=>{
    console.log(data)
    const response=dispatch(updateEventAPi({
      title: data.title,
      details: data.details,
      industry: data.industry,
      functions: data.functions,
      category: data.category,
      host: data.host,
      isPrivate: data.isPrivate==="false"?false:true,
      isOffline: data.isOffline==="false"?false:true,
      eventUrl: data.eventUrl,
      venue: data.venue,
      fee: !isPaidEvent?0:data.fee,
      startDate: getFormattedDate(data.startDate),
      endDate:getFormattedDate(data.endDate),
      emailId: data.emailId,
      startTime:getFormattedTime(data.startTime),
      endTime: getFormattedTime(data.endTime),
      bannerImageUrl: data.bannerImageUrl,
      eventId:eventId,
    }))

    navigate(`/tab/events/${eventId}`)  
    // console.log("Response",response)
}

console.log("Updated Event",eventDetail)
useEffect(()=>{
    dispatch(getEventDetailsApi({eventId}))  
  },[eventId])

const handleChange = (event) => {
    // console.log(event)
    setIsUploading(true);

    const selectedFile =
      event instanceof DragEvent
        ? event.dataTransfer.files
        : event.target.files;
    console.log(selectedFile);

    uploadContent.uploadEventContent(userId, selectedFile, (response) => {
      //console.log(response,jobId);
      const { contentType, name, size, url } = response[0];
      const attachment = {
        contentType: contentType,
        name: name,
        size: size,
        url: url,
      };
      console.log(attachment);
      setUpdatedBanner(attachment.url)
      setValue("bannerImageUrl",(attachment.url));
      setIsUploading(false);
    });
  };

  
  return (
    <div className='flex'>

    
    <div className='flex-1 pr-4'>
      <div>
        <h1 className=' py-2 text-white text-xl font-semibold'>Update Event !!!</h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
          
          {!isNextPage && <div>
            <h1 className="text-lxgray-100">Upload File</h1>
            <label className="text-lxgray-300 ">
              Drag or choose your file to upload (less than or equal to 3 MegaBytes)
            </label>
            <input
              ref={inputRef}
              className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg mb-4 hidden"
              accept=".jpg,.jpeg,.png"
              // placeholder={<UploadIcon/>}
              type="file"
              onChange={handleChange}
              //
            />

            <div className="relative">
              <div className="bg-lxgray-400 ">
                <span
                  onClick={() => inputRef?.current?.click()}
                  {...register("bannerImageUrl",{value:eventDetail?.bannerImageUrl})}
                  className="rounded "
                  >
                  {" "}
                  {/* {isUploading ? "Uploading..." : "Upload"} */}
                  <div className="cursor-pointer relative">   
                    <img src={updatedBanner.length>0?`${baseUrl}/${contentUrl}/${updatedBanner}?t=${token}`:`${baseUrl}/${contentUrl}/${eventDetail?.bannerImageUrl}?t=${token}`} alt="bannerImage" className='w-full h-40 object-contain'/>
                    <div className="absolute top-[40%] left-[49%]"><UploadIcon/></div>
                  </div> 
                </span>     
              </div>
              
            </div>



            <div className="   flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300">
                Enter Event Title *
              </label>
              <input
                type="text"
                placeholder="Enter Title"
                defaultValue={eventDetail.title}
                className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
                {...register("title")}
                // required
              />
            </div>

            <div className="text-lxgray-300 mb-4 flex flex-col">
              <label htmlFor="" className="text-sm p-1 ">
                Type *
              </label>
              <div className="flex items-center">
                <input
                  type="radio"
                  defaultChecked={!eventDetail.isPrivate?true:false}
                  value={"false"}
                  className=" w-4 h-4 mr-2 "
                  {...register("isPrivate")}
                />
                <label htmlFor="public" className="text-white p-1 mr-6 ">
                  Public
                </label>

                <input
                  type="radio"
                  defaultChecked={eventDetail.isPrivate?true:false}
                  value={"true"}
                  className=" h-4 w-4 mr-2"
                  {...register("isPrivate")}
                />
                <label htmlFor="client" className="text-white p-1 ">
                  Private
                </label>
              </div>
            </div>

            <div className="  flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
                Industry *
              </label>
              <div className="text-white">
                <Dropdown
                  name="industry"
                  control={control}
                  defaultValue={eventDetail?.industry}
                  placeholder={"Choose The Relevant Industry"}
                  options={industries.map((ele) => {
                    return { value: ele.Id, label: ele.Name };
                  })}
                />
              </div>
            </div>

            <div className="  flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
                Category *
              </label>
              <div className="text-white">
                <Dropdown
                  name="category"
                  control={control}
                  defaultValue={eventDetail.category}
                  placeholder={"Choose The Relevant Category"}
                  options={categories.map((ele) => {
                    return { value: ele.Id, label: ele.Categories };
                  })}
                />
              </div>
            </div>

            <div className="  flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
                Functions *
              </label>
              <div className="text-white">
                <Dropdown
                  name="functions"
                  control={control}
                  defaultValue={eventDetail.functions}
                  placeholder={"Choose The Relevant Function"}
                  options={functions.map((ele) => {
                    return { value: ele.Id, label: ele.Name };
                  })}
                />
              </div>
            </div>

            <div className="text-lxgray-300 flex flex-col mb-4 relative">
              <label htmlFor="" className="text-sm p-1">
                Enter Event Details *
              </label>
              <textarea
                className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg text-white py-2 pr-8 pl-2 no-scrollbar"
                name=""
                id=""
                defaultValue={eventDetail.details}
                rows={5}
                placeholder="Enter Here"
                maxLength={26000}
                {...register("details")}
                // required
              ></textarea>
            </div>
            <button onClick={()=>setIsNextPage(!isNextPage)} type='button' className='bg-golden px-8 text-white py-1 rounded'>Next</button>
          </div>}
          {
            isNextPage && (
              <div>
                 <div className="flex flex-col mb-4">
                  <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
                    Host *
                  </label>
                  <div className="text-white">
                    <select
                      className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2  text-white w-full"
                      defaultValue={eventDetail.host}
                      {...register("host")}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {["Individual", "Partner", "Linkcxo"].map((ele, i) => (
                        <option key={`${i}jobType`} value={ele}>
                          {ele}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="text-lxgray-300 mb-4 flex flex-col">
        <label htmlFor="" className="text-sm p-1 ">
          Mode *
        </label>
        <div className="flex items-center">
          <input
            type="radio"
            defaultChecked={eventDetail.isOffline?false:true}
            value={"false"}
            defaultValue={`"${eventDetail.isOffline}"`}
            className=" w-4 h-4 mr-2 "
            {...register("isOffline")}
          />
          <label htmlFor="online" className="text-white p-1 mr-6 ">
            Online
          </label>

          <input
            type="radio"
            defaultChecked={!eventDetail.isOffline?false:true}
            value={"true"}
            defaultValue={`"${eventDetail.isOffline}"`}
            className=" h-4 w-4 mr-2"
            {...register("isOffline")}
          />
          <label htmlFor="offline" className="text-white p-1 ">
            Offline
          </label>
        </div>
      </div>

      {(isOnline === "false") && (
        <div className="   flex flex-col mb-4">
          <label htmlFor="" className="text-sm p-1 text-lxgray-300">
            Event Link *
          </label>
          <input
            type="text"
            placeholder="Event Url"
            defaultValue={eventDetail?.eventUrl}
            className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
            {...register("eventUrl")}
            // required
          />
        </div>
      )}

        {(isOnline === "true") && (
          <div className="   flex flex-col mb-4">
            <label htmlFor="" className="text-sm p-1 text-lxgray-300">
              Address *
            </label>
            <input
              type="text"
              placeholder="Event Url"
              defaultValue={eventDetail?.venue}
              className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
              {...register("venue")}
              // required
            />
          </div>
          )}


          <div className="flex mb-4 gap-2">
            <div className="flex flex-col w-2/3">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300">
                Start Date *
              </label>
              
              <div className="">
                <Controller
                  control={control}
                  name="startDate"
                  defaultValue={new Date(eventDetail?.startDate?.year,eventDetail?.startDate?.month-1,eventDetail?.startDate?.day)}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ReactDatePicker
                    // placeholderText={`${eventDetail?.startDate.day}-${eventDetail?.startDate.month}-${eventDetail?.startDate.year}`}
                    className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg w-full p-2 text-white"
                    onChange={onChange} // send value to hook form
                    onBlur={onBlur} // notify when input is touched/blur
                    selected={ value || new Date(eventDetail?.startDate?.year,eventDetail?.startDate?.month-1,eventDetail?.startDate?.day)}
                  //  dropdownMode='select'
                    minDate={new Date()} 
                    dateFormat="dd/MM/yyyy"
                    />
                  )}
                  />
                </div>
            </div>

            <div className="flex flex-col w-1/3">
            <label htmlFor="" className="text-sm p-1 text-lxgray-300">
              Time (IST Standard) *
            </label>
            <input
              type="time"
              placeholder="Select Time"
              defaultValue={`${eventDetail?.startTime.hour}:${eventDetail?.startTime.minute===0?"00":eventDetail?.startTime.minute}`}
              // defaultValue={"12:0"}
              className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
              {...register("startTime")}
              // required
            />
            </div>
        </div>


        <div className="flex mb-4 gap-2">
            <div className="flex flex-col w-2/3">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300">
                End Date *
              </label>
              
              <div className="">
                <Controller
                  control={control}
                  name="endDate"
                  defaultValue={new Date(eventDetail?.endDate?.year,eventDetail?.endDate?.month-1,eventDetail?.endDate?.day)}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ReactDatePicker
                    placeholderText="Select Date"
                    className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg w-full p-2 text-white"
                    onChange={onChange} // send value to hook form
                    onBlur={onBlur} // notify when input is touched/blur
                    selected={ value || new Date(eventDetail?.endDate?.year,eventDetail?.endDate?.month-1,eventDetail?.endDate?.day)}
                    minDate={startDateWatch?new Date(startDateWatch):new Date()}
                    dateFormat="dd/MM/yyyy"
                    />
                  )}
                  />
                </div>
            </div>

            <div className="flex flex-col w-1/3">
            <label htmlFor="" className="text-sm p-1 text-lxgray-300">
              Time (IST Standard) *
            </label>
            <input
              type="time"
              placeholder="Select Time"
              className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
              defaultValue={`${eventDetail?.endTime.hour}:${eventDetail?.endTime.minute===0?"00":eventDetail?.endTime.minute}`}
              {...register("endTime")}
              // required
            />
            </div>


            
        </div>

        <div className="flex p-1 text-sm text-lxgray-300 justify-between mb-3">
          <span className="text-white">Set Event to be Free or Paid *</span>
          <div className="flex gap-2 items-center">
            <label htmlFor="" className="text-lxgray-100">Event is Paid</label>
            <input type="checkbox" ref={isPaid} defaultChecked={eventDetail.fee>0?true:false} onChange={(e)=>setValue("switch",e.target.checked)} className="hidden" />
            <div onClick={()=>isPaid.current?.click()} {...register('switch',{value:eventDetail.fee>0?true:false})}><Switch isopen={eventDetail.fee>0?true:false}/></div>
          </div>
        </div>
        

        {isPaidEvent && <div className="flex gap-4 mb-4">
          <div className="flex flex-col w-1/4">
            <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
              Currency *
            </label>
            <div className="text-white">
              <select
                className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2  text-white w-full"
                defaultValue={currencies[0].Title}
                {...register("currency")}
              >
                <option value="" disabled>
                  Select
                </option>
                {currencies.map((ele, i) => (
                  <option key={`${i}jobType`} value={ele.Title}>
                    {ele.Title}
                  </option>
                ))}
              </select>
              </div>
          </div>
          <div className="flex flex-col flex-1">
            <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
              Amount *
            </label>
            <input
            type="text"
            placeholder="Enter Your Amount"
            defaultValue={eventDetail?.fee}
            className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
            {...register("fee",{setValueAs:(value)=>Number(value),valueAsNumber:true})}
            onInput={(e) => {
              const target = e.target as HTMLInputElement; // Cast to HTMLInputElement
              target.value = target.value.replace(/[^0-9]/g, ''); // Filter input
            }}
            // required
          />
          </div>
      </div>}


      <div className="   flex flex-col mb-4">
          <label htmlFor="" className="text-sm p-1 text-lxgray-300">
            Email *
          </label>
          <input
            type="text"
            defaultValue={eventDetail.emailId}
            placeholder="support@gmail.com"
            className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
            {...register("emailId")}
          // required
          />
      </div>

                <button type='button' className='bg-lxgray-400 px-8 text-white py-1 rounded' onClick={()=>setIsNextPage(!isNextPage)}>Previous</button>
                <button className='bg-golden px-8 text-white py-1 rounded ml-4'>Submit</button>
              </div>
            )
          }
     </form>
    </div>
    <div className='border-l border-lxgray-300 px-5'>
      <Advertisement/>
    </div>
    </div>
  )
}

export default UpdateEvent
