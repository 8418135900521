import { useState } from "react";
import TopVoice from "./user-interest-tabs/TopVoice";

const UserInterest = () => {
  const [openTab, setOpenTab] = useState<number>(1);

  const onChangeTabHandler = (tabId: number) => {
    setOpenTab(tabId);
  };

  return (
    <div className="w-full">
      <h1 className="text-white text-xl font-bold">Interest</h1>

      <div className="w-full mt-6">
        <ul className="w-full flex items-center justify-around text-lxgray-200 text-xl font-light">
          <li className="flex-1" onClick={() => onChangeTabHandler(1)}>
            <h4
              className={`px-4 py-2 w-fit cursor-pointer mx-auto transition duration-300 ease-in-out ${
                openTab === 1
                  ? "font-bold text-golden border-b-[1px] border-golden"
                  : "border-b-[1px] border-transparent"
              }`}>
              Top Voice
            </h4>
          </li>
          <li className="flex-1" onClick={() => onChangeTabHandler(2)}>
            <h4
              className={`px-4 py-2 w-fit cursor-pointer mx-auto transition duration-300 ease-in-out ${
                openTab === 2
                  ? "font-bold text-golden border-b-[1px] border-golden"
                  : "border-b-[1px] border-transparent"
              }`}>
              Companies
            </h4>
          </li>
          <li className="flex-1" onClick={() => onChangeTabHandler(3)}>
            <h4
              className={`px-4 py-2 w-fit cursor-pointer mx-auto transition duration-300 ease-in-out ${
                openTab === 3
                  ? "font-bold text-golden border-b-[1px] border-golden"
                  : "border-b-[1px] border-transparent"
              }`}>
              Groups
            </h4>
          </li>
          <li className="flex-1" onClick={() => onChangeTabHandler(4)}>
            <h4
              className={`px-4 py-2 w-fit cursor-pointer mx-auto transition duration-300 ease-in-out ${
                openTab === 4
                  ? "font-bold text-golden border-b-[1px] border-golden"
                  : "border-b-[1px] border-transparent"
              }`}>
              Newsletter
            </h4>
          </li>
        </ul>
      </div>

      <div className="w-full mt-2 transition-opacity delay-500 ease-in-out">
        <div
          className={`w-full ${
            openTab === 1 ? "block opacity-100" : "hidden opacity-0"
          }`}>
          <TopVoice />
        </div>
        <div
          className={`text-white  ${
            openTab === 2 ? "block opacity-100" : "hidden opacity-0"
          }`}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore quas
          officia hic, aliquam culpa at illo, illum deserunt corporis numquam
          molestiae quaerat corrupti sunt vero doloremque aperiam fugit, minima
          laborum reiciendis? Ad rerum laudantium, placeat dolore eius
          reprehenderit? Sunt labore, voluptate iure vel suscipit quisquam
          expedita delectus repellen excepturi qui. Architecto doloremque
          temporibus aut beatae rerum.
        </div>
        <div
          className={`text-white  ${
            openTab === 3 ? "block opacity-100" : "hidden opacity-0"
          }`}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore quas
          officia hic, aliquam culpa at illo, illum deserunt corporis numquam
          molestiae quaerat corrupti sunt vero doloremque aperiam fugit, minima
          laborum reiciendis? Ad rerum laudantium, placeat dolore eius
          reprehenderit? Sunt labore, voluptate iure vel suscipit quisquam
          expedita delectus repellendus amet explicabo ipsa mollitia assumenda
          non modi consectetur deserunt tempore exercitationem in animi a dicta
          pariatur commodi! Est libero maxime quae sint rerum excepturi
          perspiciatis repudiandaeaborum reiciendis? Ad rerum laudantium,
          placeat dolore eius reprehenderit? Sunt labore, voluptate iure vel
          suscipit quisquam expedita delectus repellendus amet explicabo ipsa
          mollitia assumenda non modi consectetur deserunt tempore
          exercitationem in animi a dicta pariatur commodi! Est libero maxime
          quae sint rerum excepturi perspiciatis repudiandae
        </div>
        <div
          className={`text-white ${
            openTab === 4 ? "block opacity-100" : "hidden opacity-0"
          }`}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore quas
          officia hic, aliquam culpa at illo, illum deserunt corporis numquam
          molestiae quaerat corrupti sunt vero doloremque aperiam fugit, minima
          laborum reiciendis? Ad rerum laudantium, placeat dolore eius
          reprehenderit? Sunt labore, voluptate iure vel suscipit quisquam
          expedita delectus repellendus amet explicabo ipsa mollitia assumenda
          non modi
        </div>
      </div>
    </div>
  );
};

export default UserInterest;
