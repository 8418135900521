import React from 'react'
import { MdOutlineLibraryBooks } from "react-icons/md";
import { PiNotebookLight } from "react-icons/pi";

const VerificationPopup = ({ visible, onClose }) => {
    if (!visible) return null;

    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[100]">
        <div className="flex flex-col items-center  gap-2 bg-lxgray-800 rounded-lg shadow-lg p-6 text-center border border-golden">
          <PiNotebookLight color='white' size={66}/>
          <h2 className="text-lg font-semibold text-white">Your profile is under verification !</h2>
          <div>
             <p className='text-sm text-lxgray-100 text-center'>You can perform this action only after post verification.</p>
             <p className='text-sm text-lxgray-100 text-center'>Until then, you can update your profile.</p>
          </div>
          <button 
            onClick={()=>onClose(false)} 
            className="mt-4 px-8 py-2 bg-golden w-fit  text-white rounded hover:bg-blue-600"
          >
            OK
          </button>
        </div>
      </div>
    );
}

export default VerificationPopup
