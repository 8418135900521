export default function PlusIcon({
  color = "#fff",
  width = "15",
  height = "15",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.627 10.132V6.368H.847A.871.871 0 010 5.5c0-.47.375-.854.847-.868h3.78V.868c0-.31.166-.597.436-.752a.876.876 0 01.873 0c.27.155.436.442.436.752v3.764h3.78A.871.871 0 0111 5.5c0 .47-.375.854-.847.868h-3.78v3.764c0 .31-.166.597-.436.752a.877.877 0 01-.873 0 .867.867 0 01-.436-.752z"
        fill={color}
      />
    </svg>
  );
}
