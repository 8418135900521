import React from "react";

const RightTick = () => {
  return (
    <div>
      <svg
        width="22"
        height="14"
        viewBox="0 0 22 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.00444 6.03166L7.05747 10.4571C11.2579 6.222 15.9741 2.51034 20.2482 0.368997C21.2062 -0.202028 22.6801 0.13107 21.6484 0.74968C16.8584 3.9379 13.1739 7.55439 9.41559 13.2171C7.94176 13.8833 6.24686 14.2164 5.43625 13.5977L0.130471 7.45922C-0.606443 6.69786 1.97276 4.9372 3.00444 6.03166Z"
          fill="#AA933E"
        />
      </svg>
    </div>
  );
};

export default RightTick;
