import { ReactNode } from "react";
import LxOutlinedButton from "../../../components/basic/LxOutlinedButton";

interface SuggestionCardProps {
  suggestionIcon: string;
  btnTitle: string;
  title: string;
  classNameIcon?: string;
  btnIcon?: ReactNode;
}

const SuggestionCard: React.FC<SuggestionCardProps> = ({
  suggestionIcon,
  btnTitle,
  title,
  classNameIcon,
  btnIcon,
}) => {
  return (
    <div className="w-full min-h-[207.73px] bg-lxgray-600 rounded-lg p-6">
      <div className="flex gap-6">
        <img src={suggestionIcon} alt="" className={classNameIcon} />
        <h1 className="flex-1 max-w-[311px] text-white text-xl font-bold">
          {title}
        </h1>
      </div>
      <div className="w-[75%] mt-2.5">
        <p className="text-lxgray-200 text-sm font-normal">
          Members with a profile photo receive up to 2.3 times as many profile
          views.
        </p>
      </div>
      <div className="mt-6">
        <LxOutlinedButton
          btnIcon={btnIcon}
          btnTitle={btnTitle}
          customeStyles={
            btnTitle === "Add Education"
              ? "w-[189px] border-white text-white"
              : "w-[165px] border-white text-white"
          }
        />
      </div>
    </div>
  );
};

export default SuggestionCard;
