import React, { useEffect, useRef, useState } from "react";
import jobLogo from "../../../assets/images/Blank_Placeholders.png";
import EditDots from "../../../assets/icons/EditDots";
import { Job } from "../../../models/job";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Clock from "../../../assets/icons/Clock";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import PopupJob from "./PopupJob";
import ApplyJob from "./ApplyJob";
import { popupShowApply, saveJobApi, unsaveJobApi } from "../../../slices/jobSlice";
import { LastAppliedDay } from "../../../utils/dateUtils";
import EditDotPopup from "../EditDotPopup";
import {baseUrl,contentUrl}from  '../../../utils/contant'
import VerificationPopup from "../../../components/VerificationPopup";
import { BsBuildings } from "react-icons/bs";

interface Props {
  jobData: Job;
}

const JobDescriptionHeaderCard: React.FC<Props> = ({ jobData }) => {
  
  const { popup, savedJobList } = useAppSelector((state) => state.job);
  const { userDetails} = useAppSelector((state) => state.user);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const { userId,token } = useAppSelector((state) => state.login.session);
  const dispatch = useAppDispatch();
  const navigate=useNavigate()
  const editDotPopupRef = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();
  const { title } = useParams();

  const [showDotData, setShowDotData] = useState<Boolean>(false);


  const handleApplyButton=()=>{
    if(userDetails?.status!=="Verified"){
      setPopupVisible(true)
      return
    }

    dispatch(popupShowApply())
  }

  const handleSaveJob = () => {
    if (!savedJobList.ids.includes(jobData.id)) {
      //SAVE JOB
      dispatch(saveJobApi({ userId, jobId: jobData.id }));
      navigate("/tab/jobs/myjobs")
      return;
    }

    //UNSAVE JOB
    // dispatch(saveJobApi({userId,jobId:jobData.id}))
  };

  const handleUnSaveJob=()=>{
      dispatch(unsaveJobApi({ userId, jobId: jobData.id }))
      navigate("/tab/jobs/myjobs")
  }
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        editDotPopupRef.current &&
        !editDotPopupRef.current.contains(event.target as Node)
      ) {
        setShowDotData(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

 

  return (
    <>
      <div className="flex flex-col sm:flex-row bg-lxgray-600 p-2 mb-2  ">
        {popup.applyJob && (
          <div className="mx-auto">
            <PopupJob>
              <ApplyJob jobId={jobData.id} />
            </PopupJob>
          </div>
        )}

        <div>
        <img src={`${jobData.authorType==="COMPANY"?`${baseUrl}/${contentUrl}/${jobData.author.picUrl}?t=${token}`:jobLogo}`} alt="image" className="ml-3 sm:ml-0 mb-2 sm:mb-0 object-cover border border-lxgray-200 rounded-lg w-12 h-12 sm:w-24 sm:h-24" />
        </div>
        <div className="px-4 flex-1 relative">
          <div className="flex justify-between">
           <p className="text-sm sm:text-xl font-semibold text-white mb-2">{jobData.title}</p>
            <div>
              {!pathname.includes("Closed") && (
                <div
                  className="pl-4 pr-1 hover:cursor-pointer"
                  onClick={() => setShowDotData(!showDotData)}
                >
                  <EditDots />
                </div>
              )}
              {showDotData && (
                <span ref={editDotPopupRef}>
                  <EditDotPopup
                    data={[
                      "CopyLink",
                      "Send to Private Message",
                      ...(pathname.includes("hiring") ||
                      pathname.includes("Open") ||
                      pathname.includes("InActive")
                        ? [
                            `${
                              pathname.includes("InActive")
                                ? "Open"
                                : "Inactive"
                            }`,
                            "Close",
                          ]
                        : ["Report"]),
                    ]}

                    jobId={jobData.id}
                    pathName={pathname}
                 
                    setShowDotData={setShowDotData}
                    
                  />
                </span>
              )}
            </div>
          </div>
          <div className="flex items-center gap-2">
          <BsBuildings size={12}/>
         
          <h3 className="text-sm sm:text-base">{" Company"}  : <span className="font-semibold text-golden mt-3">{(!jobData.company||jobData.authorId===userId)?jobData.company:"Confidential"}</span></h3>
          </div>

          <div className="flex flex-col-reverse sm:flex-row gap-2 sm:gap-0 justify-between sm:items-center mt-2 sm:mt-0">
            <div className="flex items-center">
              <Clock />
              <p className="text-xs ml-1 text-lxgray-400">
                {LastAppliedDay(jobData.createdAt) === 0
                  ? "Today"
                  : `Posted ${LastAppliedDay(jobData.createdAt)} day ago`}
              </p>
            </div>
            <div className="">
              {title === "Open" ||
              title === "Closed" ||
              title === "InActive" ||
               title==="Company"? (
                <div>
                  <h1 className="text-golden text-xl font-bold">
                    {jobData.noOfApplicants}{" "}
                    <span className="text-lxgray-400 text-xs">Applicants</span>
                  </h1>
                </div>
              ) : (
                <div className="flex">
                  {!jobData.applied ? (
                    <button
                      className={` text-xs sm:text-sm rounded font-semibold  px-4 sm:px-10 py-1.5 ${"bg-golden text-white"}`}
                      onClick={handleApplyButton}
                    >
                      Apply
                    </button>
                  ) : (
                    <button
                      className={` text-xs sm:text-sm rounded font-semibold ml-4 px-4 sm:px-10 py-1 ${"bg-lxgray-300 text-lxgray-100"}`}
                    >
                      Applied
                    </button>
                  )}
                  {savedJobList.ids.includes(jobData.id) && <button
                    className={` text-xs sm:text-sm rounded font-semibold ml-4 px-4 sm:px-10 py-1  text-golden border-2 border-golden hover:scale-95`}
                    onClick={handleUnSaveJob}
                  >
                    { "Unsaved" }
                  </button>}
                
                 { !savedJobList.ids.includes(jobData.id) && !jobData?.applied && <button
                    className={` text-xs sm:text-sm rounded font-semibold ml-4 px-4 sm:px-10 py-1  text-golden border-2 border-golden hover:scale-95`}
                    onClick={handleSaveJob}
                  >
                    {"Save"}
                  </button>}
                </div>
              )}
            </div>
          </div>
        </div>
        <VerificationPopup 
        visible={isPopupVisible} 
        onClose={setPopupVisible} 
      />
      </div>
    </>
  );
};

export default JobDescriptionHeaderCard;
