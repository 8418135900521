export const customColor = {
  golden: "#AA933E",
  lxgreen: {
    100: "#7AC482",
    200: "#0d2901",
  },
  lxred: {
    100: "#FF4D67",
    200: "#2C4C3B",
  },

  lxwhite: {
    100: "#FFFFFF",
    200: "#EFEEEE",
    300: "#6C6C6C",
  },
  lxgray: {
    100: "#CDCED2",
    200: "#8C8C8C", // "#6C7275",
    300: "#6C7275", //#696969",
    400: "#696969", //"#8C8C8C",
    500: "#343839", //"#323232",
    600: "#323232",
    700: "#242525", //"#343839",
    800: "#181919",
  },
};

export const baseUrl = 'https://api.dev.linkcxo.com';
// export const baseUrl = 'https://api.prod.linkcxo.com';
// export const socketUrl = "wss://api.dev.linkcxo.com";
export const socketUrl = "wss://api.prod.linkcxo.com";

//For Production
export const contentUrl="files"

export const CLIENT_ID="77c4lm3a11roau"
export const REDIRECT_URI="https://www.linkcxo.com/login/callback"
// DEv
// export const contentUrl="files"