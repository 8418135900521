import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import nocoService, {
  Category,
  Designation,
  Functions,
  Industry,
  Country,
  City,
  Language,
  Currency,
  JobType,
  WorkplaceType,
  MinSalary,
  Experience,
  PostDuration,
  Faq,
  PrivacyTerms,
  Location,
  Qualification,
  CompanyType,
  NoticePeriod,
  Skill,
  EmployeeSize
} from "../services/noco.service";
import { logout } from "./loginSlice";

interface AppReduxState {
  categories: Category[];
  designations: Designation[];
  industries: Industry[];
  functions: Functions[];
  countries: Country[];
  cities: City[];
  languages: Language[];
  theme: "dark" | "light";
  platform: "web" | "app";
  device: UserDevice;
  network: string;
  location: any;
  currencies: Currency[];
  jobTypes: JobType[];
  workplaceTypes: WorkplaceType[];
  minSalaries: MinSalary[];
  maxSalaries: MinSalary[];
  experienceMinRange: Experience[];
  experienceMaxRange: Experience[];
  postDuration: PostDuration[];
  faqList: Faq[];
  privacyTerms: PrivacyTerms[];
  qualification:Qualification[],
  companyType:CompanyType[],
  noticePeriod:NoticePeriod[],
  skills:Skill[],
  preferredLocation:Location[]
  employeeSize:EmployeeSize[]
}
interface UserDevice {
  model: string;
  platform: string;
  appVersion: string;
  appBuild: string;
  appId: string;
  appName: string;
  operatingSystem: string;
  osVersion: string;
  manufacturer: string;
  isVirtual: boolean;
  uuid: string;
  isPaytm: boolean;
  ios14: boolean;
}

let isDarkMode = false;
const isWeb = true;
if (
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches
) {
  isDarkMode = true;
}

const initialState: AppReduxState = {
  designations: [],
  industries: [],
  categories: [],
  functions: [],
  countries: [],
  cities: [],
  languages: [],
  theme: isDarkMode ? "dark" : "light",
  platform: isWeb ? "web" : "app",
  device: undefined,
  network: undefined,
  location: undefined,
  currencies: [],
  jobTypes: [],
  workplaceTypes: [],
  minSalaries: [],
  maxSalaries: [],
  experienceMinRange: [],
  experienceMaxRange: [],
  postDuration: [],
  faqList: [],
  privacyTerms: [],
  noticePeriod: [],
  companyType: [],
  qualification: [],
  skills: [],
  preferredLocation:[],
  employeeSize:[]
};

export const getDesignationsApi = createAsyncThunk(
  "appSlice/getDesignationsApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      return await nocoService.getAllDesignations();
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);
export const getIndustriesApi = createAsyncThunk(
  "appSlice/getIndustriesApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllIndustries();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCategoriesApi = createAsyncThunk(
  "appSlice/getCategoriesApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllCategories();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getFunctionsApi = createAsyncThunk(
  "appSlice/getFunctionsApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllFunctions();

      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCountriesApi = createAsyncThunk(
  "appSlice/getCountriesApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllCountries();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCitiesApi = createAsyncThunk(
  "appSlice/getCitiesApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllCities();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getLanguagesApi = createAsyncThunk(
  "appSlice/getLanguagesApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getLanguageList();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCurrencyApi = createAsyncThunk(
  "appSlice/getCurrencyApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllCurrencyType();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);
export const getJobTypeApi = createAsyncThunk(
  "appSlice/getJobTypeApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllJobType();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);
export const getWorkplaceTypeApi = createAsyncThunk(
  "appSlice/getWorkplaceTypeApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getAllWorkplaceType();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getSalaryRangeMinApi = createAsyncThunk(
  "appSlice/getSalaryRangeMinApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getSalaryRange();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);
export const getSalaryMaxRangeApi = createAsyncThunk(
  "appSlice/getSalaryMaxRangeApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getMaxSalaryRange();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getExperienceMinApi = createAsyncThunk(
  "appSlice/getExperienceMinApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getExperienceMinRange();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);
export const getExperienceMaxApi = createAsyncThunk(
  "appSlice/getExperienceMaxApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getExperienceMinRange();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getPostDurationAPi = createAsyncThunk(
  "appSlice/getPostDuretion",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getPostDuration();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getFAQApi = createAsyncThunk(
  "appSlice/getFAQApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getFaqs();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getPrivacyTerms = createAsyncThunk(
  "appSlice/getPrivacyTerms",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getPrivacyTerms();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getQualificationApi = createAsyncThunk(
  "appSlice/getQualificationApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getQualification();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getSkillsApi = createAsyncThunk(
  "appSlice/getSkillsApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getSkills();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getCompanyTypeApi = createAsyncThunk(
  "appSlice/getCompanyTypeApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getCompanyTypes();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNoticePeriodApi = createAsyncThunk(
  "appSlice/getNoticePeriodApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getNoticePeriod();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getPreferedLocationsApi = createAsyncThunk(
  "appSlice/getPreferedLocationsApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getPreferedLocations();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getEmployeeSizeApi = createAsyncThunk(
  "appSlice/getEmployeeSizeApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const response = await nocoService.getEmployeeSize();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const appSlice = createSlice({
  name: "appConfig",
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      state.theme = state.theme === "dark" ? "light" : "dark";
    },
    setDarkMode: (state, action) => {
      state.theme = action.payload;
    },
    setDevice: (state, action) => {
      state.device = action.payload;
    },
    setNetwork: (state, action) => {
      state.network = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getDesignationsApi.fulfilled,
      (state: AppReduxState, action) => {
        state.designations = action.payload.data.list;
      }
    );
    builder.addCase(
      getIndustriesApi.fulfilled,
      (state: AppReduxState, action) => {
        state.industries = action.payload.data.list;
      }
    );
    builder.addCase(
      getCategoriesApi.fulfilled,
      (state: AppReduxState, action) => {
        state.categories = action.payload.data.list;
      }
    );
    builder.addCase(
      getFunctionsApi.fulfilled,
      (state: AppReduxState, action) => {
        state.functions = action.payload.data.list;
      }
    );
    builder.addCase(
      getCountriesApi.fulfilled,
      (state: AppReduxState, action) => {
        state.countries = action.payload.data.list;
      }
    );
    builder.addCase(getCitiesApi.fulfilled, (state: AppReduxState, action) => {
      state.cities = action.payload.data.list;
    });
    builder.addCase(
      getLanguagesApi.fulfilled,
      (state: AppReduxState, action) => {
        state.languages = action.payload.data.list;
      }
    );
    builder.addCase(
      getCurrencyApi.fulfilled,
      (state: AppReduxState, action) => {
        state.currencies = action.payload.data.list;
      }
    );
    builder.addCase(getJobTypeApi.fulfilled, (state: AppReduxState, action) => {
      state.jobTypes = action.payload.data.list;
    });
    builder.addCase(
      getWorkplaceTypeApi.fulfilled,
      (state: AppReduxState, action) => {
        state.workplaceTypes = action.payload.data.list;
      }
    );
    builder.addCase(
      getSalaryMaxRangeApi.fulfilled,
      (state: AppReduxState, action) => {
        state.maxSalaries = action.payload.data.list;
      }
    );
    builder.addCase(
      getSalaryRangeMinApi.fulfilled,
      (state: AppReduxState, action) => {
        state.minSalaries = action.payload.data.list;
      }
    );
    builder.addCase(
      getExperienceMinApi.fulfilled,
      (state: AppReduxState, action) => {
        state.experienceMinRange = action.payload.data.list;
      }
    );
    builder.addCase(
      getExperienceMaxApi.fulfilled,
      (state: AppReduxState, action) => {
        state.experienceMaxRange = action.payload.data.list;
      }
    );
    builder.addCase(
      getPostDurationAPi.fulfilled,
      (state: AppReduxState, action) => {
        state.postDuration = action.payload.data.list;
      }
    );
    builder.addCase(
      getPrivacyTerms.fulfilled,
      (state: AppReduxState, action) => {
        state.privacyTerms = action.payload.data.list;
      }
    );
    builder.addCase(getFAQApi.fulfilled, (state: AppReduxState, action) => {
      state.faqList = action.payload.data.list;
    });
    builder.addCase(
      getQualificationApi.fulfilled,
      (state: AppReduxState, action) => {
        state.qualification = action.payload.data.list;
      }
    );
    builder.addCase(
      getSkillsApi.fulfilled,
      (state: AppReduxState, action) => {
        state.skills = action.payload.data.list;
      }
    );
    builder.addCase(
      getCompanyTypeApi.fulfilled,
      (state: AppReduxState, action) => {
        state.companyType = action.payload.data.list;
      }
    );
    builder.addCase(
      getPreferedLocationsApi.fulfilled,
      (state: AppReduxState, action) => {
        state.preferredLocation = action.payload.data.list;
      }
    );
    builder.addCase(
      getNoticePeriodApi.fulfilled,
      (state: AppReduxState, action) => {
        state.noticePeriod = action.payload.data.list;
      }
    );
    builder.addCase(
      getEmployeeSizeApi.fulfilled,
      (state: AppReduxState, action) => {
        state.employeeSize = action.payload.data.list;
      }
    );
    builder.addCase(logout, (state) => {
      // Reset this slice's state to initial state
      return initialState;
    });
  },
});
export const {
  toggleDarkMode,
  setDarkMode,
  setDevice,
  setNetwork,
  setLocation,
} = appSlice.actions;
export default appSlice.reducer;
