import { FC, useCallback, useEffect, useRef, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import moment from "moment";

import "../../../App.css";

import CrossIcon from "../../../assets/icons/CrossIcon";
import LikeIcon from "../../../assets/icons/LikeIcon";
import ShareIcon from "../../../assets/icons/ShareIcon";
import ArrowIcon from "./../../../assets/icons/ArrowIcon";
import CommentIcon from "../../../assets/icons/CommentIcon";

import Profile from "../../../assets/images/Blank_Placeholders.png";
import Comments from "../../../components/Comments";

import { useAppDispatch, useAppSelector } from "./../../../config/hooks";
import {
  closeCreatePostModalType,
  togggleCreatePostModalType,
} from "../../../slices/homeFeedSlice";
import { baseUrl, contentUrl } from "../../../utils/contant";

import useReadMoreToggle from "../../../utils/hooks/useReadMoreToggle";

interface OpenImagePopupProps {
  isOpen: boolean;
  title: string;
  postId: string;
}

const OpenImage: FC<OpenImagePopupProps> = ({ isOpen, postId }) => {
  const post = postId.match(/openImage\?=(\d+)/)[1];
  const index = postId && parseInt(postId.match(/index=(\d+)/)[1]);
  const menuRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const session = useAppSelector((state) => state.login.session);
  const posts = useAppSelector((state) => state.user.userPosts.posts);
  const postComments = useAppSelector((state) => state.comment);
  const [toggleComment, setToggleComment] = useState(false);
  const [HoverState, setHoverState] = useState("#EFEFF3");
  const postDetails = posts?.find((postData) => postData.id === post);
  const { contentRef, showMore, isOverflowing, textToggleHandler } =
    useReadMoreToggle(postDetails?.metadata?.content);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    startIndex: index,
  });

  const { comments } = postComments;

  const postRelativeTime =
    postDetails && moment(postDetails.createdAt).fromNow();

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (!menuRef.current.contains(e.target as Node)) {
        closePopupHandler();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const closePopupHandler = () => {
    dispatch(closeCreatePostModalType());
  };

  const hidePopuphandler = () => {
    if (!isOpen) dispatch(togggleCreatePostModalType());
  };

  const toggleCommentHandler = () => {
    setToggleComment(!toggleComment);
  };

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  return (
    postDetails && (
      <div
        className={`fixed items-center top-0
      } top-0 left-0 w-full h-full flex justify-center ${
        !isOpen && "popup-closeanimation"
      } z-10`}
        onAnimationEnd={hidePopuphandler}
      >
        <div
          className={`fixed top-0 left-0 w-full h-full bg-black opacity-80 ${
            !isOpen && "hidden"
          }`}
        ></div>
        <div
          ref={menuRef}
          className={`fixed bg-lxgray-800 shadow-md w-[80%]`}
          style={{
            animation: isOpen && "dropTop .3s linear",
          }}
        >
          <div className="w-full">
            <div className="flex">
              <div
                className="flex-1 flex items-center justify-center overflow-hidden relative"
                ref={emblaRef}
              >
                <div className="flex gap-1">
                  {postDetails.metadata.attachments.map(
                    (imageDetail: { id: string; url: string }) => (
                      <div
                        className="min-w-0 max-h-[79vh] flex items-center justify-center"
                        style={{ flex: "0 0 100%" }}
                        key={imageDetail.id}
                      >
                        <img
                          className="object-contain w-full h-full"
                          src={`${baseUrl}/${contentUrl}/${imageDetail.url}?t=${session.token}`}
                        />
                      </div>
                    )
                  )}
                </div>
                {postDetails.metadata.attachments.length > 1 && (
                  <div className="absolute w-full flex items-center justify-between px-2">
                    <button
                      className="embla__prev active:scale-75"
                      onClick={scrollPrev}
                    >
                      <ArrowIcon color="white" />
                    </button>
                    <button
                      className="embla__next rotate-180 active:scale-75"
                      onClick={scrollNext}
                    >
                      <ArrowIcon color="white" />
                    </button>
                  </div>
                )}
              </div>
              <div className="flex-1 pr-4 pl-4 py-2">
                <div className="flex gap-2">
                  <img
                    src={
                      postDetails?.author?.picUrl
                        ? `${baseUrl}/${contentUrl}/${postDetails?.author?.picUrl}?t=${session.token}`
                        : Profile
                    }
                    alt={`${postDetails?.author?.firstName} ${postDetails?.author?.lastName}`}
                    className="h-10 w-10 rounded-full self-center"
                  />
                  <div className="flex-1">
                    <h1 className="text-sm text-white font-normal">{`${postDetails?.author?.firstName} ${postDetails?.author?.lastName}`}</h1>
                    <p className="text-base text-lxgray-400 font-normal flex flex-wrap items-center justify-between truncate">
                      <span className="text-start truncate">{`${
                        postDetails?.author?.designation
                      } ${
                        postDetails?.author?.designation &&
                        postDetails?.author?.organization &&
                        "|"
                      } ${postDetails?.author?.organization}`}</span>
                      <span className="text-end">{postRelativeTime}</span>
                    </p>
                  </div>
                  <div
                    className="w-fit flex justify-between self-start pr-2 pt-1"
                    style={{ boxShadow: "0px 11px 8px -3px #00000033" }}
                  >
                    <button
                      onClick={closePopupHandler}
                      onMouseEnter={() => setHoverState("#AA933E")}
                      onMouseLeave={() => setHoverState("#EFEFF3")}
                      className="text-golden hover:rotate-90 transition-all duration-200"
                    >
                      <CrossIcon color={HoverState} height="15" width="15" />
                    </button>
                  </div>
                </div>
                <div className="h-[70vh] overflow-y-auto scrollbar-hidden">
                  {postDetails?.metadata?.content && (
                    <div
                      className={`text-sm text-lxgray-100 font-normal space-y-1 my-7`}
                    >
                      <p
                        className={
                          !showMore
                            ? "line-clamp-3 whitespace-pre-wrap"
                            : "line-clamp-none whitespace-pre-wrap"
                        }
                        ref={contentRef}
                      >
                        {postDetails?.metadata?.content}
                      </p>
                      {isOverflowing && (
                        <div className="flex">
                          <button
                            className="text-xs text-lxgray-400 ml-auto mr-0"
                            onClick={textToggleHandler}
                          >
                            {!showMore ? "... See More" : "... See Less"}
                          </button>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="w-full flex items-center justify-between border-t-2 border-lxgray-600 text-sm text-lxgray-400 font-semibold py-2 px-4">
                    <h1 className="flex gap-2 items-center justify-center cursor-pointer px-2 text-center">
                      <LikeIcon />
                      {`${postDetails?.counts?.like} Applauses`}
                    </h1>
                    <h1
                      className="flex gap-2 items-center justify-center cursor-pointer px-2 text-center"
                      onClick={() => toggleCommentHandler()}
                    >
                      <CommentIcon />
                      {`${postDetails.counts.comment} Comments
                  `}
                    </h1>
                    <h1 className="flex gap-2 items-center justify-center cursor-pointer px-2 text-center">
                      <ShareIcon />
                      {`${postDetails?.counts?.share} Shares`}
                    </h1>
                  </div>

                  <div className="mt-2">
                    <Comments
                      userContent={postDetails}
                      comments={comments}
                      parentId=""
                      key={postDetails.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default OpenImage;
