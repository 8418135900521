import React, { useState } from "react";
import vector from "../../../assets/icons/Vector.png";
import jobLogo from "../../../assets/images/Blank_Placeholders.png";
import { useAppSelector } from "../../../config/hooks";
import { Job } from "../../../models/job";
import { LastAppliedDay } from "../../../utils/dateUtils";
import {baseUrl,contentUrl}from  '../../../utils/contant'
import { multipleValueDropdown } from "../../../utils/nocoFunctions";
import { useParams } from "react-router-dom";
import Location from "../../../assets/Job Icon/Location";
// import EditDots from "../../../assets/icons/EditDots";
// import EditDotPopup from "../EditDotPopup";
// import { useLocation } from "react-router-dom";

interface Props {
  data: Job;
  title: string;
}

const JobCardBox: React.FC<Props> = ({ data, title }) => {
  const {userId,token} =useAppSelector((state)=>state.login.session)
  const {preferredLocation} =useAppSelector((state)=>state.appConfig)
  const [popup, setPopup] = useState<Boolean>(false);
  const { jobId} = useParams();
  // const {pathname}=useLocation()
  const { jobType, location, company ,id,workplaceType} = data;
  //  console.log(data,"Data")
  
  const handlePopup = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setPopup(!popup);
  };


 console.log("DATAT",data)
  // console.log("Created At",data.createdAt)
  return (
    <div className={`flex flex-col shrink-0    pl-4 py-2 rounded mr-2 mb-2 hover:bg-lxgray-200/50 ${jobId===id?"bg-lxgray-600":"bg-lxgray-600/60"} `}>
      <div className="flex justify-between relative">
        <img
          src={`${data?.authorType==="COMPANY"?`${baseUrl}/${contentUrl}/${data?.author.picUrl}?t=${token}`:jobLogo}`}
          alt="image"
          className="object-cover border border-lxgray-200 rounded-lg w-12 h-12"
        />
        {/* {title!=='Closed' && <div className="px-4" onClick={handlePopup}><EditDots/></div>}
        {popup && <EditDotPopup data={["CopyLink","Send to Private Message",...(title==("Open")?["Inactive","Close"]:["Report"])]}/>} */}
      </div>
      <div className="">
        <div className="text-lg text-white line-clamp-1">{data?.title}</div>
        <p className="text-sm text-golden font-semibold">{(!company||data.authorId===userId)?company:"Confidential"}</p>
        <div className="flex gap-1 mt-1">
          <Location/>
          <p className="text-sm text-lxgray-400 mb-2 line-clamp-1">
            {multipleValueDropdown(location,preferredLocation)}{" "}
            <span className="text-lxgreen-100 text-xs"> ({workplaceType})</span>
          </p>
        </div>
        <div className="flex items-center justify-end">
          <img src={vector} alt="vector" className="pr-1" />
          <p className="text-xs text-lxgray-100 pr-5 font-semibold">
            {LastAppliedDay(data.createdAt) === 0
              ? "Today"
              : `Posted ${LastAppliedDay(data?.createdAt)} day ago`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default JobCardBox;
