const EclipsVerticleDotsIcon = ({
  width = "6",
  height = "19",
  color = "#8C8C8C",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 6 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.01429 16.4271C5.01429 15.0525 3.8918 13.9381 2.50714 13.9381C1.12249 13.9381 0 15.0525 0 16.4271C0 17.8017 1.12249 18.916 2.50714 18.916C3.8918 18.916 5.01429 17.8017 5.01429 16.4271ZM5.01429 9.45805C5.01429 8.08345 3.8918 6.96911 2.50714 6.96911C1.12249 6.96911 0 8.08345 0 9.45805C0 10.8327 1.12249 11.947 2.50714 11.947C3.8918 11.947 5.01429 10.8327 5.01429 9.45805ZM2.50714 8.7738e-05C3.8918 8.7738e-05 5.01429 1.11442 5.01429 2.48903C5.01429 3.86363 3.8918 4.97796 2.50714 4.97796C1.12249 4.97796 0 3.86363 0 2.48903C0 1.11442 1.12249 8.7738e-05 2.50714 8.7738e-05Z"
        fill={color}
      />
    </svg>
  );
};

export default EclipsVerticleDotsIcon;
