import React from "react";

const Share = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.665 8.25564L14.665 0.255639C14.5213 0.126486 14.3431 0.041842 14.1522 0.0119848C13.9613 -0.0178724 13.7658 0.00834123 13.5895 0.087443C13.4132 0.166545 13.2636 0.295132 13.159 0.457593C13.0543 0.620053 12.9991 0.809398 13 1.00264V4.20964C9.996 5.01564 0 8.76764 0 23.0026C0.000366134 23.2348 0.081496 23.4596 0.229476 23.6385C0.377455 23.8174 0.583063 23.9392 0.811038 23.983C1.03901 24.0269 1.27515 23.9901 1.47895 23.8789C1.68274 23.7677 1.8415 23.5891 1.928 23.3736C4.893 15.9606 10.673 14.4136 12.999 14.0906V17.0026C12.9988 17.1957 13.0545 17.3847 13.1593 17.5468C13.2642 17.7089 13.4137 17.8372 13.5899 17.9162C13.766 17.9951 13.9613 18.0215 14.1521 17.9919C14.3428 17.9624 14.521 17.8782 14.665 17.7496L23.665 9.74964C23.7704 9.65582 23.8548 9.54076 23.9125 9.41201C23.9703 9.28327 24.0002 9.14375 24.0002 9.00264C24.0002 8.86153 23.9703 8.72201 23.9125 8.59326C23.8548 8.46452 23.7704 8.34945 23.665 8.25564Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default Share;
