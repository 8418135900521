import React, { useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { Controller, useForm } from "react-hook-form";
import TextArea from "../basic_components/TextArea";
import DropDown from "../basic_components/DropDown";
import InputText from "../basic_components/InputText";
import { unwrapResult } from "@reduxjs/toolkit";
import { createPostApi } from "../../../../slices/postSlice";
import { closeCreatePostModalType } from "../../../../slices/homeFeedSlice";

type FormValues = {
  question: string;
  discription: string;
  categories: { Categories: string; Id: number };
};

const QueryForm = ({authorType,authorId}:{authorType?:string,authorId?:string}) => {
  const buttonRef = useRef(null);
  const dispatch = useAppDispatch();
  const session = useAppSelector((state) => state.login.session);
  const categoryOptions = useAppSelector((state) => state.appConfig.categories);

  const form = useForm<FormValues>();
  const { handleSubmit, control, formState } = form;
  const { errors } = formState;

  const onSubmit = (formData: FormValues) => {
    console.log("formData", formData);

    dispatch(
      createPostApi({
        authorType: authorType?authorType:"USER",
        authorId:authorId?authorId:session.userId,
        postType: "QUERY",
        metadata: {
          content: formData.question,
        },
        id: "",
      })
    )
      .then(unwrapResult)
      .then((response) => {
        console.log("response", response);
      })
      .catch((error) => {
        console.log(error);
      });
    dispatch(closeCreatePostModalType());
  };

  const submitButtonHandler = () => {
    buttonRef.current.click();
  };

  return (
    <div className="">
      <form
        className="flex flex-col gap-2 px-6 max-h-[70vh] overflow-auto scrollbar-hidden"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="">
          <Controller
            name="categories"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Categorie is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <DropDown
                title="Category *"
                onChange={onChange}
                placeholder="Choose Category"
                value={value || null}
                optionValue="Id"
                optionLabel="Categories"
                options={categoryOptions}
                dropDownHeight="50px"
                dropDownBorderWidth="2px"
                dropDownBorderColor="#696969"
                error={errors?.categories?.message}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="question"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Question is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <InputText
                className="h-[50px] border-2 border-lxgray-400"
                placeholder="Enter Question"
                title="Question *"
                name="question"
                value={value || ""}
                onChange={onChange}
                isComment={false}
                error={errors?.question?.message}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="discription"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Discription is required",
              },
              maxLength: {
                value: 140,
                message: "Only 140 characters are allowed",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <TextArea
                rows={2}
                name="discription"
                value={value || ""}
                title="Query Description *"
                maxLength={140}
                onChange={onChange}
                placeholder="Your Description"
                error={errors?.discription?.message}
              />
            )}
          />
        </div>

        <div className="justify-end mt-2 hidden">
          <button
            ref={buttonRef}
            type="submit"
            className="w-[135px] h-[40px] bg-golden rounded-xl text-white text-base font-semibold active:scale-95"
          >
            Save
          </button>
        </div>
      </form>
      <div
        className="flex justify-end mt-2 px-6 py-4"
        style={{ boxShadow: "0px -11px 8px -3px #00000033" }}
      >
        <button
          onClick={submitButtonHandler}
          type="submit"
          className="w-[135px] h-[40px] bg-golden rounded-xl text-white text-base font-semibold active:scale-95"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default QueryForm;
//git commit demotest
