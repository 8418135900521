import { useEffect, useRef, useState } from "react";
import moment from "moment";
import CommentIcon from "../assets/icons/CommentIcon";
import LikeIcon from "../assets/icons/LikeIcon";
import { useAppDispatch, useAppSelector } from "../config/hooks";
import profile from "../assets/images/Blank_Placeholders.png";
import Comments from "./Comments";
import EclipsVerticleDotsIcon from "../assets/icons/EclipsVerticleDotsIcon";
import { setCommentIdL1 } from "../slices/profileSlice";
import { doActionOnCommentApi } from "../slices/commentSlice";
import { baseUrl, contentUrl } from "../utils/contant";

const CommentCard = ({ comment, userContent }) => {
  const dispatch = useAppDispatch();
  const menuRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleComment, setToggleComment] = useState(false);
  const session = useAppSelector((state) => state.login.session);
  const { author, authorId, content, counts, createdAt, isLiked, id } = comment;
  const { firstName, lastName, designation, organization, picUrl } = author;
  const { like, reply } = counts;
  const postRelativeTime = moment(createdAt).fromNow();

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (
        menuRef.current &&
        parentRef.current &&
        !menuRef.current.contains(e.target as Node) &&
        !parentRef.current.contains(e.target as Node)
      ) {
        // onClose();
        setToggleModal(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const togglePostModal = () => {
    setToggleModal(!toggleModal);
  };

  const toggleCommentHandler = () => {
    if (toggleComment) {
      if (comment.id === id) {
        setToggleComment(false);
      } else {
        dispatch(setCommentIdL1(id));
      }
    } else {
      if (comment.id === id) {
        setToggleComment(true);
      } else {
        setToggleComment(true);
        dispatch(setCommentIdL1(id));
      }
    }
  };

  const toggleCommentLiked = (commentId) => {
    const isLiked = comment.isLiked;
    const data = {
      action: isLiked ? "unlike" : "like",
      postId: userContent.id, //userContent.id === comment.postId
      commentId,
    };
    dispatch(doActionOnCommentApi(data));
  };

  return (
    <>
      <div
        className={`grow w-full bg-lxgray-700 p-4 rounded-xl space-y-5 border-l-4 ${
          comment.depth === 0
            ? "border-l-golden"
            : comment.depth === 1
            ? "border-l-green-400"
            : "border-lxgray-200"
        }`}>
        <div className="flex gap-4 items-start relative">
          <div className="w-[50px]">
            {picUrl ? (
              <div
                className={`w-[50px] h-[50px] bg-no-repeat bg-center bg-cover rounded-full`}
                style={{
                  backgroundImage: `url(${baseUrl}/${contentUrl}/${picUrl}?t=${session.token})`,
                }}></div>
            ) : (
              <div
                className={`w-[40px] h-[40px] bg-no-repeat bg-center bg-cover rounded-full`}
                style={{
                  backgroundImage: `url(${profile})`,
                }}></div>
            )}
          </div>
          <div className="text-start grow min-w-[70%]">
            <h1 className="text-lg text-white font-semibold leading-5">{`${firstName} ${lastName}`}</h1>
            <p className="text-base text-lxgray-400 font-normal flex flex-wrap items-center justify-between">
              <span className="text-start truncate">{`${designation} | ${organization}`}</span>
              <span className="text-end">{postRelativeTime}</span>
            </p>
          </div>
          {authorId === session.userId && (
            <div
              className="self-start ml-auto mr-0 cursor-pointer"
              ref={parentRef}
              onClick={() => togglePostModal()}>
              <EclipsVerticleDotsIcon />
            </div>
          )}
          {toggleModal && (
            <div
              className="w-[265px] absolute top-6 right-0 z-40 rounded-s-xl rounded-br-xl"
              ref={menuRef}>
              <div
                className={`w-full h-full bg-lxgray-600 shadow-2xl text-lg text-white tracking-tight text-start font-light  rounded-s-xl rounded-br-xl flex flex-col justify-between divide-y-2 divide-lxgray-400/30 `}>
                <h1 className="w-full  p-2 cursor-pointer hover:opacity-90">
                  Delete
                </h1>
              </div>
            </div>
          )}
        </div>
        <div className="w-[92%] ml-auto mr-0">
          <p className="text-white text-base font-normal tracking-wide">
            {content}
          </p>
        </div>
        <div className="w-full border-t-2 border-lxgray-400/30">
          <div
            className={`flex items-center ${
              comment.depth !== 2 ? "justify-end" : "justify-end"
            } text-sm text-lxgray-400 font-semibold py-2 px-4 ml-auto mr-0 gap-3`}>
            <h1
              className={`flex gap-2 items-center justify-center cursor-pointer px-2 text-center ${
                isLiked && "text-golden"
              }`}
              onClick={() => toggleCommentLiked(id)}>
              <LikeIcon color={isLiked ? "#AA933E" : "#8C8C8C"} />
              {`${like} Applauses`}
            </h1>
            {comment.depth !== 2 && (
              <h1
                className="flex gap-2 items-center justify-center cursor-pointer px-2 text-center"
                onClick={() => toggleCommentHandler()}>
                <CommentIcon />
                {`${reply} Replies`}
              </h1>
            )}
          </div>
        </div>
      </div>
      {toggleComment && (
        <div className="w-full pl-14">
          <Comments
            userContent={userContent}
            comments={comment?.replies}
            parentId={comment.id}
            key={id}
          />
        </div>
      )}
    </>
  );
};

export default CommentCard;
