import { InputHTMLAttributes } from "react";

interface ProfileInputProps extends InputHTMLAttributes<HTMLInputElement> {
  title: string;
  error: string;
  name: string;
  className?: string;
  isComment?: boolean;
}

const ProfileInput: React.FC<ProfileInputProps> = ({
  title,
  error,
  name,
  className,
  isComment,
  ...props
}) => {
  return (
    <div>
      <label
        htmlFor={name}
        className={`${
          isComment ? "" : "ml-2"
        } text-sm font-normal text-lxgray-200`}>
        {title}
      </label>
      <input
        className={`h-14 w-full bg-lxgray-700
      outline-none px-4 text-base ring-0 focus:ring-0
      focus:outline-none border  rounded-xl placeholder:text-lxgray-200 text-lxgray-100 ${className}`}
        id={name}
        {...props}
      />
      <p className="text-red-900">{error}</p>
    </div>
  );
};

export default ProfileInput;
