import React from "react";
import NoContentIcon from "../assets/icons/NoContentIcon";

const NoContent = () => {
  return (
    <div>
      <div className="w-fit mx-auto">
        <NoContentIcon />
      </div>
      <h1 className="w-fit mx-auto text-xl text-golden text-center tracking-wider mt-1">
        Nothing to see for now
      </h1>
    </div>
  );
};

export default NoContent;
