import AwardCertificationCard from "../components/Award&CertificationCard";

import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getOtherUserDetailApi } from "../../../slices/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";

const UserAwards = () => {
  const dispatch = useAppDispatch();
  const { profileId } = useParams();
  const userData = useAppSelector((state) => state.user);

  const { userAwards } = userData;

  const [otherUserDetails, setOtherUserDetails] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(
        getOtherUserDetailApi({
          userId: profileId,
        })
      ).then(unwrapResult);
      setOtherUserDetails(response.data.data.userAwards);
    };
    if (profileId) fetchData();
    else setOtherUserDetails(null);
  }, [profileId]);

  if (profileId) {
    return (
      otherUserDetails && (
        <div className="w-full bg-lxgray-700 mt-5 self-start">
          <div className="w-[95%] mx-auto">
            <div className="w-full my-7">
              <h1 className="text-white text-xl font-bold">
                Award & Certification
              </h1>
            </div>
            <div className="mb-4 flex flex-col gap-4">
              {otherUserDetails.map((awardData, index) => {
                return (
                  <AwardCertificationCard
                    key={index}
                    awardData={awardData}
                    editAwdDetails={false}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )
    );
  }

  return (
    <div className="w-full bg-lxgray-700 mt-5 self-start">
      <div className="w-[95%] mx-auto">
        <div className="w-full my-7">
          <h1 className="text-white text-xl font-bold">
            Award & Certification
          </h1>
        </div>
        <div className="mb-4 flex flex-col gap-4">
          {userAwards &&
            userAwards.map((awardData, index) => {
              return (
                <AwardCertificationCard
                  key={index}
                  awardData={awardData}
                  editAwdDetails={false}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default UserAwards;
