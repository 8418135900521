import React from "react";

const DownloadIcon = () => {
  return (
    <div className="hover:scale-95 cursor-pointer">
      <svg
        width="40"
        height="40"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5078 28.0042C21.9679 28.0042 28.0155 21.9591 28.0155 14.5021C28.0155 7.0451 21.9679 1 14.5078 1C7.04764 1 1 7.0451 1 14.5021C1 21.9591 7.04764 28.0042 14.5078 28.0042Z"
          stroke="#AA933E"
        />
        <path
          d="M20.125 14.75C19.9592 14.75 19.8003 14.8158 19.6831 14.9331C19.5658 15.0503 19.5 15.2092 19.5 15.375V17.875C19.5 18.0408 19.4342 18.1997 19.3169 18.3169C19.1997 18.4342 19.0408 18.5 18.875 18.5H10.125C9.95924 18.5 9.80027 18.4342 9.68306 18.3169C9.56585 18.1997 9.5 18.0408 9.5 17.875V15.375C9.5 15.2092 9.43415 15.0503 9.31694 14.9331C9.19973 14.8158 9.04076 14.75 8.875 14.75C8.70924 14.75 8.55027 14.8158 8.43306 14.9331C8.31585 15.0503 8.25 15.2092 8.25 15.375V17.875C8.25 18.3723 8.44754 18.8492 8.79917 19.2008C9.15081 19.5525 9.62772 19.75 10.125 19.75H18.875C19.3723 19.75 19.8492 19.5525 20.2008 19.2008C20.5525 18.8492 20.75 18.3723 20.75 17.875V15.375C20.75 15.2092 20.6842 15.0503 20.5669 14.9331C20.4497 14.8158 20.2908 14.75 20.125 14.75ZM14.0563 15.8188C14.1157 15.8757 14.1858 15.9203 14.2625 15.95C14.3373 15.9831 14.4182 16.0001 14.5 16.0001C14.5818 16.0001 14.6627 15.9831 14.7375 15.95C14.8142 15.9203 14.8843 15.8757 14.9438 15.8188L17.4438 13.3188C17.5614 13.2011 17.6276 13.0414 17.6276 12.875C17.6276 12.7086 17.5614 12.5489 17.4438 12.4313C17.3261 12.3136 17.1664 12.2474 17 12.2474C16.8336 12.2474 16.6739 12.3136 16.5563 12.4313L15.125 13.8688V7.875C15.125 7.70924 15.0592 7.55027 14.9419 7.43306C14.8247 7.31585 14.6658 7.25 14.5 7.25C14.3342 7.25 14.1753 7.31585 14.0581 7.43306C13.9408 7.55027 13.875 7.70924 13.875 7.875V13.8688L12.4438 12.4313C12.3855 12.373 12.3163 12.3268 12.2402 12.2952C12.164 12.2637 12.0824 12.2474 12 12.2474C11.9176 12.2474 11.836 12.2637 11.7598 12.2952C11.6837 12.3268 11.6145 12.373 11.5563 12.4313C11.498 12.4895 11.4518 12.5587 11.4202 12.6348C11.3887 12.711 11.3724 12.7926 11.3724 12.875C11.3724 12.9574 11.3887 13.039 11.4202 13.1152C11.4518 13.1913 11.498 13.2605 11.5563 13.3188L14.0563 15.8188Z"
          fill="#AA933E"
        />
      </svg>
    </div>
  );
};

export default DownloadIcon;
