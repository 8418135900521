import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Suspense, lazy } from "react";
import LoginPage from "../pages/login/LoginPage";
import LoginWithEmailPage from "../pages/login/LoginWithEmailPage";
import EditExperience from "../pages/profile/pages/EditExperience";
import UserProfile from "../pages/profile/pages/UserProfile";
import UserExperience from "../pages/profile/pages/UserExperience";
import EditEducation from "../pages/profile/pages/EditEducation";
import UserEducation from "../pages/profile/pages/UserEducation";
import UserAwards from "../pages/profile/pages/UserAwards";
import EditAwards from "../pages/profile/pages/EditAwards";
import UserPublication from "../pages/profile/pages/UserPublication";
import EditPublication from "../pages/profile/pages/EditPublication";
import JobDescriptionCard from "../pages/job/components/JobDescriptionCard";
import OtpVerificationPage from "../pages/login/OtpVerificationPage";
import WebSignUp from "../pages/login/WebSignUp";
import RecommendedJobsPage from "../pages/job/components/RecommendedJobsPage";
import Discover from "../pages/job/components/Discover";
import MyJobs from "../pages/job/components/MyJobs";
import Hiring from "../pages/job/components/Hiring";
import CreateJob from "../pages/job/components/CreateJob";
import DiscoverEvents from "../pages/Clubs and Events/Events/DiscoverEvents";
import ParticipatingEvents from "../pages/Clubs and Events/Events/ParticipatingEvents";
import HoistingEvents from "../pages/Clubs and Events/Events/HoistingEvents";
import PendingEvents from "../pages/Clubs and Events/Events/PendingEvents";
import DiscoverClubs from "../pages/Clubs and Events/Clubs/DiscoverClubs";
import PendingClubs from "../pages/Clubs and Events/Clubs/PendingClubs";
import JoinedClubs from "../pages/Clubs and Events/Clubs/JoinedClubs";
import OwnedClubs from "../pages/Clubs and Events/Clubs/OwnedClubs";
import AllEvents from "../pages/Clubs and Events/Events/AllEvents";
import Event from "../pages/Clubs and Events/Events/Event";
import CreateEvent from "../pages/Clubs and Events/Events/CreateEvent";
import UpdateEvent from "../pages/Clubs and Events/Events/UpdateEvent";
import AllClubs from "../pages/Clubs and Events/Clubs/AllClubs";
import SurveyPage from "../pages/home/components/survey/SurveyPage";
import TextEditor from "../pages/article/text-editor/TextEditor";
import ViewArticleById from "../pages/article/components/ViewArticleById";
import Club from "../pages/Clubs and Events/Clubs/Club";
import CreateClub from "../pages/Clubs and Events/Clubs/CreateClub";
import CreateCompany from "../pages/company/CreateCompany";
import MyCompanies from "../pages/company/MyCompanies";
import Company from "../pages/company/Company";
import { useEffect } from "react";
import { getCountriesApi } from "../slices/appSlice.ts";
import { useAppDispatch } from "./hooks.ts";
import StaticJobDetails from "../pages/static-page/components/StaticJobDetails.tsx";
import GlobalSearch from "../pages/globalSearch/GlobalSearch.tsx";
import Setting from "../pages/setting/Setting.tsx";
import Bookmarks from "../pages/bookmark/Bookmarks.tsx";
import UpdateClub from "../pages/Clubs and Events/Clubs/UpdateClub";
import MessagePage from "../pages/messages/MessagePage";
import Loading from "../components/basic/Loading.tsx";
import PrivateRoute from "./PrivateRoute.tsx";
import RewardsPage from "../pages/rewards/RewardsPage.tsx";
import DiscoverRewards from "../pages/rewards/components/DiscoverRewards.tsx";
import Memberships from "../pages/rewards/components/Memberships.tsx";
import Partners from "../pages/rewards/components/Partners.tsx";
import Products from "../pages/rewards/components/Products.tsx";
import ProductDetail from "../pages/rewards/components/ProductDetail.tsx";
import LoginWithLinkedin from "../pages/login/LoginWithLinkedin.tsx";


const Home = lazy(() => import("../pages/home/HomePage"));
const Body = lazy(() => import("../pages/body/Body"));

const EventPage = lazy( () => import("../pages/Clubs and Events/Events/EventPage"));
const NetworkPage = lazy(() => import("../pages/network/NetworkPage"));
const JobPage = lazy(() => import("../pages/job/JobPage"));
const ProfilePage = lazy(() => import("../pages/profile/ProfilePage"));

const ClubPage = lazy(() => import("../pages/Clubs and Events/Clubs/ClubPage"));

const CompanyPage = lazy(() => import("../pages/company/CompanyPage"));

const ArticlePage = lazy(() => import("../pages/article/articlePage.tsx"));

const StaticPage = lazy(() => import("../pages/static-page/StaticPage.tsx"));
const JobIntroPage = lazy(
  () => import("../pages/static-page/pages/JobIntroPage.tsx")
);
const AboutUs = lazy(() => import("../pages/static-page/pages/AboutUs.tsx"));
const StaticEventPage = lazy(
  () => import("../pages/static-page/pages/StaticEventPage.tsx")
);
const StaticInsightPage = lazy(
  () => import("../pages/static-page/pages/StaticInsightPage.tsx")
);

const MainPage = lazy(() => import("../pages/static-page/pages/MainPage.tsx"));
const BrandingAndAdvertisment = lazy(
  () => import("../pages/static-page/pages/BrandingAndAdvertisment.tsx")
);
const LeadershipBranding = lazy(
  () => import("../pages/static-page/pages/LeadershipBranding.tsx")
);
const SponsorOurEvents = lazy(
  () => import("../pages/static-page/pages/SponsorOurEvents.tsx")
);
const PartnerWithUs = lazy(
  () => import("../pages/static-page/pages/PartnerWithUs.tsx")
);
const StaticFAQS = lazy(
  () => import("../pages/static-page/pages/StaticFAQS.tsx")
);
const StaticTAndC = lazy(
  () => import("../pages/static-page/pages/StaticTAndC.tsx")
);
const StaticPrivelegesAndOffers = lazy(
  () => import("../pages/static-page/pages/StaticPrivelegesAndOffers.tsx")
);
const CorporateRelations = lazy(
  () => import("../pages/static-page/pages/CorporateRelations.tsx")
);
const StaticPrivacyPolicy = lazy(
  () => import("../pages/static-page/pages/StaticPrivacyPolicy.tsx")
);
const StaticContentDisclamer = lazy(
  () => import("../pages/static-page/pages/StaticContentDisclamer.tsx")
);
// import Chat from "../pages/messages/Chat.tsx";

const routes = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Loading/>}>
        <StaticPage />
      </Suspense>
    ),
    children: [
      {
        path: "/",
        element: (
          <Suspense fallback={<Loading/>}>
            <MainPage />
          </Suspense>
        ),
      },
      {
        path: "/login-about",
        element: (
          <Suspense fallback={<Loading/>}>
            <AboutUs />
          </Suspense>
        ),
      },
      {
        path: "/login-job",
        element: (
          <Suspense fallback={<Loading/>}>
            <JobIntroPage />
          </Suspense>
        ),
      },
      {
        path: "/login-job-details/:id",
        element: <StaticJobDetails />,
      },
      {
        path: "/login-event",
        element: (
          <Suspense fallback={<Loading/>}>
            <StaticEventPage />
          </Suspense>
        ),
      },
      {
        path: "/login-brand",
        element: (
          <Suspense fallback={<Loading/>}>
            <LeadershipBranding />
          </Suspense>
        ),
      },
      {
        path: "/login-insight",
        element: (
          <Suspense fallback={<Loading/>}>
            <StaticInsightPage />
          </Suspense>
        ),
      },
      {
        path: "/branding-advertising",
        element: (
          <Suspense fallback={<Loading/>}>
            <BrandingAndAdvertisment />
          </Suspense>
        ),
      },
      {
        path: "/partner-with-us",
        element: (
          <Suspense fallback={<Loading/>}>
            <PartnerWithUs />
          </Suspense>
        ),
      },
      {
        path: "/sponsor-our-events",
        element: (
          <Suspense fallback={<Loading/>}>
            <SponsorOurEvents />
          </Suspense>
        ),
      },
      {
        path: "/corporate-relations",
        element: (
          <Suspense fallback={<Loading/>}>
            <CorporateRelations />
          </Suspense>
        ),
      },
      {
        path: "/FAQS",
        element: (
          <Suspense fallback={<Loading/>}>
            <StaticFAQS />
          </Suspense>
        ),
      },
      {
        path: "/terms-and-conditions",
        element: (
          <Suspense fallback={<Loading/>}>
            <StaticTAndC />
          </Suspense>
        ),
      },
      {
        path: "/privileges-and-offers",
        element: (
          <Suspense fallback={<Loading/>}>
            <StaticPrivelegesAndOffers />
          </Suspense>
        ),
      },
      {
        path: "/privacy-policy",
        element: (
          <Suspense fallback={<Loading/>}>
            <StaticPrivacyPolicy />
          </Suspense>
        ),
      },
      {
        path: "/content-disclaimer",
        element: (
          <Suspense fallback={<Loading/>}>
            <StaticContentDisclamer />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/login-signup",
    element: <LoginWithEmailPage />,
  },
  {
    path:"/login/callback",
    element:<LoginWithLinkedin/>
  },
  // {
  //   path: "/email",
  //   element: <LoginWithEmailPage />,
  // },
  // {
  //   path: "/otp",
  //   element: <OtpVerificationPage />,
  // },
  {
    path: "/signup",
    element: <WebSignUp />,
  },
  {
    path: "/tab",
    element: (
      <PrivateRoute>
        <Suspense fallback={<Loading/>}>
          <Body />
        </Suspense>
      </PrivateRoute>
    ),
    children: [
      {
        path: "/tab",
        element: (
          <Suspense fallback={<Loading/>}>
            <Home />
          </Suspense>
        ),
      },
      {
        path: "/tab/search",
        element: (
          <Suspense fallback={<Loading/>}>
            <GlobalSearch />
          </Suspense>
        ),
      },
      {
        path: "/tab/network",
        element: (
          <Suspense fallback={<Loading/>}>
            <NetworkPage />
          </Suspense>
        ),
      },
      {
        path: "/tab/article",
        element: (
          <Suspense fallback={<Loading/>}>
            <ArticlePage />
          </Suspense>
        ),
        children: [
          {
            path: "/tab/article",
            element: <TextEditor />,
          },
          {
            path: "/tab/article/:postId",
            element: <ViewArticleById />,
          },
        ],
      },
      {
        path: "/tab/survey/:surveyId",
        element: <SurveyPage />,
      },
      {
        path: "/tab/jobs",
        element: (
          <Suspense fallback={<Loading/>}>
            <JobPage />
          </Suspense>
        ),
        children: [
          {
            path: "/tab/jobs",
            element: <Discover />,
          },
          {
            path: "/tab/jobs/myjobs",
            element: <MyJobs />,
          },
          {
            path: "/tab/jobs/hiring",
            element: <Hiring />,
          },
          {
            path: "/tab/jobs/createjob",
            element: <CreateJob />,
          },
          {
            path: "/tab/jobs/recommended",
            element: <RecommendedJobsPage />,
          },
          {
            path: "/tab/jobs/description/:title/:jobId",
            element: <JobDescriptionCard />,
          },
        ],
      },
      {
        path: "/tab/profile",
        element: (
          <Suspense fallback={<Loading/>}>
            <ProfilePage />
          </Suspense>
        ),
        children: [
          {
            path: "/tab/profile",
            element: <UserProfile />,
          },
          {
            path: "/tab/profile/editexperience-details",
            element: <EditExperience />,
          },
          {
            path: "/tab/profile/experience-details",
            element: <UserExperience />,
          },
          {
            path: "/tab/profile/editeducation-details",
            element: <EditEducation />,
          },
          {
            path: "/tab/profile/education-details",
            element: <UserEducation />,
          },
          {
            path: "/tab/profile/award-details",
            element: <UserAwards />,
          },
          {
            path: "/tab/profile/editaward-details",
            element: <EditAwards />,
          },
          {
            path: "/tab/profile/publication-details",
            element: <UserPublication />,
          },
          {
            path: "/tab/profile/editpublication-details",
            element: <EditPublication />,
          },
        ],
      },
      {
        path: "/tab/profile",
        element: (
          <Suspense fallback={<Loading/>}>
            <ProfilePage />
          </Suspense>
        ),
        children: [
          {
            path: "/tab/profile/:profileId",
            element: <UserProfile />,
          },

          {
            path: "/tab/profile/experience-details/:profileId",
            element: <UserExperience />,
          },

          {
            path: "/tab/profile/education-details/:profileId",
            element: <UserEducation />,
          },
          {
            path: "/tab/profile/award-details/:profileId",
            element: <UserAwards />,
          },

          {
            path: "/tab/profile/publication-details/:profileId",
            element: <UserPublication />,
          },
        ],
      },
      {
        path: "/tab/events",
        element: (
          <Suspense fallback={<Loading/>}>
            <EventPage />
          </Suspense>
        ),
        children: [
          {
            path: "/tab/events",
            element: <DiscoverEvents />,
          },
          {
            path: "/tab/events/myevents/participating",
            element: <ParticipatingEvents />,
          },
          {
            path: "/tab/events/myevents/hoisting",
            element: <HoistingEvents />,
          },
          {
            path: "/tab/events/myevents/pending",
            element: <PendingEvents />,
          },
          {
            path: "/tab/events/all",
            element: <AllEvents />,
          },
          {
            path: "/tab/events/:eventId",
            element: <Event />,
          },
          {
            path: "/tab/events/:eventId/update",
            element: <UpdateEvent />,
          },
          {
            path: "/tab/events/create",
            element: <CreateEvent />,
          },
        ],
      },
      {
        path: "/tab/clubs",
        element: (
          <Suspense fallback={<Loading/>}>
            <ClubPage />
          </Suspense>
        ),
        children: [
          {
            path: "/tab/clubs",
            element: <DiscoverClubs />,
          },
          {
            path: "/tab/clubs/all",
            element: <AllClubs />,
          },
          {
            path: "/tab/clubs/:clubId",
            element: <Club />,
          },
          {
            path: "/tab/clubs/myclubs/pending",
            element: <PendingClubs />,
          },
          {
            path: "/tab/clubs/myclubs/joined",
            element: <JoinedClubs />,
          },
          {
            path: "/tab/clubs/myclubs/owned",
            element: <OwnedClubs />,
          },
          {
            path: "/tab/clubs/create",
            element: <CreateClub />,
          },
          {
            path: "/tab/clubs/:clubId/update",
            element: <UpdateClub />,
          },
        ],
      },
      {
        path: "/tab/company",
        element: (
          <Suspense fallback={<Loading/>}>
            <CompanyPage />
          </Suspense>
        ),
        children: [
          {
            path: "/tab/company",
            element: <MyCompanies />,
          },
          {
            path: "/tab/company/create",
            element: <CreateCompany />,
          },
          {
            path: "/tab/company/:companyId",
            element: <Company />,
          },
        ],
      },
      // {
      //   path: "/tab/messages/:conversationId",
      //   element: <MessagePage />,
      // },
      {
        path: "/tab/rewards",
        element: (
          <Suspense fallback={<Loading/>}>
            <RewardsPage />
          </Suspense>
        ),
        children: [
          {
            path: "/tab/rewards",
            element: <DiscoverRewards />,
          },
          {
            path: "/tab/rewards/memberships",
            element: <Memberships />,
          },
          {
            path: "/tab/rewards/partners/:categoryId",
            element: <Partners />,
          },
          {
            path: "/tab/rewards/products/:partnerId",
            element: <Products />,
          },
          {
            path: "/tab/rewards/products/productdetail/:productId",
            element: <ProductDetail />,
          },
        ]
      },
      {
        path: "/tab/setting",
        element: <Setting />,
      },
      {
        path: "/tab/bookmark",
        element: <Bookmarks />,
      },
    ],
  },
]);

export default function Routes() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCountriesApi(null));
  }, [dispatch]);

  return <RouterProvider router={routes} />;
}
