import axios from "axios";
import { tokenRepo } from "../../src/cache/db";
// Next we make an 'instance' of it

const apiClient = axios.create({
  // .. where we make our configurations
  baseURL: process.env.REACT_APP_BASE_URL,
  responseType: "json",

  // headers: {
  //   common: {
  //     Authorization: `Bearer ${"VLEijc4QTN4yWsGQjmNg0LnXO1vc6eOSAuhde11Qg9Qs7ao5gIoawNpWDxDcZWcnq1HlgJ8sH56ke0KVRMy2W1woyeZbbHMal5QAL4UjK4BhThSMGWs28u0CeBSQBOIy"
  //       }`,
  //   },
  // },
});

// Where you would set stuff like your 'Authorization' header, etc ...
//instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';

// Also add/ configure interceptors && all the other cool stuff

// Where you would set stuff like your 'Authorization' header, etc ...
//instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';
apiClient.interceptors.request.use(
  async function (config) {
    const appToken = await tokenRepo.appToken.get(1);
    let header = {
      "content-type": config.headers.get("content-type")
        ? config.headers.get("content-type")
        : "application/json",
    };
  
    const token = appToken;
    // const token = "VLEijc4QTN4yWsGQjmNg0LnXO1vc6eOSAuhde11Qg9Qs7ao5gIoawNpWDxDcZWcnq1HlgJ8sH56ke0KVRMy2W1woyeZbbHMal5QAL4UjK4BhThSMGWs28u0CeBSQBOIy";
   

    if (token) {
      header = {
        ...header,
        Authorization: `Bearer ${token.token}`,
        // Authorization: `Bearer ${token}`,
      };
    }
    if (config.skipHeader) {
      config.headers = {};
    } else {
      config.headers = header;
    }

    return config;
  },
  function (error) {
    console.error(error);
    return Promise.reject(error);
  }
);
export default apiClient;
