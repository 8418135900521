import React from 'react'
import ProfileImage from "../../../assets/images/Blank_Placeholders.png"
import { baseUrl,contentUrl } from '../../../utils/contant'
import { useAppSelector } from '../../../config/hooks'
import { dropDownByTitle } from '../../../utils/nocoFunctions'
import { Link } from 'react-router-dom'

interface Props{
    fullName?:string,
    picUrl?:string,
    designation?:string,
    organization?:string,
    userId?:string
    }

const Author = ({fullName,picUrl,designation,organization,userId}:Props) => {

    const {token}=useAppSelector((state)=>state.login.session)
    const {designations}=useAppSelector((state)=>state.appConfig)
  return (
    <div className='flex gap-2 sm:gap-4 items-center py-4'>
      <div>
      <Link to={`/tab/profile/${userId}`}>
        <img className='w-8 h-8 sm:w-12 sm:h-12 object-cover border rounded-full' src={`${picUrl?.length>0?`${baseUrl}/${contentUrl}/${picUrl}?t=${token}`:ProfileImage}`} alt="Profile" />
        </Link>
      </div>
    <div>
        <h1 className='text-lxgray-100 text-sm  sm:text-lg'>{fullName}</h1>
        <h3 className='text-lxgray-100 text-xs sm:text-sm'>{dropDownByTitle(designation,designations)}</h3>
        <h3 className='text-golden text-xs'>{organization} </h3>
    </div>

    </div>
  )
}

export default Author
