import { useState, useEffect, useRef } from "react";

const useReadMoreToggle = (initialContent: string) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(
        contentRef.current.clientHeight < contentRef.current.scrollHeight
      );
    }
  }, [initialContent]);

  const textToggleHandler = () => {
    setShowMore(!showMore);
  };

  return {
    contentRef,
    showMore,
    isOverflowing,
    textToggleHandler,
  };
};

export default useReadMoreToggle;
