import React from "react";

const EditDots = ({width="5",height="19"}) => {
  return (
    <div>
    <svg
      width={width}
      height={height}
      viewBox="0 0 5 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
    
        d="M5 16.5C5 15.1193 3.88071 14 2.5 14C1.11929 14 0 15.1193 0 16.5C0 17.8807 1.11929 19 2.5 19C3.88071 19 5 17.8807 5 16.5ZM5 9.5C5 8.11929 3.88071 7 2.5 7C1.11929 7 0 8.11929 0 9.5C0 10.8807 1.11929 12 2.5 12C3.88071 12 5 10.8807 5 9.5ZM2.5 0C3.88071 0 5 1.11929 5 2.5C5 3.88071 3.88071 5 2.5 5C1.11929 5 0 3.88071 0 2.5C0 1.11929 1.11929 0 2.5 0Z"
        fill="white"
      />
    </svg>
    </div>
  );
};

export default EditDots;
