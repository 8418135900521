import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../config/hooks'
import { getMembershipPlansApi, placeOrderApi } from '../../../slices/rewardSlice'
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";
import { TiTick } from "react-icons/ti";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import {Link} from "react-scroll"

const PlanDetail = ({title,setShowPaymentSummary,setPrice,setPlanId}) => {
    const dispatch=useAppDispatch()
    const {placeOrder}=useAppSelector((state)=>state.rewards)

    const {userDetails}=useAppSelector((state)=>state.user)
    const {membershipPlans}=useAppSelector((state)=>state.rewards)
    const [filteredList,setFilteredList]=useState(membershipPlans)
    const { error, isLoading, Razorpay } = useRazorpay();

    useEffect(()=>{
        dispatch(getMembershipPlansApi())
    },[])
    

    useEffect(()=>{
        const newlist=membershipPlans?.filter((ele)=>ele.membership.name===title)
        setFilteredList(newlist)
    },[title])
     
    const handleBuy=(price,id)=>{
      setPrice(price)
      setPlanId(id)
      setShowPaymentSummary(true)
    }
    // const handlePayment = async(price,id) => {
    //   await dispatch(placeOrderApi({planId:id}))
    //   if(placeOrder){
    //       const options: RazorpayOrderOptions = {
    //         key: `${placeOrder?.payment.key}`,
    //         amount: (price * 100), // Amount in paise
    //         currency: "INR",
    //         name: `${userDetails?.firstName} ${userDetails?.lastName}`,
    //         description: 'Welcome for purchase rewards',
    //         image: 'https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/Linkcxo%20new%20Logos/Link%20CXO%20Monogram%20Dark%20Gold-01.png',
    //         order_id: `${placeOrder?.order.paymentStatus.externalOrderId}`, // Generate order_id on server
    //         handler: (response) => {
    //           console.log(response);
    //           alert("Payment Successful!");
    //           // setShowPaymentSummary(false)
    //           // setPopupOpen(true)
    //         },
    //         prefill: {
    //           name: `${userDetails?.firstName} ${userDetails?.lastName}`,
    //           email: userDetails?.email,
    //           contact: userDetails?.mobile.toString(),
    //         },
    //         theme: {
    //           color: "#242525",
    //         },
    //       };
      
    //       const razorpayInstance = new Razorpay(options);
    //       razorpayInstance.open();
    //     }
    //   };
  
  return (
    <>
    <div  className='relative'>
        {
          filteredList?.map((ele)=>{
            return (
              
                    <div className='text-white pt-20  pb-10 px-4 rounded-lg' style={{  background: `linear-gradient(to left top,${ele.membership.backgroundColors[0]}, transparent)` ,}}>
                        <h1 className=" absolute top-4 right-4 w-fit px-5 rounded-full" style={{backgroundColor:`${ele.membership.backgroundColors[0]}`}}>{ele.membership.name}</h1>
                        <h1 className='text-golden text-3xl  font-bold'>{ele.membership.description}</h1>
                        <h2 className='text-sm mb-2 flex items-center text-lxgray-200 line-through '><MdOutlineCurrencyRupee />{ele.membership.price}</h2>

                        <div>{ele.benefits.map((benefit)=>{
                            return (
                                <div key={benefit.id} className='mb-3'>
                                    <div className='flex items-center gap-3'>
                                           <TiTick width={2}/>
                                            <h2>{benefit.name}</h2>
                                    </div>
                                    <p className=' ml-6 text-sm text-lxgray-100'>{benefit.description}</p>

                                </div>
                            )
                        })                       
                        }
                        </div>
                        
                        {/* <button onClick={()=>handlePayment(ele.membership.price-ele.membership.discount,ele.membership.id)} className='mt-4 text-base bg-golden text-center w-full py-3 rounded font-bold tracking-wide hover:text-golden hover:bg-white/80'>Buy Now</button> */}
                       <Link to={"payment-mem"} smooth={true} duration={800} offset={-80}>
                            <button onClick={()=>handleBuy(ele.membership.price-ele.membership.discount,ele.membership.id)} className='mt-4 text-base bg-golden text-center w-full py-3 rounded font-bold tracking-wide hover:text-golden hover:bg-white/80'>Buy Now</button>
                       </Link>

                    </div>
                )
              })
        }
       
    </div>
    </>
  )
}

export default PlanDetail
