import React from "react";

const CommentIcon = ({ width = "15", height = "13", color = "#8C8C8C" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.43992 12.9974C7.26593 12.9809 7.1063 12.8958 6.99792 12.7615L4.82833 10.0624H0.642889C0.472341 10.0624 0.308815 9.99615 0.188337 9.87829C0.0677142 9.76029 0 9.60045 0 9.4336V0.628938C0 0.462091 0.0677104 0.302114 0.188337 0.18425C0.308811 0.0662446 0.472337 0 0.642889 0H14.3571C14.5277 0 14.6912 0.0662408 14.8117 0.18425C14.9323 0.30211 15 0.462088 15 0.628938V9.4336C15 9.60045 14.9323 9.76028 14.8117 9.87829C14.6912 9.99615 14.5277 10.0625 14.3571 10.0625H10.1717L8.00208 12.7617C7.86725 12.9282 7.65605 13.0167 7.43992 12.9974ZM7.50016 11.3596L9.35514 9.04701C9.47576 8.89537 9.66081 8.80617 9.85737 8.8047H13.7145V1.25785H1.28598V8.8047H5.1431C5.33966 8.80616 5.52456 8.89537 5.64518 9.04701L7.50016 11.3596ZM3.64303 7.75652C3.28804 7.75652 3.00014 7.47488 3.00014 7.12758C3.00014 6.78027 3.28803 6.49863 3.64303 6.49863H9.64301C9.998 6.49863 10.2859 6.78012 10.2859 7.12758C10.2859 7.47487 9.99802 7.75652 9.64301 7.75652H3.64303ZM3.64303 5.66017C3.28804 5.66017 3.00014 5.37853 3.00014 5.03123C3.00014 4.68392 3.28803 4.40228 3.64303 4.40228H11.3573C11.7123 4.40228 12.0002 4.68377 12.0002 5.03123C12.0002 5.37852 11.7123 5.66017 11.3573 5.66017H3.64303ZM3.64303 3.56382C3.28804 3.56382 3.00014 3.28218 3.00014 2.93488C3.00014 2.58757 3.28803 2.30593 3.64303 2.30593H11.3573C11.7123 2.30593 12.0002 2.58743 12.0002 2.93488C12.0002 3.28217 11.7123 3.56382 11.3573 3.56382H3.64303Z"
        fill={color}
      />
    </svg>
  );
};

export default CommentIcon;
