import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../config/hooks';
import { useParams } from 'react-router-dom';
import { SiGoogleearth } from "react-icons/si";
import { BiSolidOffer } from "react-icons/bi";
import AccordianDownArrow from '../../../assets/icons/AccordianDownArrow';
import { MdCurrencyRupee } from "react-icons/md";
import PaymentSummary from './PaymentSummary';
import backgroundImage from "../../../assets/images/Blank_Placeholders.png"
import CrossIcon from '../../../assets/icons/CrossIcon';
import PaymentSuccessful from './PaymentSuccessful';
import RewardsPopup from './RewardsPopup';
import { productInterestApi } from '../../../slices/rewardSlice';
import InterestSuccessful from './InterestSuccessful';
import {Link} from "react-scroll"



const ProductDetail = () => {
    const dispatch=useAppDispatch()
    const {products}=useAppSelector((state)=>state.rewards)
    const { productId } = useParams();
    const [filteredList, setFilteredList] = useState(products);
    const [showRedeem,setShowRedeem]=useState(false)
    const [showTC,setShowTC]=useState(false)
    const [showPaymentSummary,setShowPaymentSummary]=useState(false)
    const [price,setPrice]=useState(0)
    const [logo,setLogo]=useState(backgroundImage)
    const [title,setTitle]=useState("")
    const [variantId,setVariantId]=useState("")
    const [popupOpen,setPopupOpen]=useState(false)
    const [interestOpen,setInterestOpen]=useState(false)

    useEffect(() => {
        const newList = products.filter((item) => item.id==productId);
        console.log("newList",newList)
        setFilteredList(newList);
    }, [products, productId]);
  
    const handleBuy=(price:number,logo:string,title:string,variantId:string)=>{
        setPrice(price)
        setLogo(logo)
        setTitle(title)
        setShowPaymentSummary(true)
        setVariantId(variantId)
    }

    const handleInterest=()=>{
         dispatch(productInterestApi({productId}))
         setInterestOpen(true)
    }
    return (
        <div className='relative'> 
            <div id={"payment-top"} className={`text-white px-12 py-2 bg-lxgray-800 ${showPaymentSummary?'opacity-50 pointer-events-none':'' }`}>
            {
                filteredList?.map((ele)=>{
                    return (
                        <div >
                            <div className='relative'>
                                <img src={ele.variants[0].images[0].url} alt="IMage" className='w-full h-[40vh] object-cover' />
                                <img src={ele.variants[0].images[0].url} alt="Logo" className='rounded-full w-20 h-20 object-cover absolute -bottom-10 left-5 border border-black' />
                            </div>
                            
                            <h1 className=' pt-14 pb-4  text-lg text-lxgray-100 font-semibold  '>{ele.variants[0].name}</h1>

                            {ele.variants[0].price>0 && <div className='flex flex-col gap-2 py-2'>
                            <h1 className='text-base text-lxgray-200'>Select Offer</h1>
                            <h3 className='text-golden border border-golden rounded p-1 w-fit px-3'>{ele.variants[0].price}</h3>
                            </div>}
                            
                            <div className='border-t border-lxgray-500 flex justify-around py-4'>
                                <div className='flex flex-col items-center gap-2'>
                                    <div className='bg-lxgray-500 w-fit rounded-full p-2'>
                                        <BiSolidOffer size={44} color='#AA933E'/>      
                                    </div>
                                    <h1>{ele.variants[0].title}</h1>
                                </div>
                                <a href={ele.website} target='_blank'>
                                    <div className='flex flex-col items-center gap-2'>
                                        <div  className='bg-lxgray-500 w-fit rounded-full p-2'>                            
                                            <SiGoogleearth  size={43} color='#AA933E'/>
                                        </div>
                                        <h1 className='text-base '>Visit Website</h1>
                                    </div>
                                </a>
                            </div>

                            <div className='bg-[#32AF94]/20 p-4 mb-2'>
                                <h1 className='text-lxgreen-100 font-semibold text-lg py-2'>{ele.variants[0].name}</h1>
                                <p className='whitespace-pre-wrap text-lxgray-100 text-base'>{ele.attributes[0].about}</p>
                            </div>

                            <div className='border-y-2 border-lxgray-500'>
                                <div onClick={()=>setShowRedeem(!showRedeem)} className='flex items-center justify-between  py-2'>
                                    <h1 className='text-lg '>How to redeem this offer ?</h1>
                                    <div className={` sm:hidden ${showRedeem?"rotate-180":""}`}>
                                    <AccordianDownArrow width='11'/>
                                    </div>
                                    <div className={` hidden sm:block ${showRedeem?"rotate-180":""} `}>
                                        <AccordianDownArrow width='17'/>
                                    </div>
                                </div>
                                {showRedeem && <p className='whitespace-pre-wrap text-lxgray-200 text-sm  pl-4 pb-4'>{ele.attributes[0].howToRedeem}</p>}
                            </div>
                            
                            <div className='border-b-2 border-lxgray-500'>
                                <div onClick={()=>setShowTC(!showTC)} className='flex items-center justify-between  py-2'>
                                    <h1 className='text-lg'>Terms & Conditions</h1>
                                    <div className={` sm:hidden ${showTC?"rotate-180":""}`}>
                                    <AccordianDownArrow width='11'/>
                                    </div>
                                    <div className={` hidden sm:block ${showTC?"rotate-180":""}`}>
                                        <AccordianDownArrow width='17'/>
                                    </div>
                                </div>
                                { showTC && <p className='whitespace-pre-wrap text-lxgray-200 text-sm  pl-4'>{ele.attributes[0].termsAndConditions}</p>}                     
                            </div>

                            <div className='flex justify-end py-2'>
                            { !ele.online && <button onClick={handleInterest} className='px-8 py-2 bg-golden text-white font-semibold rounded hover:scale-105'>Interested</button>}
                            { ele.online && 
                        <Link to={"payment-top"} smooth={true} duration={800} offset={-80}>
                             <button onClick={()=>handleBuy(ele.variants[0].price,ele.variants[0].images[0].url,ele.variants[0].name,ele.variants[0].id)} className='px-8 py-2 bg-golden text-white font-semibold rounded flex items-center hover:scale-105'>Buy for {" "}<span><MdCurrencyRupee /></span>{" "} {ele.variants[0].price}</button>
                        </Link>
                            
                             }
                            </div>
                        </div>
                    )
                })
            }
            </div>
            {showPaymentSummary && <div className='absolute top-2 right-0 w-2/5    '>
                <PaymentSummary price={price} logo={logo} title={title} variantId={variantId} setShowPaymentSummary={setShowPaymentSummary} setPopupOpen={setPopupOpen} membershipPlan={false}/>         
            </div>}
           { !showPaymentSummary && popupOpen && 
                <div>
                    <RewardsPopup setPopupOpen={setPopupOpen}><PaymentSuccessful/></RewardsPopup>
                </div>
            }

            { !showPaymentSummary && interestOpen && 
                <div>
                    <RewardsPopup setPopupOpen={setInterestOpen}><InterestSuccessful/></RewardsPopup>
                </div>
            }
        </div>
  )
}

export default ProductDetail
