import React, { useState } from 'react'
import {Controller, useForm} from "react-hook-form"
import Select  from 'react-select';


const Dropdown = ({name,control,options,placeholder,defaultValue}) => {
  

  return (
   
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Select
            {...field}
            isMulti
            // ref={field.ref}
            options={options}
            placeholder={placeholder}
            className="w-full rounded-md focus:outline-none"
         styles={{
        // Override Tailwind styles
          control: (provided,state) => ({
            ...provided,
            backgroundColor: '#242525', 
            // border: state.isFocused ? '1px solid #FFD700' : 'none',// Set the background color here
              caretColor:"white",
              border: `${"1px"} solid ${state.isFocused ? "#535354" : "#535354"}`,
             borderRadius: "0.5rem",
             boxShadow: state.isFocused ? "none" : "none",
          }),
          menu: (provided) => ({
            ...provided,
            backgroundColor: '#242525', 
            border: 'none',
            
            // Set the background color here
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#4b5563' : 'tranparent', // Change background color on hover
            '&:hover': {
              // backgroundColor: '#4b5563', // Change background color on hover
            },
            border:"none"
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
             // Change the color of the caret here
          }),
          input: (provided) => ({
            ...provided,
            color: 'white',
            border:"none" // Set the color of the text being typed inside the input field
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          clearIndicator: (provided) => ({
            ...provided,
            display: "none",
          }),
            }}           
            // value={options.find(c=>c.value===field.value)}
            // onChange={(e:any)=>e.value ? field.onChange(e.value):field.onChange(e.map((c:any)=>c.value))}
            // defaultValue={defaultValue.map((ele)=>{
            //   return {"label":ele,"value":ele}
            // })}
            value={field?.value?.map(value => options?.find(option => option.value === value))} 
            onChange={(selectedOptions) => {
            // Set the selected values as an array of values
            field.onChange(selectedOptions ? selectedOptions.map(option => option.value) : []);
          }}
          />
        )}
      />
   
  )
}

export default Dropdown
