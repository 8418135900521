import { useEffect, useMemo, useState } from "react";
import RepostCard from "./RepostCard";
import ArticlePost from "./ArticlePost";
import SurveyPost from "./SurveyPost";
import QueryPost from "./QueryPost";
import PollCard from "./PollCard";
import ImagePost from "./ImagePost";

import useReadMoreToggle from "../utils/hooks/useReadMoreToggle";

const Repost = ({ userContent, openComments, feeds = true }) => {
  const [repostuserContent, setRepostuserContent] = useState(null);
  const { contentRef, showMore, isOverflowing, textToggleHandler } =
    useReadMoreToggle(userContent.metadata.content);
  const {
    metadata: { content },
  } = userContent;

  useEffect(() => {
    const repostIdhandler = (userContent: any) => {
      if (userContent.postType === "REPOST") {
        repostIdhandler(userContent.referencePost);
      } else {
        setRepostuserContent(userContent);
      }
    };

    repostIdhandler(userContent);
  }, [userContent]);

  const repostComponent = useMemo(() => {
    if (!repostuserContent) {
      return null;
    }

    const { postType, id, metadata } = repostuserContent;
    const { attachments } = metadata;

    switch (postType) {
      case "POST":
        return (
          <RepostCard userContent={repostuserContent} key={id} feeds={feeds}>
            <ImagePost
              userContent={repostuserContent}
              attachments={attachments}
            />
          </RepostCard>
        );
      case "POLL":
        return (
          <RepostCard userContent={repostuserContent} key={id} feeds={feeds}>
            <PollCard userContent={repostuserContent} />
          </RepostCard>
        );
      case "QUERY":
        return (
          <RepostCard userContent={repostuserContent} key={id} feeds={feeds}>
            <QueryPost
              userContent={repostuserContent}
              openComments={openComments}
            />
          </RepostCard>
        );
      case "SURVEY":
        return (
          <RepostCard userContent={repostuserContent} key={id} feeds={feeds}>
            <SurveyPost userContent={repostuserContent} />
          </RepostCard>
        );
      case "ARTICLE":
        return (
          <RepostCard userContent={repostuserContent} key={id} feeds={feeds}>
            <ArticlePost userContent={repostuserContent} />
          </RepostCard>
        );

      default:
        return null;
    }
  }, [feeds, openComments, repostuserContent]);

  return (
    <div className="space-y-6">
      {content && feeds && (
        <div
          className={`text-sm text-lxgray-100 font-normal space-y-1 ${
            !isOverflowing && "mb-2"
          }`}
        >
          <p
            className={
              !showMore
                ? "line-clamp-3 whitespace-pre-wrap"
                : "line-clamp-none whitespace-pre-wrap"
            }
            ref={contentRef}
          >
            {content}
          </p>
          {isOverflowing && (
            <div className="flex">
              <button
                className="text-xs text-lxgray-400 ml-auto mr-0"
                onClick={textToggleHandler}
              >
                {!showMore ? "... See More" : "... See Less"}
              </button>
            </div>
          )}
        </div>
      )}
      {repostuserContent && <div>{repostComponent}</div>}
    </div>
  );
};

export default Repost;
