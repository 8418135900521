import { createSlice } from "@reduxjs/toolkit";

interface modalReduxState {
  isOpenModal: boolean;
  iscreatePostModalTypeOpen: boolean;
  openedModalType: string;
  createPostModalType: string;
}

const initialState: modalReduxState = {
  isOpenModal: false,
  iscreatePostModalTypeOpen: false,
  openedModalType: "",
  createPostModalType: "",
};

const homeFeedSlice = createSlice({
  name: "createHomeFeedsData",
  initialState,
  reducers: {
    openFeedsModal: (state, action) => {
      state.isOpenModal = !state.isOpenModal;
      state.openedModalType = action.payload;
    },
    closeFeedsModal: (state) => {
      state.isOpenModal = false;
    },
    togggleFeedsModalType: (state) => {
      state.openedModalType = "";
    },
    togggleCreatePostModalType: (state) => {
      state.createPostModalType = "";
    },
    openCreatePostModalType: (state, action) => {
      state.iscreatePostModalTypeOpen = true;
      state.createPostModalType = action.payload;
    },
    closeCreatePostModalType: (state) => {
      state.iscreatePostModalTypeOpen = false;
    },
  },
});

export const {
  openFeedsModal,
  closeFeedsModal,
  togggleFeedsModalType,
  openCreatePostModalType,
  closeCreatePostModalType,
  togggleCreatePostModalType,
} = homeFeedSlice.actions;
export default homeFeedSlice.reducer;
