import {
    createAsyncThunk, createSlice
  } from "@reduxjs/toolkit";
  import { Club } from "../models/club";
  import { Company } from "../models/company";
  import { Job } from "../models/job";
  import { Post, PostResponse } from "../models/post";
  import { SearchData } from "../models/search";
  import { BasicUser } from "../models/user";
  import searchService from "../services/search.service";
import { logout } from "./loginSlice";

  export interface EventResponse{
    id:string,
    bannerImageUrl:string,
    title:string,
    isOffline:string,
    details:string

  }
  export interface SearchReduxState {
    userList: Array<BasicUser>;
    jobSearchList: Array<Job>;
    postSearchList: Array<PostResponse>;
    eventSearchList: Array<EventResponse>;
    clubSearchList: Array<Club>;
    companySearchList: Array<Company>;
    recentlySearchs: Array<string>;
    
  }
  const initialState: SearchReduxState = {
    userList: [],
    jobSearchList: [],
    postSearchList: [],
    eventSearchList: [],
    clubSearchList: [],
    recentlySearchs: [],
    companySearchList: []
  };
  
  export const searchApi = createAsyncThunk(
    "globalSearch/searchApi",
    async (data: SearchData, { getState, rejectWithValue, dispatch }) => {
      try {
        const response = await searchService.userSearch(data);
        return response;
      } catch (error) {
        return rejectWithValue(error.response?.data);
      }
    }
  );
  
  export const globalSearchSlice = createSlice({
    name: "globalSearch",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        .addCase(
          searchApi.fulfilled,
          (state: SearchReduxState, action) => {
            state.eventSearchList = action.payload.data.data.event
            state.jobSearchList = action.payload.data.data.job
            state.userList = action.payload.data.data.user
            state.postSearchList = action.payload.data.data.post
            state.clubSearchList = action.payload.data.data.club
            state.companySearchList = action.payload.data.data.company
            
            let searchText = state.recentlySearchs;
            if (action.meta.arg.text !== '' && !searchText.includes(action.meta.arg.text)) {
              
              searchText.unshift(action.meta.arg.text);
            }
            state.recentlySearchs = searchText;
          }
        )
        .addCase(logout, (state) => {
          // Reset this slice's state to initial state
          return initialState;
        });
    },
  });
  
  
  export default globalSearchSlice.reducer;