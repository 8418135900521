import newImage from "../../../assets/images/Blank_Placeholders.png";
import EditDots from "../../../assets/icons/EditDots";
import DateCard from "./DateCard";

const FilteredCard = () => {
  return (
    <div className="flex text-white bg-lxgray-600 rounded mb-2">
      <img
        src={newImage}
        alt="banner"
        className="w-96 h-48 object-cover rounded"
      />
      <div className=" ml-2  flex flex-col justify-around p-4">
        <div className="flex items-start">
          <div>
            <h1 className="text-xl">
              Likely the Best Marketing Event of 2023 - Growth Inc.
            </h1>
          </div>
          <div className="px-4 relative top-1.5">
            <EditDots />
          </div>
        </div>
        <DateCard newDate={new Date()} />
        <div className="text-sm">
          <span className="text-golden">Offline</span>
          <span>{" | "}</span>
          <span className="">by Leadership Mastery</span>
          <span>{" | "}</span>
          <span className="">Growth Agency</span>
        </div>
      </div>
    </div>
  );
};

export default FilteredCard;
