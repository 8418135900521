import React, { useEffect, useRef, useState } from 'react'
import { CiCirclePlus,CiCircleMinus } from "react-icons/ci";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import Coupon from '../../../assets/icons/Coupon';
import CouponCodes from './CouponCodes';
import { TiTick } from "react-icons/ti";
import LinkCoin from '../../../assets/icons/LinkCoin';
import CrossIcon from '../../../assets/icons/CrossIcon';
import { useAppDispatch, useAppSelector } from '../../../config/hooks';
import { getPaymentSummaryApi, getRewardsPointsApi, placeOrderApi } from '../../../slices/rewardSlice';
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";
import { useParams } from 'react-router-dom';
import { getLoginUserDetailApi } from '../../../slices/userSlice';
import { RewardOrderResponse } from '../../../models/reward';
import { AxiosResponse } from 'axios';


const PaymentSummary = ({price,logo,title,setShowPaymentSummary,variantId,setPopupOpen,membershipPlan,planId}:{price:number,logo:string,title:string,setShowPaymentSummary:any,variantId?:string,setPopupOpen:any,membershipPlan:boolean,planId?:string}) => {
    const dispatch =useAppDispatch()
    const {rewardPoints,paymentSummary,placeOrder}=useAppSelector((state)=>state.rewards)
    const {userId}=useAppSelector((state)=>state.login.session)
    const {userDetails}=useAppSelector((state)=>state.user)
    const [count,setCount]=useState(1)
    const [showCoupons,setShowCoupons]=useState(false)
    const [couponCode,setCouponCode]=useState(null)
    const [couponCodeId,setCouponCodeId]=useState("")
    const [couponAmount,setCouponAmount]=useState(0)
    const [coins,setCoins]=useState(false)
    const { productId } = useParams();
    const { error, isLoading, Razorpay } = useRazorpay();
    const [paymentAttempted, setPaymentAttempted] = useState(false); 
    const Percentage=10

    useEffect(()=>{
        dispatch( getLoginUserDetailApi({userId: userId, }) );
        dispatch(getRewardsPointsApi())
        membershipPlan? dispatch(getPaymentSummaryApi({planId:planId})):dispatch(getPaymentSummaryApi({currency:"",userId:userId,items:[{productId:productId,quantity:count,variantId:variantId,}],rewardPoint:coins?Number(rewardPoints):0,couponId:couponCodeId}))
    },[count,coins,couponCodeId])
    

    const handleIncreaseCount=()=>{
        setCount((prev)=>prev+1)

    }
    const handleDecreaseCount=()=>{
        if(count==1){
            return
        }
        setCount((prev)=>prev-1)
    }
    useEffect(() => {
        if (paymentAttempted) {
            // Place the order when payment is attempted
            if (membershipPlan) {
                dispatch(placeOrderApi({ planId: planId })).then((result ) => {
                    if (placeOrderApi.fulfilled.match(result)) {
                        const response: RewardOrderResponse = result.payload.data.data;
                        const options: RazorpayOrderOptions = {
                        key:  `${response.payment.key}`,
                        amount: (response.order.summary.finalPrice * 100), // Amount in paise
                        currency: "INR",
                        name: `${userDetails?.firstName} ${userDetails?.lastName}`,
                        description: 'Welcome for purchase rewards',
                        image: 'https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/Linkcxo%20new%20Logos/Link%20CXO%20Monogram%20Dark%20Gold-01.png',
                        order_id: `${response.order.paymentStatus.externalOrderId}`, // Generate order_id on server
                        handler: (response) => {
                        console.log(response);
                        alert("Payment Successful!");
                        setShowPaymentSummary(false)
                        setPopupOpen(true)
                        },
                        prefill: {
                        name: `${userDetails?.firstName} ${userDetails?.lastName}`,
                        email: userDetails?.email,
                        contact: userDetails?.mobile.toString(),
                        },
                        theme: {
                        color: "#242525",
                        },
                    };
                
                    const razorpayInstance = new Razorpay(options);
                    razorpayInstance.open();
                }
                });

            } else {
                dispatch(placeOrderApi({
                    currency: "",
                    userId: userId,
                    items: [{
                        productId: productId,
                        quantity: count,
                        variantId: variantId,
                    }],
                    rewardPoint: coins ? Number(rewardPoints) : 0,
                    couponId: couponCodeId
                })).then((result)=>{
                    if (placeOrderApi.fulfilled.match(result)) {
                        // This means the order was placed successfully
                        const response: RewardOrderResponse = result.payload.data.data;
                       const options: RazorpayOrderOptions = {
                        key:  `${response.payment.key}`,
                        amount: (response.order.summary.finalPrice * 100), // Amount in paise
                        currency: "INR",
                        name: `${userDetails?.firstName} ${userDetails?.lastName}`,
                        description: 'Welcome for purchase rewards',
                        image: 'https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/Linkcxo%20new%20Logos/Link%20CXO%20Monogram%20Dark%20Gold-01.png',
                        order_id: `${response.order.paymentStatus.externalOrderId}`, // Generate order_id on server
                        handler: (response) => {
                        console.log(response);
                        alert("Payment Successful!");
                        setShowPaymentSummary(false)
                        setPopupOpen(true)
                        },
                        prefill: {
                        name: `${userDetails?.firstName} ${userDetails?.lastName}`,
                        email: userDetails?.email,
                        contact: userDetails?.mobile.toString(),
                        },
                        theme: {
                        color: "#242525",
                        },
                    };              
                    const razorpayInstance = new Razorpay(options);
                    razorpayInstance.open();
 
                    }
                });
            }
            setPaymentAttempted(false); // Reset after processing
        }
    }, [paymentAttempted,placeOrder]); 

    const handlePayment =  async() => {
        setPaymentAttempted(true);      
      };  

    const discountOnCoins=Number(rewardPoints)*(Percentage/100)
    
  return (
    <div  className='bg-lxgray-700  text-white p-4'>
      {showCoupons && <CouponCodes setCouponCode={setCouponCode} setShowCoupons={setShowCoupons} setCouponAmount={setCouponAmount} setCouponCodeId={setCouponCodeId}/>}

      {!showCoupons && <div> 
             <div  className='flex justify-between'>
                 <h1 className='font-semibold'>PAYMENT SUMMARY</h1>
                 <div onClick={()=>setShowPaymentSummary(false)} className=' cursor-pointer hover:scale-75'>
                    <CrossIcon width={'15'} height={"15"} />
                </div>
            </div>       
            <div className='flex gap-4 mt-4'>
                <img src={logo} alt="" className='w-20 h-20 rounded-full object-cover'/>
                <div>
                    <h1 className='text-xl text-lxgray-100'>{title}</h1>
                    <div className='flex gap-2 items-center '>
                        <CiCircleMinus size={23} onClick={handleDecreaseCount} style={{cursor:'pointer'}}/>
                        <h1 className='text-lg'>{count}</h1>
                        <CiCirclePlus size={23} onClick={handleIncreaseCount} style={{cursor:'pointer'}}/>
                    </div>
                </div>
            </div>
            <div className='flex justify-between mt-4 border-b border-lxgray-500'>
                <h1 className='text-lxgray-200 font-semibold'>Voucher Price</h1>
                <h2 className='text-base mb-2 flex items-center'><MdOutlineCurrencyRupee />{price}</h2>
            </div>
        
            <div className='flex items-center justify-between py-4 border-b border-lxgray-500 '>
                <h1 className='text-base text-lxgray-100 flex gap-1'><Coupon/>Coupon Code</h1>
                <button onClick={()=>setShowCoupons(true)} className='text-golden font-semibold text-xs  outline-dashed outline-2  px-5 py-2 tracking-wider bg-golden/20'>{couponCode?couponCode:'APPLY COUPON'}</button>
            </div>

            <div className='flex  justify-between py-4 border-b border-lxgray-500 '>
                <div>
                    <h1 className='text-base text-lxgray-100 flex items-center gap-2 mb-2'><LinkCoin/>Link CxO Coins</h1>
                    <h1 className='ml-8 text-base text-lxgray-100 flex gap-1 items-center'>( Add your <span>{Number(rewardPoints)-(coins?discountOnCoins:0)}</span>  <LinkCoin />)</h1>
                    <h4 className='text-lxgray-200 ml-8 '>(10 Points = Rs. 1)</h4>
                    <h4 className='text-lxgray-200 ml-8 text-sm'>(You can avail 10% from balance)</h4>
                </div>
                {/* <input ref={checkBoxRef} type="checkbox" className='bg-golden hidden h-4 w-4 text-golden'/> */}
                <div onClick={()=>setCoins(!coins)} className='relative flex  justify-center'>
                   <div className='flex'>
                        <h2 className={` border border-golden p-2.5 rounded w-fit h-fit ${coins?'bg-golden':""} `}></h2>
                       
                   </div>
                   { coins && <div className={`absolute top-0.5`}>
                        <TiTick  />
                    </div>}
                </div>
               
            </div>



            <div className='py-4 border-b border-lxgray-500 '>
                <div className='flex justify-between'>
                    <h3 className='text-lxgray-200'>Total Price</h3>
                    <h2 className='text-base mb-2 flex items-center'><MdOutlineCurrencyRupee />{paymentSummary?.totalPrice}</h2>
                </div>
                <div className='flex justify-between'>
                    <h3 className='text-lxgray-200'>Coupon Code</h3>
                    <h2 className='text-base mb-2 flex items-center'>-<MdOutlineCurrencyRupee />{couponAmount}</h2>
                </div>
                <div className='flex justify-between'>
                    <h3 className='text-lxgray-200'>Rewards Coins</h3>
                    <h2 className='text-base mb-2 flex items-center'>-<MdOutlineCurrencyRupee />{coins?(discountOnCoins*0.1).toFixed(2):0} {coins?<span className=' ml-2 flex  text-[0.7em] items-center relative -bottom-0.5 '>({discountOnCoins} <LinkCoin width='10' height='10'/>)</span>:""}</h2>
                </div>
                <div className='flex justify-between'>
                    <h3 className='text-lxgray-200'>Including GST</h3>
                    <h2 className='text-base mb-2 flex items-center'>+<MdOutlineCurrencyRupee />{paymentSummary?.totalGST}</h2>
                </div>
                <div className='flex justify-between'>
                    <h3 className='text-lxgray-200'>Total Discount</h3>
                    <h2 className='text-base mb-2 flex items-center'>-<MdOutlineCurrencyRupee />{paymentSummary?.totalDiscount}</h2>
                </div>
            </div>

            <div className='flex justify-between py-2'>
                <h1 className='font-semibold'>Total</h1>
                <h3 className='font-bold flex items-center'><MdOutlineCurrencyRupee />{(paymentSummary?.finalPrice)}</h3>
            </div>  
                <button onClick={handlePayment} className='mt-4 text-base bg-golden text-center w-full py-3 rounded font-bold tracking-wide hover:text-golden hover:bg-white/80'>Pay Now</button>       
        </div>}
    </div>
  )
}

export default PaymentSummary
