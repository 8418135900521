import * as React from "react"

function PollIcon() {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // {...props}
    >
      <g clipPath="url(#clip0_4353_5495)">
        <rect width={48} height={48} rx={12} fill="#007AFF" />
        <rect width={48} height={48} rx={12} fill="#fff" fillOpacity={0.88} />
        <path
          d="M11.194 31.215a4.586 4.586 0 00.407 3.57 4.469 4.469 0 005.085 2.032l1.853-.528c.416-.13.863-.13 1.28 0 1.344.467 2.756.707 4.18.711.405 0 .812 0 1.242-.06a13.108 13.108 0 0011.724-12.006A12.974 12.974 0 0023.998 11c-.313 0-.63 0-.934.033A13.108 13.108 0 0011.06 22.757a12.85 12.85 0 00.618 5.362c.14.392.158.817.053 1.22l-.537 1.876zm22.529-6.5a9.678 9.678 0 01-2.384 5.66l-5.476-4.961 6.82-5.846a9.707 9.707 0 011.04 5.139v.008zm-10.432-10.44c.232-.025.472-.025.707-.025a9.606 9.606 0 015.78 1.885c.335.252.655.524.96.813L24.1 22.635l-1.219-8.316c.13-.008.268-.032.41-.045zm-8.997 8.79a9.828 9.828 0 015.427-7.803l1.304 8.799a4.469 4.469 0 001.405 2.628l6.374 5.765a9.553 9.553 0 01-3.871 1.251 9.675 9.675 0 01-4.063-.475 5.257 5.257 0 00-1.673-.292 5.621 5.621 0 00-1.544.219l-1.852.528a1.218 1.218 0 01-1.393-.565 1.316 1.316 0 01-.086-.995l.532-1.869a5.31 5.31 0 00-.097-3.156 9.634 9.634 0 01-.463-4.034z"
          fill="#7AC482"
        />
      </g>
      <defs>
        <clipPath id="clip0_4353_5495">
          <path fill="#fff" d="M0 0H48V48H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PollIcon
