import React from "react";

const Video = () => {
  return (
    <div>
      <svg
        width="14"
        height="11"
        viewBox="0 0 19 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4 11.001H1.52C1.11687 11.001 0.730253 10.8354 0.445198 10.5407C0.160142 10.246 0 9.84632 0 9.42955V1.57241C0 1.15564 0.160142 0.755938 0.445198 0.461238C0.730253 0.166538 1.11687 0.000976563 1.52 0.000976562H11.4C11.8031 0.000976563 12.1897 0.166538 12.4748 0.461238C12.7599 0.755938 12.92 1.15564 12.92 1.57241V9.42955C12.92 9.84632 12.7599 10.246 12.4748 10.5407C12.1897 10.8354 11.8031 11.001 11.4 11.001ZM14.44 3.14383V7.85812L17.8182 10.187C17.9327 10.266 18.0658 10.3113 18.2033 10.3182C18.3408 10.325 18.4775 10.2932 18.5989 10.226C18.7202 10.1588 18.8216 10.0588 18.8923 9.93662C18.9629 9.81448 19.0002 9.67479 19 9.53248V1.46948C19 1.32723 18.9627 1.18764 18.892 1.06561C18.8213 0.943581 18.7199 0.843683 18.5985 0.776576C18.4772 0.709468 18.3406 0.677668 18.2031 0.68457C18.0657 0.691471 17.9327 0.736814 17.8182 0.815762L14.44 3.14383Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default Video;
