import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../config/hooks'
import { addHoistingEvents, addPendingEvents, getMyEventApi } from '../../../slices/eventSlice'
import { Link } from 'react-router-dom'
import NoEvents from "../../../assets/images/NoEventsImage.png"
import CommonCard from '../BothCommon/CommonCard'

const HoistingEvents = () => {
  const dispatch=useAppDispatch()
  const {hoistingEvents}= useAppSelector((state)=>state.events)
  useEffect(()=>{
    fetchData()
  },[])

  const fetchData=async()=>{
    try{
      const response=await dispatch(getMyEventApi({status:"hosting"}))
      dispatch(addHoistingEvents(response.payload))
    }
    catch(error){
      console.log(error,"ERROR")
    }
  }

  return (
    <>
    {hoistingEvents?.length>0 &&
      (
      <div>
        <h1 className='text-white text-lg py-2'>My Events</h1>
        <div className='flex flex-wrap gap-2 justify-start'>
        {
          hoistingEvents?.map((event)=> 
            <Link  key={event.id} to={ `/tab/events/${event.id}`}>
            <CommonCard details={{title:event?.title,detail:event?.details,offline:event?.isOffline,venue:event?.venue,author:`${event.author.firstName} ${event.author.lastName}`,startDate:event?.parsedStartTime,bannerImageUrl:event?.bannerImageUrl,members:event?.members,cardType:"event"}}/>
            </Link>
          )
        }
        </div>
      </div>)}

      {
        hoistingEvents.length===0 && 
        <div className='flex justify-center'>
          <img src={NoEvents} alt="NO EVENTS AVAILABLE" />
        </div>
      }
    </>
  )
}

export default HoistingEvents
