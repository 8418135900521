import React from 'react'

const AlternateProfile = ({firstName,lastName,height="20",width="20"}) => {
  return (
    <h1 className={`bg-lxgray-200 rounded-full w-${width} h-${height} flex items-center justify-center text-white text-xl font-semibold`}>
        {firstName.slice(0, 1)}{lastName.slice(0, 1)}
      </h1>
  )
}

export default AlternateProfile
