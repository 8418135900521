import React, { useEffect } from 'react'
import { useAppSelector } from '../../../../config/hooks'
import OtherLists from '../../../globalSearch/OtherLists'
import { Link } from 'react-router-dom'
import { baseUrl, contentUrl } from '../../../../utils/contant'
import Profile_Banner from "../../../../assets/images/Blank_Placeholders.png";
import AlternateProfile from '../../../../components/AlternateProfile'


const EventClubNetworkSuggestions = ({title,imgUrl,description,description2,buttonText,urlLink}:{title:string,imgUrl:string,description?:string,description2?:string|string[],buttonText:string,urlLink:string}) => {
   
    const {token}= useAppSelector((state)=>state.login.session)
   
  return (
    <div>
      <Link to={urlLink}>
              <div className='flex justify-between items-center  gap-2 bg-lxgray-700 rounded-lg p-3 hover:bg-lxgray-400/60 cursor-pointer'>
                  <div className='flex items-center gap-2 w-4/5'>
                   {imgUrl.length>0 ? <img
                      src={imgUrl.length>0?                       
                        `${baseUrl}/${contentUrl}/${imgUrl}?t=${token}`:Profile_Banner
                      }
                      alt="Job Image"
                      className='w-10 h-10 rounded-full object-cover'
                      />
                    :
                    <AlternateProfile firstName={title} lastName={title?.split(" ")[1]} width='10' height='10'/>
                    }
                    <div className='w-2/3'>
                      <h2 className='text-base  line-clamp-1 '>{title}</h2>
                      <p className=' text-sm text-lxgray-100 line-clamp-1 '>{description}</p>
                      <h3 className='text-sm  text-golden line-clamp-1'>{description2}</h3>
                    </div>
                  </div>
                  <div className='flex-1'>
                    <Link to={urlLink}>
                    <button className='text-golden border border-golden rounded  text-lg font-bold px-2 hover:bg-golden hover:text-white'>{buttonText}</button>
                    </Link>
                  </div>
                 </div>
       </Link>
    </div>
  )
}

export default EventClubNetworkSuggestions
