import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { openProfileModal } from "../../../slices/profileSlice";

import PlusIcon from "../../../assets/icons/PlusIcon";

import ExperienceCard from "../components/ExperienceCard";
import NoContent from "../../../components/NoContent";

interface WorkExperience {
  id: string;
  userId: string;
  organization: string;
  organizationId: string;
  userExperienceDetails: UserExperienceDetails[];
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}

interface UserExperienceDetails {
  location: string;
  locationId: string;
  locationType: string;
  employmentType: string;
  title: string;
  description: string;
  startDate: StartEndDate;
  endDate: StartEndDate;
  isCurrentRole: boolean;
  skills: string[] | null;
  media: string[] | null;
}
interface StartEndDate {
  month: string;
  year: string;
}

const EditExperience = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.user);

  const { userExperience } = userData;

  const toggleModalHandler = (type: string, isEdit: boolean) => {
    const modalTypes = {
      profileModalType: type,
      editForm: isEdit,
      editFormId: "",
    };
    dispatch(openProfileModal(modalTypes));
  };
  return (
    <div className="w-full bg-lxgray-700 mt-5 self-start pb-4">
      <div className="w-[95%] mx-auto">
        <div className="w-full flex justify-between my-7">
          <h1 className="text-white text-xl font-bold">Add Experience</h1>
          <button
            className="text-lg flex gap-2 items-center justify-center text-lxgray-400 font-normal"
            onClick={() => toggleModalHandler("experience", false)}>
            <PlusIcon color="#8C8C8C" /> Add
          </button>
        </div>

        <div className="flex flex-col gap-4">
          {userExperience &&
            userExperience.map(
              (useExperienceData: WorkExperience, index: number) => (
                <ExperienceCard
                  key={index}
                  editExpDetails={true}
                  useExperienceData={useExperienceData}
                />
              )
            )}
        </div>
        {userExperience && userExperience.length == 0 && <NoContent />}
      </div>
    </div>
  );
};

export default EditExperience;
