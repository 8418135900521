import { useState } from 'react';

const Switch = ({isopen}:{isopen?:boolean}) => {
  const [checked, setChecked] = useState(isopen||false);

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <div onClick={handleChange} className='relative'>
        <div className={`rounded-full h-5 w-6 absolute ${checked?"left-7 bg-white":"bg-lxgray-100 ease-in-out"}`}></div>
        <div className={`w-12 h-5 ${checked?"bg-golden":"bg-lxgray-200 ease-in-out"}  z-12 rounded-xl`}></div>
    </div>
  );
};

export default Switch;

