import React, { useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import TextArea from "../basic_components/TextArea";
import DropDown from "../basic_components/DropDown";
import InputText from "../basic_components/InputText";
import { unwrapResult } from "@reduxjs/toolkit";
import PlusIcon from "../../../../assets/icons/PlusIcon";
import Delete_Icon from "../../../../assets/icons/Delete_Icon";
import { createSurveyApi } from "../../../../slices/surveySlice";
import { closeCreatePostModalType } from "../../../../slices/homeFeedSlice";
import { createPostApi } from "../../../../slices/postSlice";
import moment from "moment";

type FormValues = {
  description: string;
  title: string;
  categories: { Categories: string; Id: number };
  questions: {
    questionType: { label: string; value: string };
    question: string;
    options: { option: string }[];
  }[];
  validity: { Title: string; Id: number; Value: number };
};

const questionTypes = [
  {
    label: "Multiple Choice",
    value: "MULTIPLE",
  },
  { label: "Select Option", value: "SINGLE" },
  { label: "Text Answers", value: "STRING" },
];

const SurveyForm = ({authorType,authorId}:{authorType?:string,authorId?:string}) => {
  const buttonRef = useRef(null);
  const dispatch = useAppDispatch();
  const session = useAppSelector((state) => state.login.session);
  const categoryOptions = useAppSelector((state) => state.appConfig.categories);
  const postDurationOptions = useAppSelector(
    (state) => state.appConfig.postDuration
  );
  const form = useForm<FormValues>({
    defaultValues: {
      validity: postDurationOptions[0],
      questions: [
        {
          questionType: { label: "Select Option", value: "SINGLE" },
          question: "",
          options: [{ option: "" }, { option: "" }],
        },
      ],
    },
  });

  const { handleSubmit, control, formState, watch } = form;
  const { errors } = formState;

  const {
    fields: questionFileds,
    append,
    remove: removeQuestionField,
  } = useFieldArray({
    name: "questions",
    control,
  });

  const appendHandler = () => {
    append({
      questionType: { label: "Select Option", value: "SINGLE" },
      question: "",
      options: [{ option: "" }, { option: "" }],
    });
  };

  const onSubmit = (formData: FormValues) => {
    const questions = formData?.questions.map((que) => {
      return {
        question: que.question,
        questionType: que.questionType.value,
        options:
          que.questionType.value === "STRING"
            ? []
            : que.options.map((opt) => opt.option),
      };
    });

    dispatch(
      createSurveyApi({
        authorId:  authorId?authorId:session.userId,
        authorType: authorType?authorType:"USER",
        title: formData.title,
        description: formData.description,
        category: formData.categories.Categories,
        duration: formData.validity.Value,
        questions: questions,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        const surveyResponse = response.data.data;
        const surveyId = surveyResponse.id;
        const duration = surveyResponse.duration;
        const calculatedDate = moment().add(duration, "day").toISOString();
        dispatch(
          createPostApi({
            id: "",
            authorType: "USER",
            authorId: session.userId,
            postType: "SURVEY",
            metadata: {
              surveyId: surveyId,
              validity: calculatedDate,
            },
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });

    dispatch(closeCreatePostModalType());
  };

  const submitClickHandler = () => {
    buttonRef.current.click();
  };
  const questionTypeData = watch(`questions`);
  return (
    <>
      <div className="mb-4 px-6 max-h-[70vh] overflow-auto scrollbar-hidden">
        <form className="flex flex-col gap-2" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Controller
              name="title"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Title is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <InputText
                  className="h-[50px] border-2 border-lxgray-400"
                  placeholder="Write your title over here."
                  title="Enter Survey Title *"
                  name="title"
                  value={value || ""}
                  onChange={onChange}
                  isComment={false}
                  error={errors?.title?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="description"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Description is required",
                },
                maxLength: {
                  value: 2600,
                  message: "Only 2600 characters are allowed",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextArea
                  rows={2}
                  name="description"
                  value={value || ""}
                  title="Enter Survey Description"
                  maxLength={2600}
                  onChange={onChange}
                  placeholder="Eg. What's your favorite productivity hack ?"
                  error={errors?.description?.message}
                />
              )}
            />
          </div>

          <div className="">
            <Controller
              name="categories"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Categorie is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <DropDown
                  title="Categories *"
                  onChange={onChange}
                  placeholder="Choose Category"
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Categories"
                  options={categoryOptions}
                  dropDownHeight="50px"
                  dropDownBorderWidth="2px"
                  dropDownBorderColor="#696969"
                  error={errors?.categories?.message}
                />
              )}
            />
          </div>

          <div className="space-y-4">
            {questionFileds.map((questionField, questionIndex) => {
              const questionType =
                questionTypeData[questionIndex].questionType.value;

              return (
                <div
                  key={questionField.id}
                  className="space-y-2 bg-lxgray-800 px-3 rounded-lg py-4"
                >
                  <Controller
                    name={`questions.${questionIndex}.question`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Question is required",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        className="h-[50px] border-2 border-lxgray-400 bg-transparent"
                        placeholder="Who is credited with inventing the telephone ?"
                        title="Your Question"
                        name={`questions.question-${questionIndex}`}
                        value={value || ""}
                        onChange={onChange}
                        isComment={false}
                        error={
                          errors?.questions?.[questionIndex]?.question?.message
                        }
                      />
                    )}
                  />

                  <div className="">
                    <Controller
                      name={`questions.${questionIndex}.questionType`}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Question type is required",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <DropDown
                          title="Question Type *"
                          onChange={onChange}
                          placeholder="Please Select"
                          value={value || null}
                          optionValue="value"
                          optionLabel="label"
                          isnastedComponent={true}
                          options={questionTypes}
                          dropDownHeight="50px"
                          dropDownBorderWidth="2px"
                          dropDownBorderColor="#696969"
                          error={
                            errors?.questions?.[questionIndex]?.questionType
                              ?.message
                          }
                        />
                      )}
                    />
                  </div>

                  {questionType === "STRING" ? (
                    <TextArea
                      rows={4}
                      name={`questions.${questionIndex}.questionType`}
                      value={""}
                      title=""
                      className="bg-transparent"
                      maxLength={140}
                      onChange={() => {}}
                      placeholder="Eg. What's your favorite productivity hack ?"
                      error={""}
                    />
                  ) : (
                    <OptionsFieldsArray
                      watch={watch}
                      questionIndex={questionIndex}
                      {...{ control }}
                      errors={errors}
                      removeQuestionField={removeQuestionField}
                    />
                  )}
                </div>
              );
            })}
          </div>

          <div className="mt-2">
            <div className="w-fit ml-auto mr-0">
              <button
                type="button"
                className="flex items-center justify-center gap-2 border-2 border-golden bg-golden/10 py-2 px-6 text-center rounded-lg text-golden tracking-wider text-sm font-semibold"
                onClick={appendHandler}
              >
                <PlusIcon color="#AA933E" /> Add Another Question
              </button>
            </div>
          </div>

          <div className="">
            <Controller
              name="validity"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Validity is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <DropDown
                  title="When should the poll end ?"
                  onChange={onChange}
                  placeholder="Please Select"
                  value={value || null}
                  optionValue="Id"
                  optionLabel="Title"
                  options={postDurationOptions}
                  dropDownHeight="50px"
                  dropDownBorderWidth="2px"
                  dropDownBorderColor="#696969"
                  error={errors?.validity?.message}
                />
              )}
            />
          </div>

          <div className="hidden">
            <button ref={buttonRef} type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
      <div
        className="flex justify-end mt-2 py-4 px-6"
        style={{ boxShadow: "0px -11px 8px -3px #00000033" }}
      >
        <button
          type="submit"
          onClick={submitClickHandler}
          className="w-[135px] h-[40px] bg-golden rounded-xl text-white text-base font-semibold active:scale-95"
        >
          Save
        </button>
      </div>
    </>
  );
};

const OptionsFieldsArray = ({
  questionIndex,
  control,
  errors,
  removeQuestionField,
  watch,
}) => {
  const {
    fields: optionFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `questions[${questionIndex}].options`,
  });

  const optionsValue = watch(`questions[${questionIndex}].options`);
  console.log("optionsValue :>> ", optionsValue);
  const addOptionFieldHandler = () => {
    append({ option: "" });
  };

  const removeOptionHandler = (optIndex: number) => {
    remove(optIndex);
  };

  const isUnique = (arr: string[]) => {
    const set = new Set(arr);
    return set.size === arr.length;
  };

  return (
    <>
      <div className={`${optionFields.length > 2 ? "space-y-5" : "space-y-2"}`}>
        {optionFields.map((optionField, optIndex) => (
          <div key={optionField.id} className={`relative`}>
            <Controller
              name={`questions.${questionIndex}.options.${optIndex}.option`}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Option is required",
                },
                validate: (_, allValues) => {
                  console.log("allValues :>> ", allValues);
                  const options = allValues.questions[
                    questionIndex
                  ].options.map((option) => {
                    return option.option;
                  });
                  console.log("options :>> ", options);
                  return isUnique(options) || "Options must be unique";
                },
              }}
              render={({ field: { onChange, value } }) => (
                <InputText
                  className="h-[50px] border-2 border-lxgray-400 bg-transparent"
                  placeholder={`Option ${optIndex + 1} *`}
                  title=""
                  name={`questions.${questionIndex}.options.${optIndex}`}
                  value={value}
                  onChange={onChange}
                  isComment={true}
                  error={
                    errors?.questions?.[questionIndex]?.options?.[optIndex]
                      ?.option?.message
                  }
                />
              )}
            />
            {optIndex > 1 && (
              <button
                className="absolute text-sm leading-none text-golden w-fit border-b border-b-golden -top-5 right-4"
                onClick={() => removeOptionHandler(optIndex)}
              >
                remove
              </button>
            )}
          </div>
        ))}
      </div>
      {optionFields.length < 5 && (
        <div className="w-full flex items-center">
          {questionIndex > 0 && (
            <div
              className="ml-2 cursor-pointer"
              onClick={() => removeQuestionField(questionIndex)}
            >
              <Delete_Icon width="20" height="24" />
            </div>
          )}
          <div className="w-fit ml-auto mr-0">
            <button
              type="button"
              className="border-2 border-green-400 py-2 px-11 text-center rounded-full text-base text-green-400 font-semibold bg-green-400/10"
              onClick={addOptionFieldHandler}
            >
              Add Option
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SurveyForm;
