import React from 'react'

const LinksCard = ({title}:{title:string}) => {
  return (
    <div className=' bg-lxgray-600 text-white mx-1 hover:bg-gradient-to-r hover:bg-golden rounded'>
      <h1 className=' w-44 text-center py-2 text-lg'>{title}</h1>
    </div>
  )
}

export default LinksCard

