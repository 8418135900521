import React from "react";

const Tickmark = () => {
  return (
    <svg
      width="11"
      height="7"
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.33035 6.80357C4.21179 6.91071 4.04383 7 3.89562 7C3.74742 7 3.57945 6.90625 3.45595 6.79911L0.689453 4.29911L1.5688 3.50446L3.90056 5.61161L10.0659 0L10.9304 0.808035L4.33035 6.80357Z"
        fill="white"
      />
    </svg>
  );
};

export default Tickmark;
