import React from "react";

const Clock = () => {
  return (
    <div>
      <svg
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5514 1.75784C10.5756 0.820404 9.30418 0.219733 7.93557 0.0496201C6.56696 -0.120493 5.17823 0.14953 3.98623 0.817525C3.92186 0.853668 3.86554 0.901635 3.82047 0.958688C3.7754 1.01574 3.74248 1.08076 3.72357 1.15004C3.70466 1.21932 3.70014 1.29149 3.71027 1.36245C3.7204 1.4334 3.74498 1.50174 3.78261 1.56357C3.82023 1.6254 3.87017 1.67951 3.92956 1.7228C3.98895 1.76609 4.05664 1.79772 4.12876 1.81588C4.20088 1.83405 4.27602 1.83839 4.34988 1.82866C4.42375 1.81893 4.49489 1.79532 4.55926 1.75917C5.63178 1.15812 6.89544 0.952761 8.11409 1.18148C9.33275 1.4102 10.423 2.05735 11.1811 3.00199C11.9393 3.94663 12.3134 5.12411 12.2336 6.31441C12.1538 7.50471 11.6255 8.62638 10.7475 9.46979C9.86947 10.3132 8.7018 10.8207 7.46266 10.8973C6.22353 10.974 4.99774 10.6146 4.01435 9.88636C3.03096 9.1581 2.35726 8.11081 2.11916 6.94017C1.88105 5.76954 2.09483 4.55568 2.72055 3.52543C2.78501 3.41955 2.81044 3.29588 2.79271 3.17441C2.77498 3.05294 2.71514 2.94075 2.62285 2.85598C2.53056 2.77121 2.41122 2.7188 2.2841 2.70722C2.15697 2.69564 2.0295 2.72557 1.92227 2.79217L0.259594 3.82449C0.196976 3.86336 0.14294 3.91369 0.100574 3.97262C0.0582073 4.03156 0.0283392 4.09792 0.0126747 4.16794C-0.0189613 4.30935 0.00917777 4.45704 0.0909014 4.57852C0.131367 4.63867 0.18377 4.69058 0.245118 4.73128C0.306466 4.77197 0.375558 4.80066 0.44845 4.81571C0.59566 4.8461 0.749409 4.81907 0.875873 4.74057L1.05247 4.63099C0.80423 5.64858 0.83701 6.7108 1.14756 7.71248C1.45811 8.71416 2.03566 9.62054 2.82301 10.3419C3.61037 11.0632 4.58022 11.5745 5.63652 11.825C6.69283 12.0756 7.79894 12.0568 8.84534 11.7704C9.89174 11.484 10.8421 10.9401 11.6024 10.1924C12.3626 9.44473 12.9064 8.51925 13.1798 7.50763C13.4532 6.49601 13.4468 5.43333 13.1611 4.42483C12.8755 3.41633 12.3206 2.49699 11.5514 1.75784Z"
          fill="#8C8C8C"
        />
        <path
          d="M6.75748 3C6.6032 3.00001 6.45525 3.05498 6.34616 3.15281C6.23708 3.25065 6.17579 3.38335 6.17578 3.52171V6.65198C6.17575 6.78524 6.23262 6.91345 6.3347 7.01028L8.25872 8.83627C8.31116 8.88613 8.37404 8.92624 8.44378 8.9543C8.51351 8.98236 8.58873 8.99781 8.66512 8.99978C8.74152 9.00176 8.8176 8.9902 8.88902 8.96579C8.96044 8.94138 9.02579 8.90458 9.08134 8.8575C9.13689 8.81042 9.18156 8.75398 9.21278 8.69141C9.244 8.62885 9.26116 8.56137 9.26329 8.49285C9.26542 8.42433 9.25247 8.3561 9.22519 8.29207C9.1979 8.22804 9.15681 8.16946 9.10427 8.11968L7.33917 6.44449V3.52171C7.33916 3.38335 7.27787 3.25065 7.16879 3.15281C7.0597 3.05497 6.91175 3.00001 6.75748 3Z"
          fill="#8C8C8C"
        />
      </svg>
    </div>
  );
};

export default Clock;
