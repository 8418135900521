import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Dropdown from './Dropdown'
import { useAppDispatch, useAppSelector } from '../../../config/hooks'
import Search from '../../../assets/icons/Search'
import { getFilteredJobApi } from '../../../slices/jobSlice'

interface FilterJobProps {
    setViewFilter: React.Dispatch<React.SetStateAction<boolean>>;
    viewFilter: boolean;
  }

const FilterJob = ({setViewFilter,viewFilter}) => {
    const dispatch=useAppDispatch()
    const {industries,designations,experienceMinRange,experienceMaxRange,minSalaries,maxSalaries,preferredLocation}=useAppSelector((state)=>state.appConfig)
    const {register,handleSubmit,control,reset,watch}=useForm()
    const [expIndex,setExpIndex]=useState(0)
    const [curentIndex,setCurrentIndex]=useState(0)
    const minimumExperience=watch("expMin")
    const minimumSalary=watch("salaryMin")
     
    useEffect(()=>{
        const index = minSalaries.findIndex(item => parseInt(item.Title) === minimumSalary);
        setCurrentIndex(index)
        // console.log(index);
      },[minimumSalary])
      
      useEffect(()=>{
        const index = experienceMinRange.findIndex(item => parseInt(item.Title) === minimumExperience);
        setExpIndex(index)
        // console.log(index);
      },[minimumExperience])

    const onSubmit=(data)=>{
        console.log(data)
        dispatch(getFilteredJobApi({location:data?.location,expMin:data?.expMin,expMax:data?.expMax,salaryMax:data?.salaryMax,salaryMin:data?.salaryMin,industries:data?.industries,workplaceType:data?.workType}))
        setViewFilter(!viewFilter)
    }
    const handleReset=()=>{
        reset()
    }
  return (
    <div className="absolute dark:bg-lxgray-700 right-8  rounded-b-lg  top-0 w-[500px] z-10 px-10 py-4 h-fit overflow-x-scroll no-scrollbar">
        <form onSubmit={handleSubmit(onSubmit)}>
        <div>
            <h1 className='text-xl mb-4'>Filter</h1>
        </div>
        {/* <div className='mb-4 border-b border-lxgray-300'>
            <h2 className='text-lxgray-300 font-semibold'>Sorted By</h2>
           <div className='flex items-center py-2 px-1'>
            <div className='mr-12'>
                <input className="mr-4" type="radio" value={"true"}  {...register("mostRecent")}/>
                <label>Most Recent</label>
            </div>
            <div>
                <input className="mr-4" type="radio" value={"true"}   {...register("mostRecent")} />
                <label>Most Relevant</label>
            </div>
           </div>
        </div> */}

        <div className='mb-4 border-b border-lxgray-300'>
            <h2 className='text-lxgray-300 font-semibold'>Work Mode</h2>
            <div className='flex items-center justify-between py-2 px-1'> 
                <div>
                    <input className="mr-4" type="checkbox" value="On-Site" {...register("workType")}/>
                    <label>Onsite</label>
                </div>
                <div>
                    <input className="mr-4" type="checkbox" value="Hybrid" {...register("workType")} />
                    <label>Hybrid</label>
                </div>
                <div>
                    <input className="mr-4" type="checkbox" value="Remote" {...register("workType")} />
                    <label>Remote</label>
                </div>
            </div>
        </div>

        <div className=" flex flex-col mb-4 ">
        <h2  className=" text-lxgray-300 font-semibold">
          Experience
        </h2>
        <div className="flex justify-between">
        <div className="text-white w-1/2">
          <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white w-full' defaultValue={""} {...register('expMin', { setValueAs: (value) => parseInt(value) })}>
          <option value={""} disabled>Minimum</option>
          {experienceMinRange.map((ele,i)=><option key={`${i}Fm`} value={parseInt(ele.Title)}>{ele.Title}</option>)}
          </select>
         </div>
         <div className="text-white relative w-1/2 pl-2">
           <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white w-full' defaultValue={""} {...register('expMax', { setValueAs: (value) => parseInt(value) })}>
          <option value={""} disabled>Maximum</option>
          {minimumExperience && experienceMaxRange.filter((ele)=>ele.Id>expIndex+1).map((ele,i)=><option key={`${i}Fmx`} value={parseInt(ele.Title)}>{ele.Title}</option>)}
          </select>
          </div>
          </div>
      </div>

      <div className="flex flex-col text-lxgray-300 flex-wrap mb-4">
      <h2  className=" text-lxgray-300 font-semibold">
          Select Salary (INR)
        </h2>
        <div className="flex flex-1 justify-between flex-wrap ">
            <div className="flex flex-col w-1/2 px-1 ">
            <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white' defaultValue={""}  {...register('salaryMin',{ setValueAs: (value) => parseFloat(value) })}>
            <option value="" disabled>Minimum</option>
            {minSalaries.map((ele,i)=><option key={`${i}Fms`} value={(ele.Title.slice(0,2))}>{ele.Title}</option>)}
            </select>
            </div>

            <div className="flex flex-col w-1/2 pl-1">
            <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white' defaultValue={""} {...register('salaryMax',{ setValueAs: (value) => parseFloat(value) })}>
            <option value="" disabled>Maximum</option>
            {minimumSalary && maxSalaries.filter((ele)=>ele.Id>curentIndex+2).map((ele,i)=><option key={`${i}Fmxs`} value={(ele.Title.slice(0,2))}>{ele.Title}</option>)}
            </select>
            </div>
        </div>
      </div>

        <div className='mb-4'>
            <label className='text-lxgray-300'>Select Location</label>
            <Dropdown name="location" control={control} placeholder={<Search/>} defaultValue={[]} options={preferredLocation.map((ele)=>{
                return { value:ele.Title,label:ele.Title}
            })}  />
        </div>

        <div className='mb-4'>
        <label className='text-lxgray-300'>Select Industries</label>
        <Dropdown name="industries" control={control} placeholder={<Search/>} defaultValue={[]} options={industries.map((ele)=>{
            return { value:ele.Name,label:ele.Name}
        })}  />
        </div>

        {/* <div className='mb-4'>
        <label className='text-lxgray-300'>Select Roles *</label>
        <Dropdown name="Role" control={control} placeholder={<Search/>} defaultValue={[]} options={designations.map((ele)=>{
            return { value:ele.Title,label:ele.Title}
        })}  />
        </div> */}
{/* 
        <div className='mb-4'>
            <h1 className='text-lxgray-300'>Date Range</h1>
            <div className='flex flex-row flex-wrap'>
            <label htmlFor="" className='mr-10'>
                <input type="radio" value={1} className='mr-4' {...register("lastPosted")}/>Last 1 Day
            </label>
            <label htmlFor="" className='mr-10'>
                <input type="radio" value={3} className='mr-4' {...register("lastPosted")}/>Last 3 Day
            </label>
            <label htmlFor="" className='mr-10'>
                <input type="radio" value={7} className='mr-4' {...register("lastPosted")}/>Last 7 Day
            </label>
            <label htmlFor="" className='mr-10'>
                <input type="radio" value={15} className='mr-4'{...register("lastPosted")}/>Last 15 Day
            </label>
            <label htmlFor="">
                <input type="radio" value={30} className='mr-4'{...register("lastPosted")}/>Last 30 Day
            </label>
            </div>
        </div> */}

        
        <div className='text-end'>

        <button className='px-4' onClick={handleReset}>Reset All</button>
        <button type='submit' className='bg-golden px-6 py-1 rounded-lg hover:bg-lxgray-100 hover:text-golden '>Apply</button>
        </div>
        </form>
    </div>
  )
}

export default FilterJob
