import React from 'react'
import Industry from '../../../assets/Job Icon/Industry'
import Job_Time from '../../../assets/Job Icon/Job_Time'
import Location from '../../../assets/Job Icon/Location'
import Experience from '../../../assets/Job Icon/Experience'
import Salary from '../../../assets/Job Icon/Salary'
import Skills from '../../../assets/Job Icon/Skills'
import Author from './Author'
import CommentIcon from '../../../assets/icons/CommentIcon'
import SideChatBox from '../../messages/SideChatBox'
import { useAppDispatch, useAppSelector } from '../../../config/hooks'
import { addDetails, sideChatActive } from '../../../slices/chatSlice'
import { getLoginUserDetailApi, getUserDetailApi } from '../../../slices/userSlice'
import { multipleValueDropdown, multipleValueDropdownByName } from '../../../utils/nocoFunctions'
import { Link } from 'react-router-dom'


const JobDetailDescription = ({jobDes}) => {
  const {active}=useAppSelector((state)=>state.chat.sideChat)
  const {userId} =useAppSelector((state)=>state.login.session)
 const {userDetails}=useAppSelector((state)=>state.user)
 const {preferredLocation,industries}=useAppSelector((state)=>state.appConfig)
  const dispatch=useAppDispatch()
  const handleOpenChat=()=>{
    dispatch(sideChatActive())
    dispatch(addDetails({jobId:jobDes.jobApplication.jobId,applicationId:jobDes.jobApplication.id,title:`${jobDes.author.firstName} ${jobDes.author.lastName}`}))
    dispatch(getLoginUserDetailApi({userId}))
  }

console.log("JOBDES",jobDes)

  return (
    <div className=''>
    
    <div className="flex  justify-start text-sm text-lxgray-100 pt-2">
      <div className="px-4 mr-40 w-3/5 ">
        <div className="flex p-1"><Job_Time/><span className="ml-4">{jobDes?.jobType=="FULL_TIME"?"Full Time":jobDes?.jobType} | { jobDes?.workplaceType}</span></div>
        <div className="flex p-1"><Location/><span className="ml-4">{multipleValueDropdown(jobDes?.location,preferredLocation)}</span></div>   
      </div>
      <div className="w-2/5">
        {(!jobDes.hideSalary || jobDes.authorId===userId) && <div className="flex p-1"><Salary/><span className="ml-4">{`${jobDes?.salary?.min } - ${ jobDes?.salary?.max} ${jobDes?.salary?.max.toString().length==1?"Cr":"Lakh"}`}</span></div>}
        <div className="flex p-1">
          <Experience/><span className="ml-4">{jobDes?.experience?.min} - {jobDes?.experience?.max} Years</span>
        </div>
      </div>
      
    </div>
    
      <div className=" flex px-5 text-sm text-lxgray-100"><Industry /> <span className="ml-4">{multipleValueDropdownByName(jobDes?.industries,industries)}</span></div>
       <div className="flex px-5 text-sm text-lxgray-100  border-b border-lxgray-400 mb-2 pb-2"><Skills/><span className="ml-4">{jobDes?.skills?.join(", ")}</span></div>

    <div className='flex flex-col  '>
      <h2 className="py-2">Job Details</h2>
      <div className="text-sm text-lxgray-100 break-words whitespace-pre-line">{jobDes?.description}</div>
      
        <div className='bg-lxgray-800 px-4 mt-10 flex items-center justify-between'>
        <Author fullName={`${jobDes?.author?.firstName} ${jobDes?.author?.lastName}`} picUrl={jobDes?.author?.picUrl} designation={jobDes?.author?.designation} organization={jobDes?.author?.organization} userId={jobDes?.author?.userId}/>
          {jobDes?.applied && <div onClick={handleOpenChat} className='border border-golden p-2.5 rounded-full cursor-pointer'>
            <CommentIcon width={"27"} height={"23"} color='#AA933E'/>      
          </div>}
        </div>
     
    </div>
      {active && <div className="fixed bottom-0 right-0 w-1/3 h-3/4">
          <SideChatBox/>
      </div>}

    </div>
  )
}

export default JobDetailDescription
