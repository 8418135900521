import React from "react";

const Private = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4679_2818)">
        <path
          d="M11.7188 7.03125V3.98438C11.7188 1.78125 9.80859 0 7.5 0C5.19141 0 3.28125 1.78125 3.28125 3.98438V7.03125H4.6875V4.21875C4.68469 3.84862 4.75552 3.48164 4.89587 3.13914C5.03622 2.79664 5.24328 2.48548 5.505 2.22375C5.76673 1.96203 6.07789 1.75497 6.42039 1.61462C6.76289 1.47427 7.12987 1.40344 7.5 1.40625C7.87013 1.40344 8.23711 1.47427 8.57961 1.61462C8.92211 1.75497 9.23327 1.96203 9.495 2.22375C9.75672 2.48548 9.96378 2.79664 10.1041 3.13914C10.2445 3.48164 10.3153 3.84862 10.3125 4.21875V7.03125H11.7188Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7508 7.5H2.43789C2.05654 7.5 1.69081 7.65149 1.42115 7.92115C1.15149 8.19081 1 8.55654 1 8.93789V12.3047C1 13.1438 1.33336 13.9486 1.92673 14.542C2.52011 15.1354 3.3249 15.4688 4.16406 15.4688H10.0234C10.8626 15.4688 11.6674 15.1354 12.2608 14.542C12.8541 13.9486 13.1875 13.1438 13.1875 12.3047V8.93789C13.1875 8.55674 13.0362 8.19119 12.7668 7.92156C12.4974 7.65194 12.1319 7.50031 11.7508 7.5ZM8.50684 9.47788C8.61496 9.65942 8.67198 9.86682 8.67188 10.0781C8.67176 10.306 8.60518 10.529 8.4803 10.7196C8.35541 10.9103 8.17764 11.0604 7.96875 11.1516V12.0703C7.96875 12.1946 7.91936 12.3139 7.83146 12.4018C7.74355 12.4897 7.62432 12.5391 7.5 12.5391C7.37568 12.5391 7.25645 12.4897 7.16854 12.4018C7.08064 12.3139 7.03125 12.1946 7.03125 12.0703V11.1516C6.83759 11.067 6.67038 10.9317 6.54733 10.76C6.42428 10.5882 6.34997 10.3863 6.33225 10.1758C6.31454 9.96521 6.35408 9.75378 6.4467 9.56386C6.53932 9.37393 6.68157 9.21259 6.85839 9.0969C7.03521 8.98121 7.24002 8.91548 7.45114 8.90667C7.66226 8.89786 7.87183 8.94629 8.05767 9.04685C8.24352 9.1474 8.39871 9.29634 8.50684 9.47788Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4679_2818">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Private;
