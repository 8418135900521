import Profile from "../profile/Profile";
import SurveyAnswer from "./SurveyAnswer";

const SurveyPage = () => {
  return (
    <div className="dark:bg-black">
      <div style={{ minHeight: `calc(100vh - 76px)`}} className="w-11/12 mx-auto flex flex-row justify-between gap-2 ">
        <div className="w-[20%] overflow-y-auto scrollbar-hidden py-5">
          <Profile />
        </div>
        <div className="w-[60%] py-5">
          <SurveyAnswer />
        </div>
        <div className="w-[20%] overflow-y-auto scrollbar-hidden py-5">
          <Profile />
        </div>
      </div>
    </div>
  );
};

export default SurveyPage;
