import apiClient from "../config/axiosConfig";
import { AxiosPromise } from "axios";
import { ApiResponse } from "../models/base";
import { UploadFileRequest, UploadFileResponse } from "../models/content";

class ContentService {
  uploadFile(
    uploadFileData: UploadFileRequest
  ): AxiosPromise<ApiResponse<Array<UploadFileResponse>>> {
    return apiClient.post(`/v1/contents`, uploadFileData.data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

const contentService = new ContentService();
export default contentService;
