import React from "react";

const AudioPauseIcon = () => {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="21.5" cy="21.5" r="21.5" fill="#AA933E" />
      <rect x="15" y="13" width="5" height="18" rx="2.5" fill="#D9D9D9" />
      <rect x="24" y="13" width="5" height="18" rx="2.5" fill="#D9D9D9" />
    </svg>
  );
};

export default AudioPauseIcon;
