import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetOTPRequest,
  NewSignUpRequest,
  Session,
  VerifyOTPRequest,
} from "../models/auth";
import { BaseApiState } from "../models/base";
import authService from "../services/auth.service";
import apiErrorToText from "../models/error";
import { tokenRepo } from "../cache/db";
export interface LoginUIReduxState {
  email?: string;
  otp: string;
  countryCode?: number;
  phoneNo?: number;
  requestId: string;
  apiState: BaseApiState;
  accountExists: boolean;
}
export interface LoginReduxState {
  userId: string;

  session: Session;
  ui: LoginUIReduxState;
}
const initialState: LoginReduxState = {
  userId: null,
  session: null,
  ui: {
    accountExists: null,
    otp: "",
    requestId: null,
    apiState: {
      error: false,
      loaded: false,
      loading: false,
      message: "",
    },
  },
};

export const getOtpApi = createAsyncThunk(
  "login/getOtpApi",
  async (data: GetOTPRequest, { getState, rejectWithValue }) => {
    try {
      return await authService.getOTP(data);
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const verifyOtpApi = createAsyncThunk(
  "login/verifyOtpApi",
  async (data: VerifyOTPRequest, { getState, rejectWithValue }) => {
    try {
      return await authService.verifyOTP(data);
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const loginThroughLinkedinApi = createAsyncThunk(
  "login/loginThroughLinkedinApi",
  async (authCode: string, { getState, rejectWithValue }) => {
    try {
      return await authService.loginThroughLinkedIn(authCode);
      // console.log("Response",reponse) 
    } catch (error) {
      console.log("ERRORO",error)
      return rejectWithValue(error.response?.data);
    }
  }
);

export const registerApi = createAsyncThunk(
  "login/register",
  async (data: NewSignUpRequest, { getState, rejectWithValue }) => {
    try {
      return await authService.newSignUp(data);
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoginError: (state: LoginReduxState, action: PayloadAction<string>) => {
      state.ui.apiState = {
        ...state.ui.apiState,
        error: true,
        message: action.payload,
      };
    },
    clearLoginError: (
      state: LoginReduxState,
      action: PayloadAction<string>
    ) => {
      state.ui.apiState = {
        ...state.ui.apiState,
        error: false,
        message: null,
      };
    },

    removeSessionData :(state)=>{
      state.session=null;
      state.userId=null;
      state.ui={
        accountExists: null,
        otp: "",
        requestId: null,
        apiState: {
          error: false,
          loaded: false,
          loading: false,
          message: "",
        },
      }
    },
    
      // other reducers...
      logout: () => initialState,  // Reset state to initial state on logout
    
  },
  extraReducers: (builder) => {
    builder.addCase(getOtpApi.pending, (state: LoginReduxState, action) => {
      state.ui.apiState = {
        error: true,
        loaded: true,
        loading: false,
      };
    });
    builder.addCase(
      getOtpApi.rejected,
      (state: LoginReduxState, action: any) => {
        state.ui.apiState = {
          error: true,
          loaded: true,
          loading: false,
          message: apiErrorToText(action.payload?.error.message),
        };
      }
    );
    builder.addCase(getOtpApi.fulfilled, (state: LoginReduxState, action) => {
      const req = action.meta.arg;
      state.ui = {
        ...state.ui,
        apiState: {
          error: false,
          loaded: true,
          loading: false,
        },
        countryCode: req.countryCode,
        email: req.emailId,
        phoneNo: req.phoneNo,
        requestId: action.payload.data.data.requestId,
      };
    });
    builder.addCase(
      verifyOtpApi.rejected,
      (state: LoginReduxState, action: any) => {
        console.log(action);
        state.ui.apiState = {
          error: true,
          loaded: true,
          loading: false,
          message: apiErrorToText(action.payload?.error.message),
        };
      }
    );
    builder.addCase(
      verifyOtpApi.fulfilled,
      (state: LoginReduxState, action) => {
        const data = action.payload.data.data;
        state.ui.apiState = {
          error: false,
          loaded: true,
          loading: false,
        };
        state.ui.accountExists = data.accountExists;
        if (data.session) {
          state.session = data.session;
          tokenRepo.appToken.add({ token: data.session.token, id: 1 });
        }
      }
    );
    builder.addCase(
      loginThroughLinkedinApi.fulfilled,
      (state: LoginReduxState, action) => {
        console.log(action.payload,"PAYLOAD")
        const data = action.payload.data.data;
        state.ui.apiState = {
          error: false,
          loaded: true,
          loading: false,
        };
        state.ui.accountExists = data.accountExists;
        state.ui.requestId=data.requestId
        if (data.session) {
          state.session = data.session;
          tokenRepo.appToken.add({ token: data.session.token, id: 1 });
        }
      }
    );
    builder.addCase(registerApi.rejected, (state: LoginReduxState, action) => {
      state.ui.apiState = {
        error: true,
        loaded: true,
        loading: false,
      };
    });
    builder.addCase(registerApi.fulfilled, (state: LoginReduxState, action) => {
      state.ui.apiState = {
        error: false,
        loaded: true,
        loading: false,
      };
      const apiRes = action.payload.data;
      state.userId = apiRes.data.session.userId;
      state.session = apiRes.data.session;
      state.ui.accountExists = true;
      tokenRepo.appToken.add({ token: apiRes.data.session.token, id: 1 });
    });
  },
});
export const { clearLoginError ,removeSessionData,logout} = loginSlice.actions;
export default loginSlice.reducer;
