import { unwrapResult } from "@reduxjs/toolkit";
import { store } from "./store";
import { uploadFiles } from "./../slices/postSlice";
import { PostAttachment } from "../models/post";
import { toast } from "react-toastify";

class UploadContent {
  //Upload Post Content
  async uploadContent(
    postAuthor: string,
    data: any,
    callback: { (response: any) }
  ) {
    const formData = new FormData();

    formData.append("key", "users/" + postAuthor + "/post");
    for (let i = 0; i < data.length; i++) {
      formData.append("files", data.item(i));
    }

    store
      .dispatch(
        uploadFiles({
          data: formData,
        })
      )
      .then(unwrapResult)
      .then((response) => {
        if (!response.data.error) {
          console.log(response);
          // toast.success("Uploaded SuccessFully")
          const atts = response.data.data.map((a) => {
            return {
              contentType: a.contentType,
              id: a.id,
              name: a.fileName,
              size: a.size,
              url: a.url,
            } as PostAttachment;
          });
          callback(atts);
        }
      })
      .catch((error) => {
        console.log(error, "This is error");
      });
  }

  //UPload PRofile Content
  async uploadProfileContent(
    postAuthor: string,
    data: any,
    callback: { (response: any) }
  ) {
    const formData = new FormData();

    console.log(data, "data");
    formData.append("key", "users/" + postAuthor + "/profile");
    for (let i = 0; i < data.length; i++) {
      formData.append("files", data.item(i));
    }

    store
      .dispatch(
        uploadFiles({
          data: formData,
        })
      )
      .then(unwrapResult)
      .then((response) => {
        if (!response.data.error) {
          console.log(response);
          toast.success("Uploaded SuccessFully");

          const atts = response.data.data.map((a) => {
            return {
              contentType: a.contentType,
              id: a.id,
              name: a.fileName,
              size: a.size,
              url: a.url,
            } as PostAttachment;
          });
          callback(atts);
        }
      })
      .catch((error) => {
        console.log(error, "This is error");
      });
  }

  //Upload Job Resume
  async uploadJobResume(
    postAuthor: string,
    data: any,
    callback: { (response: any) }
  ) {
    const formData = new FormData();

    console.log(data, "data");
    formData.append("key", "users/" + postAuthor + "/job");
    for (let i = 0; i < data.length; i++) {
      formData.append("files", data.item(i));
    }

    store
      .dispatch(
        uploadFiles({
          data: formData,
        })
      )
      .then(unwrapResult)
      .then((response) => {
        if (!response.data.error) {
          console.log(response);
          toast.success("Uploaded SuccessFully");
          const atts = response.data.data.map((a) => {
            return {
              contentType: a.contentType,
              id: a.id,
              name: a.fileName,
              size: a.size,
              url: a.url,
            } as PostAttachment;
          });
          callback(atts);
        }
      })
      .catch((error) => {
        console.log(error, "This is error");
      });
  }

  async uploadEventContent(
    postAuthor: string,
    data: any,
    callback: { (response: any) }
  ) {
    const formData = new FormData();

    console.log(data, "data");
    formData.append("key", "users/" + postAuthor + "/event");
    for (let i = 0; i < data.length; i++) {
      formData.append("files", data.item(i));
    }

    store
      .dispatch(
        uploadFiles({
          data: formData,
        })
      )
      .then(unwrapResult)
      .then((response) => {
        if (!response.data.error) {
          console.log(response);
          toast.success("Uploaded SuccessFully");
          const atts = response.data.data.map((a) => {
            return {
              contentType: a.contentType,
              id: a.id,
              name: a.fileName,
              size: a.size,
              url: a.url,
            } as PostAttachment;
          });
          callback(atts);
        }
      })
      .catch((error) => {
        console.log(error, "This is error");
      });
  }

  async uploadClubContent(
    postAuthor: string,
    data: any,
    callback: { (response: any) }
  ) {
    const formData = new FormData();

    console.log(data, "data");
    formData.append("key", "users/" + postAuthor + "/club");
    for (let i = 0; i < data.length; i++) {
      formData.append("files", data.item(i));
    }

    store
      .dispatch(
        uploadFiles({
          data: formData,
        })
      )
      .then(unwrapResult)
      .then((response) => {
        if (!response.data.error) {
          console.log(response);
          toast.success("Uploaded SuccessFully");
          const atts = response.data.data.map((a) => {
            return {
              contentType: a.contentType,
              id: a.id,
              name: a.fileName,
              size: a.size,
              url: a.url,
            } as PostAttachment;
          });
          callback(atts);
        }
      })
      .catch((error) => {
        console.log(error, "This is error");
      });
  }

  async uploadCompanyContent(
    postAuthor: string,
    data: any,
    callback: { (response: any) }
  ) {
    const formData = new FormData();

    console.log(data, "data");
    formData.append("key", "users/" + postAuthor + "/company");
    for (let i = 0; i < data.length; i++) {
      formData.append("files", data.item(i));
    }

    store
      .dispatch(
        uploadFiles({
          data: formData,
        })
      )
      .then(unwrapResult)
      .then((response) => {
        if (!response.data.error) {
          console.log(response);
          toast.success("Uploaded SuccessFully");
          const atts = response.data.data.map((a) => {
            return {
              contentType: a.contentType,
              id: a.id,
              name: a.fileName,
              size: a.size,
              url: a.url,
            } as PostAttachment;
          });
          callback(atts);
        }
      })
      .catch((error) => {
        console.log(error, "This is error");
      });
  }

//UPload JD
  async uploadJD(
    postAuthor: string,
    data: any,
    callback: { (response: any) }
  ) {
    const formData = new FormData();

    console.log(data, "data");
    formData.append("key", "users/" + postAuthor + "/job/JD");
    for (let i = 0; i < data.length; i++) {
      formData.append("files", data.item(i));
    }

    store
      .dispatch(
        uploadFiles({
          data: formData,
        })
      )
      .then(unwrapResult)
      .then((response) => {
        if (!response.data.error) {
          console.log(response);
          toast.success("Uploaded SuccessFully");
          const atts = response.data.data.map((a) => {
            return {
              contentType: a.contentType,
              id: a.id,
              name: a.fileName,
              size: a.size,
              url: a.url,
            } as PostAttachment;
          });
          callback(atts);
        }
      })
      .catch((error) => {
        console.log(error, "This is error");
      });
  }

  // async uploadPostContent(
  //   uploadType: string,
  //   postAuthor: string,
  //   data: any,
  //   callback: {
  //     (response: any)
  //   }) {
  //   if (Platform.OS === "ios" || Platform.OS === "android") {
  //     const formData = buildForm.buildFormDataDeviceUpload(postAuthor, data.assets);
  //     store.dispatch(
  //       uploadFiles({
  //         data: formData,
  //       })
  //     ).then(unwrapResult)
  //       .then((response) => {
  //         if (!response.data.error) {
  //           console.log(response)
  //           if (uploadType === "post") {
  //             const atts = response.data.data.map((a) => {
  //               return {
  //                 contentType: a.contentType,
  //                 id: a.id,
  //                 name: a.fileName,
  //                 size: a.size,
  //                 url: a.url,
  //               } as PostAttachment;
  //             });
  //             callback(atts);
  //           }
  //           else if (uploadType === "article") {
  //             const atts = {
  //               url: response.data.data[0].url,
  //               name: response.data.data[0].fileName,
  //               contentType: response.data.data[0].contentType,
  //               size: response.data.data[0].size,
  //               id: response.data.data[0].id,
  //             }
  //             callback(atts);
  //           }
  //         }
  //       })
  //       .catch((error) => { console.log(error, "This is error") });
  //   }
  //   else {
  //     const formData = buildForm.buildMultipleAttachFormData(postAuthor, data.output);
  //     store.dispatch(
  //       uploadFiles({
  //         data: formData,
  //       })
  //     ).then(unwrapResult)
  //       .then((response) => {
  //         if (!response.data.error) {
  //           if (uploadType === "post") {
  //             const atts = response.data.data.map((a) => {
  //               return {
  //                 contentType: a.contentType,
  //                 id: a.id,
  //                 name: a.fileName,
  //                 size: a.size,
  //                 url: a.url,
  //               } as PostAttachment;
  //             });
  //             callback(atts);
  //           }
  //           else if (uploadType === "article") {
  //             const atts = {
  //               url: response.data.data[0].url,
  //               name: response.data.data[0].fileName,
  //               contentType: response.data.data[0].contentType,
  //               size: response.data.data[0].size,
  //               id: response.data.data[0].id,
  //             }
  //             callback(atts);
  //           }
  //         }
  //       })
  //       .catch((error) => { error });
  //   }
  // }

  // async uploadFromCamera(
  //   postAuthor: string,
  //   data: any,
  //   callback: {
  //     (response: any)
  //   }) {
  //   let localUri = data.assets[0].uri;
  //   const file = await fetch(localUri);
  //   const file_blob = await file.blob();
  //   let formData = new FormData();
  //   formData.append("files", file_blob);
  //   formData.append("key", "users/" + postAuthor + "/post");

  //   store.dispatch(
  //     uploadFiles({
  //       data: formData,
  //     })
  //   ).then(unwrapResult)
  //     .then((response) => {
  //       if (!response.data.error) {
  //         const atts = response.data.data.map((a) => {
  //           return {
  //             contentType: a.contentType,
  //             id: a.id,
  //             name: a.fileName,
  //             size: a.size,
  //             url: a.url,
  //           } as PostAttachment;
  //         });
  //         callback(atts);
  //       }
  //     })
  //     .catch((error) => { error });
  // }

  // async uploadProfileImage(postAuthor: string, data: any, callback: { (response: any) }) {
  //   if (Platform.OS === "ios" || Platform.OS === "android") {
  //     const formData = buildForm.buildFormDataProfileUpload(postAuthor, data.assets);
  //     store.dispatch(
  //       uploadFiles({
  //         data: formData,
  //       })
  //     ).then(unwrapResult)
  //       .then((response) => {
  //         if (!response.data.error) {
  //           callback(response.data.data[0].url);
  //         }
  //       })
  //       .catch((error) => { console.log(error, "This is error") });
  //   }
  //   else {
  //     const formData = buildForm.buildMultipleAttachFormData(postAuthor, data.output);
  //     store.dispatch(
  //       uploadFiles({
  //         data: formData,
  //       })
  //     ).then(unwrapResult)
  //       .then((response) => {
  //         if (!response.data.error) {
  //           callback(response.data.data[0].url);
  //         }
  //       })
  //   }
  // }
}

const uploadContent = new UploadContent();
export default uploadContent;
