import React from "react";

const Public = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM2 10C2 9.39 2.08 8.79 2.21 8.22L6.99 13V14C6.99 15.1 7.89 16 8.99 16V17.93C5.06 17.43 2 14.07 2 10ZM15.89 15.4C15.63 14.59 14.89 14 13.99 14H12.99V11C12.99 10.45 12.54 10 11.99 10H5.99V8H7.99C8.54 8 8.99 7.55 8.99 7V5H10.99C12.09 5 12.99 4.1 12.99 3V2.59C15.92 3.77 18 6.65 18 10C18 12.08 17.19 13.98 15.89 15.4Z"
        fill="white"
      />
    </svg>
  );
};

export default Public;
