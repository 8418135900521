import { FC, ReactNode, useEffect, useRef, useState } from "react";

import "../../../../App.css";

import CrossIcon from "../../../../assets/icons/CrossIcon";

import { useAppDispatch } from "../../../../config/hooks";
import {
  closeCreatePostModalType,
  togggleCreatePostModalType,
} from "../../../../slices/homeFeedSlice";

interface ProfilePopupProps {
  isOpen: boolean;
  children: ReactNode;
  popupHeight?: string;
  title: string;
}

const ProfilePopup: FC<ProfilePopupProps> = ({
  isOpen,
  children,
  popupHeight = "h-[551px]",
  title,
}) => {
  const dispatch = useAppDispatch();
  const menuRef = useRef<HTMLDivElement>(null);
  const [HoverState, setHoverState] = useState("#EFEFF3");

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (!menuRef.current.contains(e.target as Node)) {
        // onClose();
        closePopupHandler();
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const closePopupHandler = () => {
    dispatch(closeCreatePostModalType());
  };

  const hidePopuphandler = () => {
    if (!isOpen) dispatch(togggleCreatePostModalType());
  };
  return (
    <div
      className={`fixed items-center top-0
      } top-0 left-0 w-full h-full flex justify-center ${
        !isOpen && "popup-closeanimation"
      } z-10`}
      onAnimationEnd={hidePopuphandler}
    >
      <div
        className={`fixed top-0 left-0 w-full h-full bg-black opacity-50 ${
          !isOpen && "hidden"
        }`}
      ></div>
      <div
        ref={menuRef}
        className={`fixed bg-lxgray-700 shadow-md ${popupHeight} w-[50%] ${
          isOpen && "popup-animation"
        }`}
      >
        <div
          className="w-full flex justify-between px-6 py-4"
          style={{ boxShadow: "0px 11px 8px -3px #00000033" }}
        >
          <h1 className="text-white text-xl font-bold tracking-wide">
            {title}
          </h1>
          <button
            onClick={closePopupHandler}
            onMouseEnter={() => setHoverState("#AA933E")}
            onMouseLeave={() => setHoverState("#EFEFF3")}
            className="text-golden hover:rotate-90 transition-all duration-200"
          >
            <CrossIcon color={HoverState} />
          </button>
        </div>
        <div className="w-full mt-4">{children}</div>
      </div>
    </div>
  );
};

export default ProfilePopup;
