import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import UploadIcon from '../../../assets/icons/UploadIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../config/store';
import uploadContent from '../../../config/UploadContent';
import Switch from '../../job/Switch';
import { useAppDispatch, useAppSelector } from '../../../config/hooks';
import Dropdown from '../../job/components/Dropdown';
import { createClubApi, inviteClubMembersApi } from '../../../slices/clubSlice';
import { baseUrl, contentUrl } from '../../../utils/contant';
import backgroundBanner from "../../../assets/images/Blank_Placeholders.png"
import Advertisement from '../../advertisement/Advertisement';
import Accordian from '../BothCommon/Accordian';
import { useNavigate } from 'react-router-dom';
import { ClubCreateApiResponse } from '../../../models/club';

const CreateClub = () => {
    const { currencies,industries,categories} = useAppSelector((state) => state.appConfig);
    const dispatch=useAppDispatch()
    const navigate=useNavigate()
 
    const { userId ,token} = useSelector((state: RootState) => state.login.session);
    const [updatedBanner,setUpdatedBanner]=useState("")
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const {register,setValue,handleSubmit,formState:{errors,isValid},watch,control}=useForm({mode:"all"})
    const [isNext,setNext]=useState(true)
    const inputRef = useRef(null);
    const isPaid=useRef(null)
    const [showIndex,setShowIndex]=useState(0)
    const isPaidClub=watch("switch")

    useEffect(() => {
        const handleDragOver = (event) => {
          event.preventDefault();
        };
    
        const handleDrop = (event) => {
          event.preventDefault();
          handleChange(event);
        };
    
        document.addEventListener("dragover", handleDragOver);
        document.addEventListener("drop", handleDrop);
    
        return () => {
          document.removeEventListener("dragover", handleDragOver);
          document.removeEventListener("drop", handleDrop);
        };
      }, []);
    
      const onSubmit = async (data) => {
        
        console.log(data);
       const response= await dispatch(createClubApi({
          title: data.title,
          industry:data.industry,
          category:data.category,
          details:data.details,
          bannerImage:data.bannerImage,
          isPrivate:data.isPrivate==="true"?true:false,
          fee:data.fee,
          email:data.email
        })) as ClubCreateApiResponse

        console.log(response,"response")
        const newClubId=response?.payload?.data?.data?.id
        
        await dispatch(inviteClubMembersApi({clubId:newClubId,data:{userIds:data.Connections?data.Connections:[]}}))
        navigate("/tab/clubs/myclubs/owned")
      }

   

      const handleChange = (event) => {
        // console.log(event)
        setIsUploading(true);
    
        const selectedFile =
          event instanceof DragEvent
            ? event.dataTransfer.files
            : event.target.files;
        console.log(selectedFile);
    
        uploadContent.uploadClubContent(userId, selectedFile, (response) => {
          //console.log(response,jobId);
          const { contentType, name, size, url } = response[0];
          const attachment = {
            contentType: contentType,
            name: name,
            size: size,
            url: url,
          };
          console.log(attachment);
          setUpdatedBanner(attachment.url)
          setValue("bannerImage",(attachment.url));
          setIsUploading(false);
        });
      };
    
  return (
    <div className='flex'>

   
    <div className="mx-12 p-8 dark:bg-lxgray-700 mt-1 rounded-lg hit flex-1">
        
         {isNext && <h1 className="text-white text-lg font-semibold">Create Club</h1>}
         <div>

         <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-1">
             {isNext && <div>
              <div>
                <h1 className="text-lxgray-100">Upload File</h1>
                <label className="text-lxgray-300 ">
                Drag or choose your file to upload (less than or equal to 3 MegaBytes)
                </label>
                <input
                ref={inputRef}
                className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg mb-4 hidden"
                accept=".jpg,.jpeg,.png"
                // placeholder={<UploadIcon/>}
                type="file"
                onChange={handleChange}
                
                //
                />
               </div>
            <div className="relative">
              <div className="bg-lxgray-400 ">
                <span
                  onClick={() => inputRef?.current?.click()}
                  {...register("bannerImage",{required:true})}
                  className="rounded "
                  >
                  {" "}
                  {/* {isUploading ? "Uploading..." : "Upload"} */}
                  <div className="cursor-pointer relative">   
                    <img src={updatedBanner.length>0?`${baseUrl}/${contentUrl}/${updatedBanner}?t=${token}`:backgroundBanner} alt="bannerImage" className='w-full h-40 object-contain'/>
                    <div className="absolute top-[40%] left-[49%]"><UploadIcon/></div>
                  </div> 
                </span>     
              </div>
            </div>
            {errors.bannerImage && <span className="text-red-500">Upload Banner Image</span>}

            <div>

            <div className="   flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300">
                Enter Club Title *
              </label>
              <input
                type="text"
                placeholder="Enter Title"
                className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
                {...register("title",{required:true})}
              />
                {errors.title && <span className="text-red-500">Enter the Valid Title</span>}
            </div>

            <div className="text-lxgray-300 mb-4 flex flex-col">
              <label htmlFor="" className="text-sm p-1 ">
                Type *
              </label>
              <div className="flex items-center">
                <input
                  type="radio"
                  defaultChecked={true}
                  value={"false"}
                  className=" w-4 h-4 mr-2 "
                  {...register("isPrivate")}
                />
                <label htmlFor="public" className="text-white p-1 mr-6 ">
                  Public
                </label>

                <input
                  type="radio"
                  defaultChecked={false}
                  value={"true"}
                  className=" h-4 w-4 mr-2"
                  {...register("isPrivate")}
                />
                <label htmlFor="client" className="text-white p-1 ">
                  Private
                </label>
              </div>
            </div>
            
            <div className="flex p-1 text-sm text-lxgray-300 justify-between mb-3">
                <span className="text-white">Set Club to be Free or Paid *</span>
                <div className="flex gap-2 items-center">
                    <label htmlFor="" className="text-lxgray-100">Club is Paid</label>
                    <input type="checkbox" ref={isPaid} defaultChecked={false} onChange={(e)=>setValue("switch",e.target.checked)} className="hidden" />
                    <div onClick={()=>isPaid.current?.click()} {...register('switch',{value:false})}><Switch/></div>
                </div>
            </div>

           
            {isPaidClub && <div className="flex gap-4 mb-4">
          <div className="flex flex-col w-1/4">
            <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
              Currency *
            </label>
            <div className="text-white">
              <select
                className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2  text-white w-full"
                defaultValue={currencies[0].Title}
                {...register("currency")}
              >
                <option value="" disabled>
                  Select
                </option>
                {currencies.map((ele, i) => (
                  <option key={`${i}jobType`} value={ele.Title}>
                    {ele.Title}
                  </option>
                ))}
              </select>
              </div>
          </div>
          <div className="flex flex-col flex-1">
            <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
              Amount *
            </label>
            <input
            type="number"
            placeholder="Enter Your Amount"
            className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
            {...register("fee",{setValueAs:(value)=>Number(value),valueAsNumber:true})}
            onInput={(e) => {
              const target = e.target as HTMLInputElement; // Cast to HTMLInputElement
              target.value = target.value.replace(/[^0-9]/g, ''); // Filter input
            }}
          />
          </div>
         </div>}


          <div className="  flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
                Industry *
              </label>
              <div className="text-white">
                <Dropdown
                  name="industry"
                  control={control}
                  defaultValue={[]}
                  placeholder={"Choose The Relevant Industry"}
                  options={industries.map((ele) => {
                    return { value: ele.Id.toString(), label: ele.Name };
                  })}
                />
              </div>
            </div>

            <div className="  flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
                Category *
              </label>
              <div className="text-white">
                <Dropdown
                  name="category"
                  control={control}
                  defaultValue={[]}
                  placeholder={"Choose The Relevant Category"}
                  options={categories.map((ele) => {
                    return { value: ele.Id.toString(), label: ele.Categories };
                  })}
                />
              </div>
            </div>

            <div className="text-lxgray-300 flex flex-col mb-4 relative">
              <label htmlFor="" className="text-sm p-1">
                Enter Club Description *
              </label>
              <textarea
                className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg text-white py-2 pr-8 pl-2 no-scrollbar"
                rows={5}
                placeholder="Enter Here"
                maxLength={2600}
                {...register("details",{required:true,maxLength:2600})}
                // required
              ></textarea>
                {errors.details && errors.details.type === "required" && (
                <span className="text-red-500">Club details are required</span>
                )}
                {errors.details && errors.details.type === "maxLength" && (
                <span className="text-red-500">Maximum 2600 characters allowed</span>
                )}
            </div>

            <div className="   flex flex-col mb-4">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300">
                Enter Email*
              </label>
              <input
                type="text"
                placeholder="Enter Email"
                className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
                {...register("email",{required:"Email is required", pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                  message: "Enter a valid email address",
                }})}
              />
                {errors.email && <span className="text-red-500">{errors.email.message.toString()}</span>}
            </div>
            <div className='flex justify-end '>
              <button onClick={()=>setNext(!isNext)} disabled={!isValid} className={`text-sm px-10 py-2 rounded-lg ${isValid?"bg-golden text-white":"bg-lxgray-300 cursor-not-allowed text-gray-300"}`}>Next</button>
            </div>
            </div>
            </div> }
            {
              !isNext && (
                  <div>
                    {
                ["Connections"].map((ele,index)=>{
                  return(
                     <div>
                      <Accordian key={`${ele}accord`} heading={ele} showItems={index===showIndex?true:false} setShowIndex={()=>{setShowIndex(index)}}  control={control} register={register} watch={watch} setValue={setValue}/>             
                    </div>
                  )
                })
              }
              <div className='flex justify-end gap-4'>
                <button onClick={()=>setNext(!isNext)} className='text-lxgray-100 bg-lxgray-300 px-8 py-1 rounded'>Previous</button>

               <button className='text-white bg-golden px-8 py-1 rounded' type='submit'>Create</button>
              </div>
              </div>
              )
                  
            }
            </form>
         </div>
    </div>
    <div className='border-l border-lxgray-300 px-5'>
      <Advertisement/>
    </div>
    </div>
  )
}

export default CreateClub
