import { ReactNode } from "react";

interface LxOutlinedButtonProps {
  btnTitle: string;
  btnIcon?: ReactNode;
  customeStyles: string;
}

const LxOutlinedButton: React.FC<LxOutlinedButtonProps> = ({
  btnIcon,
  btnTitle,
  customeStyles,
}) => {
  return (
    //More than two words {btnTitle} are not allow
    <button
      className={
        "flex items-center justify-center gap-4 h-[40px] border rounded-xl text-base font-semibold " +
        customeStyles
      }>
      {btnIcon}
      {btnTitle}
    </button>
  );
};

export default LxOutlinedButton;
