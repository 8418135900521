import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../config/hooks'
import { getRewardCategoriesApi } from '../../../slices/rewardSlice'
import { MdArrowRightAlt } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';

const Categories = () => {
    const dispatch=useAppDispatch()
    const {categories}=useAppSelector((state)=>state.rewards)
    const navigate=useNavigate()
    useEffect(()=>{
        dispatch(getRewardCategoriesApi())
    },[])

    const handleClick=()=>{
        navigate('/tab/rewards/partners/all')
    }

  return (
    <div className='text-white'>
        <div className='flex justify-between items-end py-2'>
            <h1 className='font-semibold text-2xl'>Categories</h1>
            
            <h3 onClick={handleClick} className='text-lxgray-400 text-sm hover:text-golden cursor-pointer'>View all</h3>
        </div>
       
        <div className='grid grid-cols-2 gap-4 '>    
            {
                categories?.map((ele,i)=>{
                    if(ele.partners.length<=0){
                        return
                    }
                    return (
                        <Link to={`/tab/rewards/partners/${ele.category.id}`} key={ele.category.id}>
                            <div className='bg-lxgray-800 rounded px-4 py-2 hover:cursor-pointer hover:bg-lxgray-200/40 '>
                                <h1 className='font-semibold text-2xl'>{ele.category.name}</h1>
                                <h3 className='pb-4 text-sm text-lxgray-200'>{ele.category.description}</h3>  
                                <div className='flex justify-between border-t border-lxgray-200 py-4'>
                                    <div className='flex gap-2'>
                                            {
                                                ele.partners.map((ele)=>{
                                                    return (
                                                        <img key={ele.id} src={ele.logoUrl} alt="" className='w-12 h-12' />
                                                    )
                                                })
                                            }                
                                    </div>
                                    {ele.partners.length>0 && <MdArrowRightAlt size={44}  />}
                                </div>
                            </div>
                        </Link>
                    )
                })
            }
        </div>
    </div>
  )
}

export default Categories
