import React from "react";

const QueryPost = ({ userContent, openComments=null }) => {
  const {
    metadata: { content },
  } = userContent;

  const openCommentHandler = () => {
    openComments.current.click();
  };
  return (
    <div className="space-y-4">
      <h1 className="text-white text-lg text-start font-bold">{content}</h1>
      <button
        className="w-full py-2 text-center rounded-xl bg-golden text-white text-lg font-semibold"
        onClick={openCommentHandler}
      >
        Add Your Answer
      </button>
    </div>
  );
};

export default QueryPost;
