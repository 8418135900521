import React from "react";
import { useAppSelector } from "../../../config/hooks";
import CommonCard from "../BothCommon/CommonCard";
import { Link } from "react-router-dom";

const AllClubs = () => {
  const { clubs } = useAppSelector((state) => state.clubs.clubs);
  return (
    <div className="flex flex-wrap gap-2 mx-auto px-7 py-11">
      {clubs?.map((club, index) => (
        <Link key={club.id} to={`/tab/clubs/${club.id}`}>
          <CommonCard
            details={{
              title: club?.title,
              detail: club?.details,
              bannerImage: club?.bannerImage,
              members: club?.members,
              cardType: "club",
            }}
          />
        </Link>
      ))}
    </div>
  );
};

export default AllClubs;
