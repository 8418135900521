import addProfileIcon from "../../../assets/icons/addProfileIcon.png";
import PlusIcon from "../../../assets/icons/PlusIcon";
import addEducationIcon from "../../../assets/icons/EducationIcon.png";

import SuggestionCard from "./SuggestionCard";

import { useAppSelector } from "../../../config/hooks";

const ProfileSuggestions = () => {
  const userData = useAppSelector((state) => state.user);
  const { userDetails } = userData;
  const userProgressStatus = userDetails.profileCompletion.replace("%", "");

  return (
    <div className="w-[96%]  mx-auto py-8">
      <div className="flex gap-5 items-center justify-center">
        <h1 className="w-full text-2xl text-white font-bold">
          Suggestions For You
        </h1>
        <div className="w-full">
          <p className="text-sm text-lxgray-200 font-medium mb-1">
            {userProgressStatus ? userProgressStatus : "0"} % Intermediate{" "}
          </p>
          <progress
            className="w-full h-[10px]"
            value={userProgressStatus}
            max={100}></progress>
        </div>
      </div>

      <div className="flex gap-5 w-full mt-8">
        <SuggestionCard
          suggestionIcon={addProfileIcon}
          btnTitle={"Add Photo"}
          title={"Add a profile photo to help others recognize you"}
          btnIcon={<PlusIcon />}
          classNameIcon="w-[64.45px] h-[54.04px]"
        />
        <SuggestionCard
          suggestionIcon={addEducationIcon}
          btnIcon={<PlusIcon />}
          btnTitle={"Add Education"}
          title={"Which university or school did you attend?"}
          classNameIcon="w-[52.09px] h-[69.27px]"
        />
      </div>
    </div>
  );
};

export default ProfileSuggestions;
