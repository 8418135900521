import "./App.css";

/* Theme variables */

import Routes from "./config/route";
import { persistor, store } from "../src/config/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AppWrapper from "./config";
import ErrorPage from "./pages/error/ErrorPage";
import "./theme/variables.css";
import { BrowserRouter } from "react-router-dom";

const App: React.FC = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <AppWrapper>
        <ErrorPage>
          <Routes />
        </ErrorPage>
      </AppWrapper>
    </PersistGate>
  </Provider>
);

export default App;



