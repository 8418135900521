import { combineReducers, configureStore } from "@reduxjs/toolkit";
import appReducer from "../slices/appSlice";
import loginReducer from "../slices/loginSlice";
import homeReducer from "../slices//homeSlice";
import postReducer from "../slices/postSlice";
import rewardReducer from "../slices/rewardSlice";
import commentReducer from "../slices/commentSlice";
import networkReducer from "../slices/networkSlice";
import profileReducer from "../slices/profileSlice";
import userReducer from "../slices/userSlice";
import jobReducer from "../slices/jobSlice";
import eventReducer from "../slices/eventSlice";
import clubReducer from "../slices/clubSlice";
import surveyReducer from "../slices/surveySlice";
import companyReducer from "../slices/companySlice"
import chatReduer from "../slices/chatSlice"
import staticReducer from "../slices/staticSlice";
import globalReducer from "../slices/globalSearchSlice"
import notificationReducer from "../slices/notificationSlice"

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist-indexeddb-storage";
import homeFeedSlice from "../slices/homeFeedSlice";
const STORAGE_NAME = "linkcxo.app.db";
const persistConfig = {
  key: "root",
  storage: storage(STORAGE_NAME),
  blacklist: ["login", "profileData", "createHomeFeedsData", "staticPageData"],
};

const loginPersistConfig = {
  storage: storage(STORAGE_NAME),
  key: "login",
  blacklist: ["ui"],
};
const rootReducer = combineReducers({
  appConfig: appReducer,
  staticPageData: staticReducer,
  login: persistReducer(loginPersistConfig, loginReducer),
  home: homeReducer,
  post: postReducer,
  job: jobReducer,
  clubs: clubReducer,
  events: eventReducer,
  comment: commentReducer,
  rewards: rewardReducer,
  networks: networkReducer,
  user: userReducer,
  profileData: profileReducer,
  createHomeFeedsData: homeFeedSlice,
  company:companyReducer,
  chat:chatReduer,
  globalSearch:globalReducer,
  notification:notificationReducer
  // surveyFeeds: surveyReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
});
export const clearDatabase = () => {
  storage(STORAGE_NAME).removeItem("persist:app");
};
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
