import React, { useEffect, useState } from "react";
import CommonCard from "../BothCommon/CommonCard";
import background from "../../../assets/images/Blank_Placeholders.png";
import EditDots from "../../../assets/icons/EditDots";
import Share from "../../../assets/icons/Share";
import Calendar from "../../../assets/icons/Calendar";
import Video from "../../../assets/icons/Video";
import ChainIcon from "../../../assets/icons/ChainIcon";
import ProfileIcon from "../../../assets/images/Blank_Placeholders.png";
import DetailCE from "../BothCommon/DetailCE";
import Media from "../BothCommon/Media";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { useNavigate, useParams } from "react-router-dom";
import banner from "../../../assets/images/default_profile_banner.png";
import { MdCurrencyRupee } from "react-icons/md";

import {
  actionOnEventInviteApi,
  deleteEventApi,
  getEventDetailsApi,
  getEventMembersApi,
  removeMemberApi,
  requestToEventApi,
} from "../../../slices/eventSlice";
import { GetAllEventResponse } from "../../../models/event";
import { formatDate } from "../BothCommon/DateFormat";
import OfflineVideo from "../../../assets/icons/OfflineVideo";
import { baseUrl, contentUrl } from "../../../utils/contant";
import Participants from "../BothCommon/Participants";
import ParticipatingComponent from "../BothCommon/ParticipatingComponent";
import AttendeesImage from "../BothCommon/AttendeesImage";
import Advertisement from "../../advertisement/Advertisement";
import VerificationPopup from "../../../components/VerificationPopup";

const Event = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { token, userId } = useAppSelector((state) => state.login.session);
  const { eventDetail, pendingEvents, eventMembers } = useAppSelector(
    (state) => state.events
  );
  const { userDetails } = useAppSelector((state) => state.user);
  const eventId = useParams();
  const [tab, setTab] = useState("detail");
  const [attend, setAttend] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [requestedSeeAll,setRequestedSeeAll]=useState(false)
  const [invitedSeeAll,setInvitedSeeAll]=useState(false)
  const [isPopupVisible, setPopupVisible] = useState(false);

  console.log(eventDetail);
  useEffect(() => {
    // console.log(eventId.eventId)
    dispatch(getEventDetailsApi(eventId));
    dispatch(getEventMembersApi({ eventId: eventId.eventId }));
  }, [eventId]);

  const handleAttendClick = (eventId: string, userId: string) => {
    console.log("eventId", eventId);
    if(userDetails?.status!=="Verified"){
      setPopupVisible(true)
      return
    }
    dispatch(
      requestToEventApi({ eventId: eventId, data: { userIds: [userId] } })
      // inviteMembersApi({ eventId: eventId, data: { userIds: [userId] } })
    );
    setAttend(true);
  };

  const handleCancelRequestClick = () => {
    dispatch(
      removeMemberApi({
        eventId: eventId.eventId,
        memberId: eventDetail?.requestMember?.id,
      })
    );

    navigate("/tab/events/myevents/pending")
  };

  const handleEditButton = (eventId: string) => {
    navigate(`/tab/events/${eventId}/update`);
  };

  const handleAcceptInvite = (eventId) => {
    dispatch(actionOnEventInviteApi({ eventId, action: "accept" }));
    navigate("/tab/events/myevents/participating");
  };
  const handleRejectInvite = (eventId) => {
    dispatch(actionOnEventInviteApi({ eventId, action: "reject" }));
  };

  const handleDeleteEvent = (eventId) => {
    dispatch(deleteEventApi({ eventId }));
    navigate("/tab/events/myevents/hoisting");
  };

  const handleSeeAll=(str)=>{
      if(str=="requested"){
        setRequestedSeeAll(!requestedSeeAll)
      }
      if(str=="invited"){
        setInvitedSeeAll(!invitedSeeAll)
      }
  }

  console.log("EVENTDETAIL", eventDetail);
  // console.log(
  //   pendingEvents?.map((ele) => ele.id).includes(eventId.eventId),
  //   "DEAR SHARAD"
  // );
  return (
    <div className="flex gap-2 text-white flex-wrap">
      <div className="bg-lxgray-600 flex-1">
        <img
          src={
            eventDetail?.bannerImageUrl
              ? `${baseUrl}/${contentUrl}/${eventDetail?.bannerImageUrl}?t=${token}`
              : background
          }
          alt="Psoter"
          className="w-full h-72 object-cover rounded"
        />

        <div className="px-4 py-4">
          <div className="flex justify-between mb-2">
            <h2 className="text-2xl font-bold">{eventDetail?.title}</h2>
            <div className="flex items-center gap-8 relative">
              <Share />
              <div onClick={() => setShow(!show)} className="cursor-pointer">
                <EditDots />
              </div>
              {show && (
                <div className="absolute z-[20] bg-lxgray-300 top-0 right-5 w-36 rounded cursor-pointer text-center">
                  <p className="py-1 border-b border-lxgray-200 ">
                    Share in a post
                  </p>
                  <p className="py-1 border-b border-lxgray-200 ">
                    Send in a message
                  </p>
                  <p className="py-1 border-b border-lxgray-200 ">Copy link</p>
                  <p className="py-1 border-b border-lxgray-200 ">Bookmark</p>
                  <p className="py-1 border-b border-lxgray-200 ">Report</p>
                  {eventDetail?.authorId === userId && (
                    <p
                      onClick={() => handleDeleteEvent(eventId.eventId)}
                      className="py-1 cursor-pointer"
                    >
                      Withdraw Event
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>

          {
            <div className="relative py-2">
              {/* {details?.members?.map((member)=><AttendeesImage url={member?.user.picUrl}/>)} */}

              <div className="flex relative gap-4 items-center">
                {eventDetail?.members.map((member, index) => (
                  <AttendeesImage key={member.id} zindex={(index + 1) * 10}  picUrl={member?.user.picUrl} memberName={member?.user.firstName}/>
                ))}
                <h1 className="text-white bg-golden rounded-full p-1.5 w-10 h-10 z-50">
                  +{eventDetail?.members.length}
                </h1>
                <h1 className="text-center text-gray-400">Participants</h1>
              </div>
            </div>
          }

          <div className="flex justify-between mb-2">
            <div className="flex gap-4">
              <div className="mt-2">
                <Calendar />
              </div>
              <div className="text-[16px]">
                <p className="text-lxgray-100">
                  Start Date : {formatDate(eventDetail?.parsedStartTime,"UTC")}
                </p>
                <p className="text-lxgray-100">
                  End Date : {formatDate(eventDetail?.parsedEndTime,"UTC")}
                </p>
              </div>
            </div>

            <h1 className="text-2xl font-semibold">
              {eventDetail?.fee > 0 ?<span className="flex items-center gap-2"><MdCurrencyRupee/> {eventDetail.fee} /-</span> : "Free"}
            </h1>
          </div>

          <div className="flex gap-4 items-center mb-2 ">
            {eventDetail?.isOffline ? <OfflineVideo /> : <Video />}
            <p className="text-[16px] text-lxgray-100">
              {eventDetail?.isOffline ? "Offline" : "Online"}
            </p>
          </div>

          {!eventDetail?.isOffline && (
            <div className="flex gap-4 items-center text-[16px] mb-2">
              <ChainIcon />
              <p className="text-lxgray-100">Event Link</p>
              <p className="border-b border-golden text-golden cursor-pointer">
                <a href={`https:${eventDetail?.eventUrl}`} target="_blank">
                  {eventDetail?.eventUrl}
                </a>
              </p>
            </div>
          )}

          {eventDetail?.isOffline && (
            <div className="flex gap-4 items-center text-[16px] mb-2">
              <ChainIcon />
              {/* <p>Event Link</p> */}
              <p className="text-lxgray-100">{eventDetail?.venue}</p>
            </div>
          )}

          <div className="flex justify-between items-center">
            <div className="flex gap-4">
              <img
                src={
                  eventDetail?.author?.picUrl
                    ? `${baseUrl}/${contentUrl}/${eventDetail?.author?.picUrl}?t=${token}`
                    : ProfileIcon
                }
                alt="Profile"
                className="rounded-full w-10 h-10 object-cover"
              />
              <div>
                <p className="text-sm text-lxgray-400">Event By</p>
                <p>
                  {eventDetail?.author?.firstName}{" "}
                  {eventDetail?.author.lastName}
                </p>
              </div>
            </div>
            <div className="">
              {(!eventDetail?.requestMember && eventDetail?.authorType!=="COMPANY") && (
                <button
                  className={`${attend?"bg-lxgray-200 cursor-not-allowed ":""} text-white bg-golden rounded-lg px-12 py-3 font-semibold`}
                  onClick={() => handleAttendClick(eventId.eventId, userId)}
                >
                  {!attend ? "Attend" : "Cancel Request"}
                </button>
              )}

              {eventDetail?.requestMember?.status === "PENDING" &&
                eventDetail?.requestMember?.type === "REQUEST" &&
                pendingEvents
                  ?.map((ele) => ele.id)
                  .includes(eventId.eventId) && (
                  <button
                    className="text-white bg-golden rounded-lg px-12 py-3 font-semibold"
                    onClick={handleCancelRequestClick}
                  >
                    Cancel Request
                  </button>
                )}

              {eventDetail?.requestMember?.status === "PENDING" &&
                eventDetail?.requestMember.type === "INVITE" &&
                pendingEvents
                  ?.map((ele) => ele.id)
                  .includes(eventId.eventId) && (
                  <div className="flex gap-6">
                    <button
                      onClick={() => handleAcceptInvite(eventId.eventId)}
                      className="text-white bg-golden rounded-lg px-12 py-3 font-semibold"
                    >
                      ACCEPT
                    </button>

                    <button
                      onClick={() => handleRejectInvite(eventId.eventId)}
                      className="text-golden border border-golden  rounded-lg px-12 py-3 font-semibold"
                    >
                      IGNORE
                    </button>
                  </div>
                )}

              {eventDetail?.authorId === userId && (
                <button
                  onClick={() => handleEditButton(eventId.eventId)}
                  className="text-white bg-golden rounded-lg px-12 py-3 font-semibold"
                >
                  Edit
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="shadow-inner shadow-black">
          <div className="flex  gap-8 text-lg  border-b border-lxgray-400 py-2 px-4">
            <h1
              onClick={() => setTab("detail")}
              className={
                tab === "detail"
                  ? "text-golden border-b-2 border-golden px-2 cursor-pointer"
                  : "text-lxgray-200 px-2 cursor-pointer"
              }
            >
              Details
            </h1>
            <h1
              onClick={() => setTab("media")}
              className={
                tab === "media"
                  ? "text-golden border-b-2 border-golden px-2 cursor-pointer"
                  : "text-lxgray-200 px-2 cursor-pointer"
              }
            >
              Media
            </h1>
            {(eventDetail?.authorId === userId ||
              eventDetail?.authorType == "COMPANY") && (
              <h1
                onClick={() => setTab("participants")}
                className={
                  tab === "participants"
                    ? "text-golden border-b-2 border-golden px-2 cursor-pointer"
                    : "text-lxgray-200 px-2 cursor-pointer"
                }
              >
                Participants
              </h1>
            )}
          </div>
          {tab === "detail" && (
            <DetailCE
              text={eventDetail?.details}
              category={eventDetail?.category.join(",")}
              host={eventDetail?.host}
              industry={eventDetail?.industry.join(",")}
              email={eventDetail?.emailId}
            />
          )}
          {tab === "media" && <Media eventId={eventId.eventId} />}
          {tab === "participants" && <ParticipatingComponent type={"event"} />}
        </div>
      </div>

      <div className="">
        {/* <CommonCard details={{ title: "Advertise", detail: "NEW ADVERTISE" }} /> */}

        { <Advertisement />}
        {eventDetail?.authorId === userId && (
          <div  className={`flex flex-col gap-2 p-4 ${requestedSeeAll?"h-fit":"max-h-96"} overflow-hidden my-2 `}>
            {eventMembers.some(
              (member) =>
                member.status === "PENDING" && member.type === "REQUEST"
            ) && <div className="flex items-center justify-between mb-2"><h1 className="text-lg font-semibold">Requested</h1><h2 onClick={()=>handleSeeAll("requested")} className="text-sm font-semibold text-lxgray-200">{requestedSeeAll?"view less":"view more"}</h2></div>}
            {eventMembers.map((ele, i) => {
              if (ele.type === "REQUEST" && ele.status === "PENDING") {
                return (
                  <Participants
                    key={i + "ItsEVENT"}
                    type={ele.type}
                    eventId={eventDetail.id}
                    memberId={ele.id}
                    fullName={`${ele.user.firstName} ${ele.user.lastName}`}
                    picUrl={ele.user.picUrl}
                    designation={ele.user.designation}
                    organization={ele.user.organization}
                    status={ele.status}
                    authorId={ele.authorId}
                    memberUserId={ele.userId}
                  />
                );
              }
              return null;
            })}
          </div>
        )}

        {eventDetail?.authorId === userId && (
          <div className={` flex flex-col  gap-2 p-4 ${invitedSeeAll?"h-fit":"max-h-96"} overflow-hidden  my-2 `}>
            {eventMembers.some(
              (member) =>
                member.status === "PENDING" && member.type === "INVITE"
            ) && <div className="flex items-center justify-between mb-2"><h1 className="text-lg font-semibold">Invited</h1><h2 onClick={()=>handleSeeAll("invited")}className="text-sm font-semibold text-lxgray-200">{invitedSeeAll?"view less":"view more"}</h2></div>}
            {eventMembers.map((ele, i) => {
              if (ele.type === "INVITE" && ele.status === "PENDING") {
                return (
                  <Participants
                    key={i + "ItsCLUB"}
                    type={ele.type}
                    eventId={eventDetail.id}
                    memberId={ele.id}
                    fullName={`${ele.user.firstName} ${ele.user.lastName}`}
                    picUrl={ele.user.picUrl}
                    designation={ele.user.designation}
                    organization={ele.user.organization}
                    status={ele.status}
                    authorId={ele.authorId}
                    memberUserId={ele.userId}
                  />
                );
              }
              return null;
            })}
          </div>
        )}
      </div>
      <VerificationPopup
        visible={isPopupVisible} 
        onClose={setPopupVisible} 
      />
    </div>
  );
};

export default Event;
