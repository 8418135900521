import moment from "moment";



import profile from "../assets/images/Blank_Placeholders.png";

import { useAppSelector } from "../config/hooks";
import { baseUrl, contentUrl } from "../utils/contant";

const RepostCard = ({ userContent, children, feeds = false }) => {
  const session = useAppSelector((state) => state.login.session);
  const options = useAppSelector((state) => state.appConfig.designations);

  const { author, createdAt } = userContent;

  const { picUrl, firstName, lastName, organization, designation } = author;

  const postRelativeTime = moment(createdAt).fromNow();

  const designantionData =
    !isNaN(designation) && designation
      ? options?.find((opt) => opt.Id === designation)?.Title
      : designation
      ? designation
      : "";

  return (
    <div
      className={`${
        feeds ? "bg-lxgray-700" : "bg-lxgray-800"
      }  p-4 rounded-xl space-y-5`}
    >
      <div className="flex gap-4 items-start relative">
        <div className="w-[50px]">
          {picUrl ? (
            <div
              className={`w-[50px] h-[50px] bg-no-repeat bg-center bg-cover rounded-full`}
              style={{
                backgroundImage: `url(${baseUrl}/${contentUrl}/${picUrl}?t=${session.token})`,
              }}
            ></div>
          ) : (
            <div
              className={`w-[40px] h-[40px] bg-no-repeat bg-center bg-cover rounded-full`}
              style={{
                backgroundImage: `url(${profile})`,
              }}
            ></div>
          )}
        </div>
        <div className="text-start grow min-w-[70%]">
          <h1 className="text-lg text-white font-semibold leading-5">{`${firstName} ${lastName}`}</h1>
          <p className="text-base text-lxgray-400 font-normal flex flex-wrap items-center justify-between">
            <span className="text-start truncate">{`${designantionData} ${
              designantionData && organization && "|"
            } ${organization}`}</span>
            <span className="text-end">{postRelativeTime}</span>
          </p>
        </div>
      </div>
      <div className="space-y-1">{children}</div>
    </div>
  );
};

export default RepostCard;
