import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../config/hooks'
import { getProductListApi } from '../../../slices/rewardSlice'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

const Products = () => {
    const dispatch=useAppDispatch()
    const {products}=useAppSelector((state)=>state.rewards)
    const { partnerId } = useParams();
    const [filteredList, setFilteredList] = useState(products);
    
    

    useEffect(() => {
        if(partnerId=="online"){
            const newList = products.filter((item) => item.online==true);
            setFilteredList(newList);
        }
        else if(partnerId=="offline"){
            const newList = products.filter((item) => item.online==false);
            setFilteredList(newList);
        }
        else{
            const newList = products.filter((item) => item.partnerId==partnerId);
            setFilteredList(newList);
        }
        
    }, [products, partnerId]);

  return (
    <div className='text-white grid grid-cols-4 gap-4  px-[10%] py-10'>
      {
        filteredList?.map((ele,i)=>{
            return (
                <Link to={`/tab/rewards/products/productdetail/${ele.id}`} key={`products-${i}`} >
                    <div className=" bg-lxgray-500 flex flex-col   rounded  hover:border-2">
                            <img src={ele.variants[0].images[0].url} alt="Partner Image" className='w-full min-h-40 max-h-40 object-cover rounded-b-lg border-b border-lxgray-300'/>
                            <div className=' pl-2 py-3 flex flex-col gap-1 '>
                                <h1 className='text-golden text-lg font-semibold line-clamp-1'>{ele.variants[0].name}</h1>
                                <h3 className='text-black line-clamp-1'>{ele.variants[0].title}</h3>
                                <h3 className='text-lxgray-100 line-clamp-1'>{ele.variants[0].description}</h3>
                            </div>
                            
                        </div>
                </Link>
            )
        })
       }
    </div>
  )
}

export default Products
