import React, { useEffect } from 'react'
import AddMultiple from '../Clubs and Events/BothCommon/AddMultiple'
import { Link } from 'react-router-dom'
import CommonCard from '../Clubs and Events/BothCommon/CommonCard'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { addHoistingEvents, getEventsByAuthorApi } from '../../slices/eventSlice'
import NoEvents from "../../../src/assets/images/NoEventsImage.png"

const CompanyEvents = ({authorId}:{authorId:string}) => {
  const dispatch=useAppDispatch()
  const {hoistingEvents}= useAppSelector((state)=>state.events)
  useEffect(()=>{
    fetchData()
  },[])

  const fetchData=async()=>{
    try{
      const response=await dispatch(getEventsByAuthorApi({authorId:authorId}))
      dispatch(addHoistingEvents(response.payload))
    }
    catch(error){
      console.log(error,"ERROR")
    }
  }
  return (
    <div className='min-h-svh py-4 bg-lxgray-700 relative'>
     
      <div className='px-4'>
       <AddMultiple placeholder={"Create Event . . ."} type="createCompanyEvent"/>
      </div>
      {hoistingEvents?.length>0 &&
      (
      <div>
        {/* <h1 className='text-white text-lg py-2'>My Events</h1> */}
        <div className='flex flex-wrap py-8 px-4 gap-2 justify-start'>
        {
          hoistingEvents?.map((event)=> 
            <Link  key={event.id} to={ `/tab/events/${event.id}`}>
              <CommonCard details={{title:event?.title,detail:event?.details,offline:event?.isOffline,venue:event?.venue,author:`${event.author.firstName} ${event.author.lastName}`,startDate:event?.parsedStartTime,bannerImageUrl:event?.bannerImageUrl,members:event?.members,cardType:"event"}}/>
            </Link>
          )
        }
        </div>
      </div>)}

      {
        hoistingEvents.length===0 && 
        <div className='flex justify-center'>
          <img src={NoEvents} alt="NO EVENTS AVAILABLE" />
        </div>
      }
    </div>
  )
}

export default CompanyEvents
