import React, { useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import TextArea from "../basic_components/TextArea";
import DropDown from "../basic_components/DropDown";
import InputText from "../basic_components/InputText";
import moment from "moment";
import { createPostApi } from "../../../../slices/postSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { closeCreatePostModalType } from "../../../../slices/homeFeedSlice";

type FormValues = {
  question: string;
  categories: { Categories: string; Id: number };
  validity: { Title: string; Id: number; Value: number };
  options: { option: string }[];
};

const PollForm = ({authorType,authorId}:{authorType?:string,authorId?:string}) => {
  const buttonRef = useRef(null);
  const dispatch = useAppDispatch();
  const session = useAppSelector((state) => state.login.session);
  const categoryOptions = useAppSelector((state) => state.appConfig.categories);
  const postDurationOptions = useAppSelector(
    (state) => state.appConfig.postDuration
  );
  const form = useForm<FormValues>({
    defaultValues: {
      validity: postDurationOptions[0],
      options: [{ option: "" }, { option: "" }], // Initialize with two fields
    },
  });

  const { handleSubmit, control, formState } = form;
  const { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    name: "options",
    control,
  });

  const addOptionHandler = () => {
    append({ option: "" });
  };

  const onSubmit = (formData: FormValues) => {
    console.log("formData", formData);
    const postValidity = formData.validity.Value;
    const calculatedDate = moment().add(postValidity, "day").toISOString();
    console.log("calculatedDate", calculatedDate);
    dispatch(
      createPostApi({
        authorType: authorType?authorType:"USER",
        authorId: authorId?authorId:session.userId,
        postType: "POLL",
        metadata: {
          question: formData.question,
          validity: calculatedDate,
          options: formData.options,
        },
        id: "",
      })
    )
      .then(unwrapResult)
      .then((response) => {
        console.log("response", response);
      })
      .catch((error) => {
        console.log(error);
      });
    dispatch(closeCreatePostModalType());
  };

  const submitButtonHandler = () => {
    buttonRef.current.click();
  };

  return (
    <div className="">
      <form
        className="max-h-[70vh] flex flex-col gap-2 overflow-auto scrollbar-hidden px-6"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <Controller
            name="question"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Question is required",
              },
              maxLength: {
                value: 140,
                message: "Only 140 characters are allowed",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <TextArea
                rows={2}
                name="question"
                value={value || ""}
                title="Your Question *"
                maxLength={140}
                onChange={onChange}
                placeholder="Eg. What's your favorite productivity hack? "
                error={errors?.question?.message}
              />
            )}
          />
        </div>

        <div className="">
          <Controller
            name="categories"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Categorie is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <DropDown
                title="Categories *"
                onChange={onChange}
                placeholder="Please Select"
                value={value || null}
                optionValue="Id"
                optionLabel="Categories"
                options={categoryOptions}
                dropDownHeight="50px"
                dropDownBorderWidth="2px"
                dropDownBorderColor="#696969"
                error={errors?.categories?.message}
              />
            )}
          />
        </div>

        <div
          className={`${fields.length > 2 ? "space-y-7" : "space-y-4 "} mt-2`}
        >
          {fields.map((field, index) => {
            return (
              <div key={field.id} className="relative">
                <Controller
                  name={`options.${index}.option`}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Option is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputText
                      className="h-[50px] border-2 border-lxgray-400"
                      placeholder={`Option ${index + 1} *`}
                      title=""
                      name={`desgnation-${index}`}
                      value={value || ""}
                      onChange={onChange}
                      isComment={true}
                      error={errors?.options?.[index]?.option?.message}
                    />
                  )}
                />
                {index > 1 && (
                  <button
                    className="absolute text-sm leading-none text-golden w-fit border-b border-b-golden -top-5 right-4"
                    onClick={() => remove(index)}
                  >
                    remove
                  </button>
                )}
              </div>
            );
          })}
          {fields.length < 5 && (
            <div className="w-full">
              <div className="w-fit ml-auto mr-0">
                <button
                  type="button"
                  className="border-2 border-green-400 py-2 px-11 text-center rounded-full text-base text-green-400 font-semibold bg-green-400/10"
                  onClick={addOptionHandler}
                >
                  Add Option
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="">
          <Controller
            name="validity"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Validity is required",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <DropDown
                title="When should the poll end ?"
                onChange={onChange}
                placeholder="Please Select"
                value={value || null}
                optionValue="Id"
                optionLabel="Title"
                options={postDurationOptions}
                dropDownHeight="50px"
                dropDownBorderWidth="2px"
                dropDownBorderColor="#696969"
                error={errors?.validity?.message}
              />
            )}
          />
        </div>

        <div className="hidden">
          <button
            ref={buttonRef}
            type="submit"
            className="w-[135px] h-[40px] bg-golden rounded-xl text-white text-base font-semibold active:scale-95"
          >
            Save
          </button>
        </div>
      </form>
      <div
        className="flex justify-end mt-2 py-2 px-5"
        style={{ boxShadow: "0px -11px 8px -3px #00000033" }}
      >
        <button
          onClick={submitButtonHandler}
          type="submit"
          className="w-[135px] h-[40px] bg-golden rounded-xl text-white text-base font-semibold active:scale-95"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default PollForm;
