const ErrorValidationIcon = ({
  width = "14",
  height = "15",
  color = "#D85B53",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0.5C3.136 0.5 0 3.6325 0 7.5C0 11.3675 3.136 14.5 7 14.5C10.864 14.5 14 11.3675 14 7.5C14 3.6325 10.864 0.5 7 0.5ZM7.7 11H6.3V9.6H7.7V11ZM7.7 8.2H6.3V4H7.7V8.2Z"
        fill={color}
      />
    </svg>
  );
};

export default ErrorValidationIcon;
