import React from 'react'
import { formatDate } from '../Clubs and Events/BothCommon/DateFormat'

const Message = ({messageType,message,time}:{messageType:string,message:string,time:string}) => {
  return (
   
      <div className={`flex flex-col w-fit${messageType==="sender"?"justify-end items-end pl-28":" justify-start items-start pr-28"}`}>
                <div className={`text-sm p-2 border border-lxgray-300  ${messageType==="sender"?'bg-lxgray-800 rounded-b-lg rounded-l-lg items-end':'bg-lxgray-500 rounded-b-lg rounded-r-lg'}`}>
                        <h1 className='whitespace-pre-line'>{message}</h1>
                </div>
                <p className={`text-lxgray-400 text-xs mt-1 ${messageType==="sender"?"text-end":""}`}>{formatDate(time,"Asia/Kolkata").split(",")[3]}</p>
      </div>
    
  )
}

export default Message
