const PlayButtonIcon = ({ width = "90", height = "87" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 90 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8" filter="url(#filter0_b_3048_9374)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.9998 87C69.7522 87 89.818 67.5244 89.818 43.5C89.818 19.4756 69.7522 0 44.9998 0C20.2474 0 0.181641 19.4756 0.181641 43.5C0.181641 67.5244 20.2474 87 44.9998 87ZM37.0907 26.3636L60.818 43.5L37.0907 60.6364V26.3636Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_3048_9374"
          x="-27.0012"
          y="-27.1828"
          width="144.002"
          height="141.366"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="13.5914" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_3048_9374"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_3048_9374"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default PlayButtonIcon;
