import React from 'react'

const CompanyClubs = () => {
  return (
    <div>
      <h1>Comaany Clubs</h1>
    </div>
  )
}

export default CompanyClubs
