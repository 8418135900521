const useComment = () => {
  const addNewComments = (tree, commentId, newComment) => {
    if (tree.id === commentId) {
      tree.replies = newComment;
      return tree;
    }

    const updatedTree = tree?.replies?.map((ele) =>
      addNewComments(ele, commentId, newComment)
    );

    return { ...tree, replies: updatedTree };
  };

  const postNewComments = (tree, commentId, newComment) => {
    if (tree.id == commentId) {
      tree.replies.unshift(newComment);
      tree.counts.reply = tree.counts.reply + 1;
      return tree;
    }

    const updatedTree = tree?.replies?.map((ele) => {
      return postNewComments(ele, commentId, newComment);
    });

    return { ...tree, replies: updatedTree };
  };

  const addDepthRecursively = (comments, depth = 0) => {
    comments.forEach((comment) => {
      comment.depth = depth;
      if (comment.replies.length > 0) {
        addDepthRecursively(comment.replies, depth + 1);
      }
    });
  };

  return { addNewComments, postNewComments, addDepthRecursively };
};

export default useComment;
