import React from "react";

const EditIcon2 = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.98379 10.2577L7.19479 13.7999C7.15908 13.9606 7.16518 14.1275 7.21254 14.2852C7.2599 14.443 7.34699 14.5865 7.46579 14.7026C7.58623 14.8158 7.73273 14.8989 7.89289 14.9448C8.05304 14.9907 8.22212 14.998 8.38579 14.9663L11.9918 14.191C12.179 14.1507 12.3505 14.0585 12.4858 13.9254L21.5998 4.99234C21.8784 4.71929 22.0994 4.39512 22.2502 4.03833C22.401 3.68155 22.4787 3.29915 22.4787 2.91297C22.4787 2.52678 22.401 2.14438 22.2502 1.7876C22.0994 1.43082 21.8784 1.10664 21.5998 0.833594C21.0286 0.29857 20.2689 0 19.4788 0C18.6887 0 17.929 0.29857 17.3578 0.833594L8.25779 9.77544C8.12121 9.90713 8.02611 10.0745 7.98379 10.2577ZM18.7718 2.2205C18.962 2.04186 19.2153 1.94213 19.4788 1.94213C19.7423 1.94213 19.9955 2.04186 20.1858 2.2205C20.3708 2.40538 20.4744 2.65401 20.4744 2.91297C20.4744 3.17193 20.3708 3.42055 20.1858 3.60544L19.4788 4.2984L18.0648 2.91248L18.7718 2.2205ZM9.87179 10.9575L16.6458 4.30134L18.0458 5.6804L11.2688 12.3385L9.47379 12.7247L9.87179 10.9575Z"
        fill="black"
      />
      <path
        d="M4.01953 21.9788H19.0195C19.9492 21.9762 20.8399 21.6122 21.4961 20.9667C22.1523 20.3211 22.5203 19.4468 22.5195 18.5356V11.1482C22.5195 10.8883 22.4142 10.639 22.2266 10.4552C22.0391 10.2714 21.7847 10.1681 21.5195 10.1681C21.2543 10.1681 21 10.2714 20.8124 10.4552C20.6249 10.639 20.5195 10.8883 20.5195 11.1482V18.5356C20.5209 18.9271 20.3637 19.3031 20.0824 19.5811C19.8012 19.8591 19.419 20.0165 19.0195 20.0185H4.01953C3.62011 20.0165 3.23784 19.8591 2.95662 19.5811C2.6754 19.3031 2.5182 18.9271 2.51953 18.5356V3.85892C2.5182 3.46742 2.6754 3.0914 2.95662 2.81338C3.23784 2.53535 3.62011 2.37804 4.01953 2.37597H11.5195C11.7847 2.37597 12.0391 2.2727 12.2266 2.08889C12.4142 1.90508 12.5195 1.65577 12.5195 1.39582C12.5195 1.13587 12.4142 0.886569 12.2266 0.702757C12.0391 0.518945 11.7847 0.41568 11.5195 0.41568H4.01953C3.08984 0.418276 2.19917 0.782284 1.54299 1.42782C0.886813 2.07336 0.518734 2.94768 0.519533 3.85892V18.5356C0.518734 19.4468 0.886813 20.3211 1.54299 20.9667C2.19917 21.6122 3.08984 21.9762 4.01953 21.9788Z"
        fill="black"
      />
    </svg>
  );
};

export default EditIcon2;
