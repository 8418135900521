import React from 'react'
import JobDetailDescription from '../JobDetailDescription'

const About = ({jobDes}) => {
  return (
    <div>
      <JobDetailDescription jobDes={jobDes}/>
    </div>
  )
}

export default About
