import React from 'react'
import { useAppSelector } from '../../../../config/hooks';
import UserJobProfile from './UserJobProfile';
import NoJobs from './NoJobs';

const Rejected = () => {
  const { rejectedJobApplications } = useAppSelector((state) => state.job);

  return (
    <div>
      {rejectedJobApplications.length > 0 ?
        rejectedJobApplications.map((ele) => {
          return (
           
            <div key={ele.id}>
              {ele.userId !== undefined && <UserJobProfile id={ele.userId}  jobId={ele.jobId} applicationId={ele.id} appliedJobTime={ele.createdAt} attachment={ele.attachment}/>}
            </div>
          );
        })
        :
          <NoJobs text={"There are currently no applications on record."}/>}
    </div>
  )
}

export default Rejected
