import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import {
  CreateSurveyResponse,
  GetSurveyRequest,
  SubmitSurveyAnswer,
  Survey,
} from "../models/survey";

import surveyService from "../services/survey.service";

const postAdapter = createEntityAdapter<CreateSurveyResponse>({});

interface PostReduxState {
  surveys: EntityState<CreateSurveyResponse, string>;
}
const initialState: PostReduxState = {
  surveys: postAdapter.getInitialState(),
};

export const createSurveyApi = createAsyncThunk(
  "survey/createSurveyApi",
  async (data: Survey, { rejectWithValue }) => {
    try {
      const response = await surveyService.createNewSurvey(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSurveyDetailsApi = createAsyncThunk(
  "survey/getSurveyDetailsApi",
  async (data: GetSurveyRequest, { rejectWithValue }) => {
    try {
      const response = await surveyService.getSurveyDetail(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitSurveyAnswer = createAsyncThunk(
  "survey/submitSurveyAnswer",
  async (data: SubmitSurveyAnswer, { rejectWithValue }) => {
    try {
      const response = await surveyService.submitSurveyAnswer(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const surveySlice = createSlice({
  name: "surveys",
  initialState,
  reducers: {},
  // extraReducers: (builder) => {
  //   builder.addCase(
  //     getSurveyDetailsApi.fulfilled,
  //     (state: PostReduxState, action) => {
  //       const response = action.payload.data.data;
  //       postAdapter.addOne(state.surveys, response);
  //     }
  //   );
  // },
});

export default surveySlice.reducer;
