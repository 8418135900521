import ProfileCard from "../components/ProfileCard";
import ProfileSuggestions from "../components/ProfileSuggestions";
import ProfileInfo from "../components/ProfileInfo";
import UserInterest from "../components/UserInterest";
import { useParams } from "react-router-dom";

const UserProfile = () => {
  const { profileId } = useParams();
  return (
    <>
      <ProfileCard />
      {!profileId && (
        <div
          className="w-full bg-lxgray-700 min-h-[318px]"
          id="profile_autosuggestion--card"
        >
          <ProfileSuggestions />
        </div>
      )}
      <div className="w-full bg-lxgray-700" id="profileinfo_tabs--article_card">
        <ProfileInfo />
      </div>
      <div className="w-full mx-auto bg-black mt-8">
        <div className="w-[96%] min-h-[350px] mx-auto">
          <UserInterest />
        </div>
      </div>
    </>
  );
};

export default UserProfile;
