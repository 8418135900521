import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  events: [],
};

export const logEvent = createAsyncThunk<any, { action: string }, any>(
  "log/event",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
      const user = state.login.user;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const logEventWithData = createAsyncThunk<
  any,
  { action: string; data: any },
  any
>("log/eventWithData", async (data, { getState, rejectWithValue }) => {
  try {
    const state: any = getState();
    const user = state.login.user;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    // logEvent: (state, action) => {
    //   CleverTapLogger.push(action.payload.action);
    // },
    // logEventWithData: (state, action) => {
    //   CleverTapLogger.push(action.payload.action, action.payload.data);
    // },
  },
});

export const {} = eventSlice.actions;
export default eventSlice.reducer;
