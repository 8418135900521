import React, { useRef } from "react";
import Dropdown from "../../job/components/Dropdown";
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";

import { useAppSelector } from "../../../config/hooks";
import { useForm ,Controller,Control,UseFormRegister,UseFormWatch,UseFormSetValue} from "react-hook-form";
import Calendar from "../../../assets/icons/Calendar";
import Switch from "../../job/Switch";

interface ScheduleYourEventProps {
  control: Control;
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
  errors:Record<string, any>
}

const ScheduleYourEvent:  React.FC<ScheduleYourEventProps> = ({ control, register,watch ,setValue,errors}) => {
  const { functions ,currencies} = useAppSelector((state) => state.appConfig);
  const isPaid=useRef(null)


 
  const isOnline = watch("isOffline");
  const isPaidEvent=watch("switch") || false;
  console.log("ISPAID",isPaidEvent)
  console.log(isOnline);
  const hosts = ["Individual", "Partner", "Linkcxo"];
  const startDateWatch=watch("startDate") || new Date()
  
  return (
    <div>
      <div className="flex flex-col mb-4">
        <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
          Host *
        </label>
        <div className="text-white">
          <select
            className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2  text-white w-full"
            defaultValue=""
            {...register("host")}
          >
            <option value="" disabled>
              Select
            </option>
            {hosts.map((ele, i) => (
              <option key={`${i}jobType`} value={ele}>
                {ele}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="text-lxgray-300 mb-4 flex flex-col">
        <label htmlFor="" className="text-sm p-1 ">
          Mode *
        </label>
        <div className="flex items-center">
          <input
            type="radio"
            defaultChecked={false}
            value={"false"}
            className=" w-4 h-4 mr-2 "
            {...register("isOffline")}
          />
          <label htmlFor="online" className="text-white p-1 mr-6 ">
            Online
          </label>

          <input
            type="radio"
            defaultChecked={false}
            value={"true"}
            className=" h-4 w-4 mr-2"
            {...register("isOffline")}
          />
          <label htmlFor="offline" className="text-white p-1 ">
            Offline
          </label>
        </div>
      </div>

      {isOnline === "false" && (
        <div className="   flex flex-col mb-4">
          <label htmlFor="" className="text-sm p-1 text-lxgray-300">
            Event Link *
          </label>
          <input
            type="text"
            placeholder="Event Url"
            className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
            {...register("eventUrl")}
            // required
          />
        </div>
      )}

        {isOnline === "true" && (
          <div className="   flex flex-col mb-4">
            <label htmlFor="" className="text-sm p-1 text-lxgray-300">
              Address *
            </label>
            <input
              type="text"
              placeholder="Enter Address"
              className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
              {...register("venue")}
              // required
            />
          </div>
          )}

          <div className="flex mb-4 gap-2">
            <div className="flex flex-col w-2/3">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300">
                Start Date *
              </label>
              
              <div className="">
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ReactDatePicker
                    placeholderText="Select Date"
                    className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg w-full p-2 text-white"
                    onChange={onChange} // send value to hook form
                    onBlur={onBlur} // notify when input is touched/blur
                    selected={value}
                    minDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    ref={ref}
                    />
                  )}
                  />
                </div>
            </div>

            <div className="flex flex-col w-1/3">
            <label htmlFor="" className="text-sm p-1 text-lxgray-300 md:line-clamp-1">
              Time (IST Standard) *
            </label>
            <input
              type="time"
              placeholder="Select Time"
              className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg  p-2 text-white"
              {...register("startTime")}
              // required
            />
            </div>
        </div>


        <div className="flex mb-4 gap-2">
            <div className="flex flex-col w-2/3">
              <label htmlFor="" className="text-sm p-1 text-lxgray-300">
                End Date *
              </label>
              
              <div className="">
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ReactDatePicker
                    placeholderText="Select Date"
                    className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg w-full p-2 text-white"
                    onChange={onChange} // send value to hook form
                    onBlur={onBlur} // notify when input is touched/blur
                    selected={value}
                    minDate={startDateWatch?new Date(startDateWatch):new Date()}
                    dateFormat="dd/MM/yyyy"
                    />
                  )}
                  />
                </div>
            </div>

            <div className="flex flex-col w-1/3">
            <label htmlFor="" className="text-sm p-1 text-lxgray-300 md:line-clamp-1">
              Time (IST Standard) *
            </label>
            <input
              type="time"
              placeholder="Select Time"
              className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
              {...register("endTime")}
              // required
            />
            </div>
        </div>

        <div className="flex p-1 text-sm text-lxgray-300 justify-between mb-3">
          <span className="text-white">Set Event to be Free or Paid *</span>
          <div className="flex gap-2 items-center">
            <label htmlFor="" className="text-lxgray-100">Event is Paid</label>
            <input type="checkbox" ref={isPaid} defaultChecked={false} onChange={(e)=>setValue("switch",e.target.checked)} className="hidden" />
            <div onClick={()=>isPaid.current?.click()} {...register('switch',{value:false})}><Switch/></div>
          </div>
        </div>
        

        {isPaidEvent && <div className="flex gap-4 mb-4">
          <div className="flex flex-col w-1/4">
            <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
              Currency *
            </label>
            <div className="text-white">
              <select
                className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2  text-white w-full"
                defaultValue={currencies[0].Title}
                {...register("currency")}
              >
                <option value="" disabled>
                  Select
                </option>
                {currencies.map((ele, i) => (
                  <option key={`${i}jobType`} value={ele.Title}>
                    {ele.Title}
                  </option>
                ))}
              </select>
              </div>
          </div>
          <div className="flex flex-col flex-1">
            <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
              Amount *
            </label>
            <input
            type="number"
            placeholder="Enter Your Amount"
            className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
            {...register("fee",{setValueAs:(value)=>Number(value),valueAsNumber:true})}
            // required
            onInput={(e) => {
              const target = e.target as HTMLInputElement; // Cast to HTMLInputElement
              target.value = target.value.replace(/[^0-9]/g, ''); // Filter input
            }}
          />
          </div>
      </div>}


      <div className="   flex flex-col mb-4">
          <label htmlFor="" className="text-sm p-1 text-lxgray-300">
            Email *
          </label>
          <input
            type="text"
            placeholder="support@gmail.com"
            className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
            {...register("emailId",{required:true})}
          // required
          />
           {errors.emailId && <span className="text-red-500">Enter the Valid Email</span>}
      </div>

    </div>
  );
};

export default ScheduleYourEvent;
