const StandardPhoneIcon = ({
  width = "30",
  height = "30",
  color = "#424242",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" fill={color} />
      <path
        d="M19.2175 16.9889C18.1161 16.8887 17.5154 18.391 16.7143 18.7916C15.4127 19.4926 13.0096 16.9889 13.0096 16.9889C13.0096 16.9889 10.5063 14.5852 11.1071 13.2832C11.6078 12.482 13.1097 11.8811 13.0096 10.7794C12.9094 9.77793 10.7066 6.17247 9.60519 7.17399C7.2021 9.37732 7.00185 10.2787 7.00185 12.0814C6.90172 15.1861 10.9069 19.092 10.9069 19.092C11.3074 19.4926 14.8119 23.0981 17.9159 22.998C19.7182 22.998 20.6193 22.7976 22.8222 20.394C23.8235 19.2923 20.319 17.089 19.2175 16.9889Z"
        fill="#AA933E"
      />
    </svg>
  );
};

export default StandardPhoneIcon;
