import { ChangeEvent, useRef, useState } from "react";
import AttachmentIcon from "../../../../assets/icons/AttachmentIcon";
import ImageIcon from "../../../../assets/icons/ImageIcon";
import VideoIcon from "../../../../assets/icons/VideoIcon";
import VoiceIcon from "../../../../assets/icons/VoiceIcon";
import { uuid } from "../../../../utils/uuid";
import RemoveImageIcon from "../../../../assets/icons/RemoveImageIcon";
import AudioPlayer from "../audio_player/AudioPlayer";
import PlayButtonIcon from "../../../../assets/icons/PlayButtonIcon";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";

import { createPostApi } from "../../../../slices/postSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { closeCreatePostModalType } from "../../../../slices/homeFeedSlice";
import uploadContent from "../../../../config/UploadContent";

const Post = ({authorType,authorId}:{authorType?:string,authorId?:string}) => {
  const session = useAppSelector((state) => state.login.session);
  const dispatch = useAppDispatch();
  const { userId } = session;
  const [text, setText] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [onChangeuploadContent, setOnChangeUploadContent] = useState({
    name: "",
    content: null,
  });
  const [formData, setformData] = useState(null);
  const [uploadContentResponse, setUploadContentResponse] = useState(null);
  const [isloadingContent, setIsloadingContent] = useState(false);
  const fileInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const audioInputRef = useRef(null);
  const videoRef = useRef(null);

  const handleClick = (fileType: string) => {
    switch (fileType) {
      case "attachment":
        fileInputRef.current.click();
        break;
      case "image":
        imageInputRef.current.click();
        break;
      case "video":
        videoInputRef.current.click();
        break;
      case "audio":
        audioInputRef.current.click();
        break;

      default:
        break;
    }
  };

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const files = [...event.target.files];
    const file = event.target.files;
    console.log("files.length :>> ", files.length);
    switch (name) {
      case "attachment":
        file &&
          file.length > 0 &&
          setOnChangeUploadContent({
            name: name,
            content: URL.createObjectURL(file[0]),
          });
        setformData(file);
        break;

      case "image":
        if (files && files.length > 0 && files.length <= 20) {
          files.splice(6);
          setOnChangeUploadContent({
            name: name,
            content: files.map((file) => URL.createObjectURL(file)),
          });
          setformData(files);

          uploadContent.uploadContent(
            userId,
            event.target.files,
            (response: any) => {
              setUploadContentResponse(response);
            }
          );
        }
        break;

      case "video":
        file &&
          file.length > 0 &&
          setOnChangeUploadContent({
            name: name,
            content: URL.createObjectURL(file[0]),
          });
        setformData(file);
        break;
      case "audio":
        file &&
          file.length > 0 &&
          setOnChangeUploadContent({
            name: name,
            content: URL.createObjectURL(file[0]),
          });
        setformData(file);
        break;

      default:
        break;
    }

    if (
      event.target.files &&
      event.target.files.length > 0 &&
      name !== "image"
    ) {
      uploadContent.uploadContent(
        userId,
        event.target.files,
        (response: any) => {
          setUploadContentResponse(response);
        }
      );
    }
  };

  const onChangeTextHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setText(value);
  };

  const removeImageHandler = (image: number) => {
    const removedConentArray = onChangeuploadContent.content.filter(
      (content: number) => content !== image
    );
    setOnChangeUploadContent({
      ...onChangeuploadContent,
      content: removedConentArray,
    });

    if (removedConentArray.length === 0) setformData(null);
  };

  const removePdfHandler = () => {
    setOnChangeUploadContent({ name: "", content: null });
    setformData(null);
  };

  const postHandler = () => {
    dispatch(
      createPostApi({
        authorType: authorType?authorType:"USER",
        authorId: authorId?authorId:session.userId,
        postType: "POST",
        metadata: {
          content: text,
          attachments: uploadContentResponse,
        },
        id: "",
      })
    )
      .then(unwrapResult)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
    dispatch(closeCreatePostModalType());
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);
  };

  const playVideo = () => {
    videoRef.current.play();
    setIsPlaying(true);
  };

  return (
    <>
      <div className="max-h-[70vh] my-4 px-6 overflow-auto scrollbar-hidden">
        <textarea
          className={`h-full w-full text-lxgray-100 placeholder:text-lxgray-400 bg-lxgray-800 outline-none px-4 py-4 text-base ring-0 focus:ring-0
        focus:outline-none rounded-md overflow-auto scrollbar-hidden`}
          style={{ resize: "none" }}
          rows={10}
          name="description"
          maxLength={2600}
          placeholder="Exploring innovative strategies to drive exponential growth in our organization. Stay tuned for insights and ideas!"
          onChange={onChangeTextHandler}
        />
        <div className="flex items-center justify-between mt-4">
          <div className="flex gap-4">
            <div
              className="cursor-pointer"
              onClick={() => handleClick("attachment")}
            >
              <div>
                <AttachmentIcon />
              </div>
              <input
                name="attachment"
                ref={fileInputRef}
                type="file"
                accept=".pdf"
                onChange={onChangeHandler}
                style={{ display: "none" }}
              />
            </div>
            <div
              className="cursor-pointer"
              onClick={() => handleClick("image")}
            >
              <div>
                <ImageIcon />
              </div>
              <input
                ref={imageInputRef}
                type="file"
                name="image"
                multiple
                accept="image/*"
                onChange={onChangeHandler}
                style={{ display: "none" }}
              />
            </div>
            <div
              className="cursor-pointer"
              onClick={() => handleClick("video")}
            >
              <div>
                <VideoIcon />
              </div>
              <input
                ref={videoInputRef}
                type="file"
                name="video"
                accept="video/*"
                onChange={onChangeHandler}
                style={{ display: "none" }}
              />
            </div>
            <div
              className="cursor-pointer"
              onClick={() => handleClick("audio")}
            >
              <div>
                <VoiceIcon />
              </div>
              <input
                ref={audioInputRef}
                type="file"
                name="audio"
                accept="audio/*"
                onChange={onChangeHandler}
                style={{ display: "none" }}
              />
            </div>
          </div>
          <div>
            <p className="text-sm text-white tracking-wide font-normal">{`${text.length} / 2600`}</p>
          </div>
        </div>
        {onChangeuploadContent.name === "image" && (
          <div className="flex gap-2 mt-4 w-full">
            {onChangeuploadContent.content.length > 0 &&
              onChangeuploadContent.content.map((image) => (
                <div className="relative flex-1 max-w-44" key={uuid()}>
                  <img
                    src={image}
                    alt="image"
                    className="h-auto w-full shadow-xl shadow-black"
                  />
                  <div
                    className="absolute w-fit rounded-full top-2 right-2 cursor-pointer"
                    onClick={() => removeImageHandler(image)}
                  >
                    <RemoveImageIcon />
                  </div>
                </div>
              ))}
          </div>
        )}

        {onChangeuploadContent.name === "audio" && (
          <div className="mt-4 shadow-xl shadow-black rounded-xl">
            <AudioPlayer
              audioSrc={onChangeuploadContent.content}
              fileName={formData[0].name}
              removePdfHandler={removePdfHandler}
            />
          </div>
        )}

        {onChangeuploadContent.name === "attachment" && (
          <div className="w-fit relative mt-4">
            <div className="w-fit h-[150px] rounded-t-xl overflow-hidden">
              {/* <Document file={onChangeuploadContent.content}>
                <Page pageNumber={1} width={300} />
              </Document> */}
            </div>
            <div className="absolute bg-lxgray-600 rounded-t-xl bottom-0 w-full flex items-center py-2 px-4 gap-4 overflow-hidden shadow-xl shadow-black z-10">
              <AttachmentIcon color="#AA933E" />
              <div className="w-full">
                <h1 className="text-base text-white font-bold">PDF</h1>
                <p className="text-sm text-white font-light tracking-wider">
                  {formData[0].name}
                </p>
              </div>
              <div
                className="w-fit rounded-full cursor-pointer"
                onClick={removePdfHandler}
              >
                <RemoveImageIcon width="23" height="23" />
              </div>
            </div>
          </div>
        )}

        {onChangeuploadContent.name === "video" && (
          <div className="mt-4 shadow-xl shadow-black">
            <div className="w-full h-full relative">
              <video
                ref={videoRef}
                controls={isPlaying}
                onEnded={handleVideoEnd}
                onPause={handleVideoEnd}
                className={`w-full h-40 mx-auto rounded-t-2xl object-cover`}
                src={onChangeuploadContent.content}
              ></video>
              {!isPlaying && (
                <div
                  onClick={playVideo}
                  className="absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 opacity-80 cursor-pointer rounded-full"
                >
                  <PlayButtonIcon />
                </div>
              )}
            </div>
            <div className="h-12 dark:bg-lxgray-600 rounded-b-2xl flex items-center px-5">
              <h1 className="text-xl text-white font-light tracking-wide">{`${formData[0].name}`}</h1>
            </div>
          </div>
        )}
      </div>
      <div
        className="mt-4 w-full ml-auto mr-0 px-6 py-4"
        style={{ boxShadow: "0px -11px 8px -3px #00000033" }}
      >
        <div className="w-fit ml-auto mr-0">
          <button
            type="submit"
            className="w-full py-2 px-10 rounded-lg bg-golden text-center text-base text-white font-bold"
            disabled={
              !text && !formData
                ? true
                : text && !formData
                ? false
                : !text && formData
                ? false
                : null
            }
            onClick={postHandler}
          >
            Post
          </button>
        </div>
      </div>
    </>
  );
};

export default Post;
