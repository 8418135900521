import React from "react";
import Image from "../../../assets/images/Blank_Placeholders.png";
import DateCard from "./DateCard";
import { baseUrl,contentUrl } from "../../../utils/contant";
import { useAppSelector } from "../../../config/hooks";
import { Member } from "../../../models/club";
import AttendeesImage from "./AttendeesImage";
import defaultBanner from "../../../assets/images/default_profile_banner.png"
interface Details {
  title?: string;
  offline?: boolean;
  author?: string;
  venue?: string;
  detail?: string;
  startDate?: Date;
  bannerImage?: string;
  bannerImageUrl?: string;
  members?: Member[];
  cardType: string | "event" | "club";
}

const CommonCard: React.FC<{ details: Details }> = ({ details }) => {
  const { token } = useAppSelector((state) => state.login.session);
  //console.log("Details",details)
  const {cardType}=details
  return (
    <div className="text-white w-[351px] h-[328px] rounded-lg bg-lxgray-600 m-1 flex-shrink-0 cursor-pointer">
      
      {cardType === "event" && (
        <div>
          <div className="relative">
              <img
                src={`${details?.bannerImageUrl? `${baseUrl}/${contentUrl}/${details?.bannerImageUrl}?t=${token}`:defaultBanner}`}
                alt="Banner Image"
                className="object-cover h-28 w-[351px] rounded-lg"
              />
            
              <div className="absolute left-3 -bottom-3">
                <DateCard newDate={details?.startDate} />
              </div>
          </div>

          <div className="flex flex-col py-4 pl-4 pr-9">
            <h1 className="text-lg line-clamp-1">{details?.title}</h1>

            {details?.author && (
              <div className=" my-2">
                <div className="flex gap-2 text-sm">
                  <span className="text-golden">
                    {details?.offline ? "Offline" : "Online"}
                  </span>
                  <span>{" | "}</span>
                  <span className="line-clamp-1">{`by ${details?.author}`}</span>
                </div>
                <div className="text-golden truncate">{details?.venue}</div>
              </div>
            )}
            {
              <div className={` ${details?.offline ?"":" mt-4"} relative py-2`}>
                {/* {details?.members?.map((member)=><AttendeesImage url={member?.user.picUrl}/>)} */}

                <div className="flex relative gap-4 items-center">
                  {details?.members?.map((member, index) => (
                    <AttendeesImage key={member.id} zindex={(index + 1) * 10}  picUrl={member.user.picUrl} memberName={member?.user.firstName}/>
                  ))}
                  <h1 className="text-white bg-golden text-center rounded-full p-1.5 w-10 h-10 z-40">
                    {details?.members?.length}
                  </h1>
                  <h1 className="text-center text-gray-400">Participants</h1>
                </div>
              </div>
            }
            <p className="text-lxgray-200 line-clamp-2">{details?.detail}</p>
          </div>
        </div>
      )}

      {cardType === "club" && (
        <div>
          <div>
           
              <img 
                src={`${details.bannerImage? `${baseUrl}/${contentUrl}/${details?.bannerImage}?t=${token}`:defaultBanner}`}
                alt="Banner Image"
                className="object-cover h-28 w-[351px] rounded-lg"
              />
           
          </div>
          <div className="flex flex-col py-4 pl-4 pr-9">
            <h1 className="text-lg line-clamp-1">{details?.title}</h1>
            {
              <div className="relative py-2">
                {/* {details?.members?.map((member)=><AttendeesImage url={member?.user.picUrl}/>)} */}

                <div className="flex relative gap-5 items-center">
                  {details?.members?.map((member, index) => (
                    <AttendeesImage key={member.id} zindex={(index + 1) * 10} picUrl={member.user.picUrl} memberName={member?.user.firstName}/>
                  ))}
                  <h1 className="text-white bg-golden rounded-full p-1.5 w-10 h-10 z-50">
                   +{details?.members?.length}
                  </h1>
                  <h1 className="text-center text-gray-400">Members</h1>
                </div>
              </div>
            }
            <p className="text-lxgray-200 line-clamp-2">{details?.detail}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommonCard;
