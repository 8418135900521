import LogoLight from "../../../../core/src/assets/images/LinkWhite_logo.png";

import { RootState } from "../../config/store";


import { GetOTPRequest, VerifyOTPRequest } from "../../models/auth";
import {
  getOtpApi,
  verifyOtpApi,
} from "../../slices/loginSlice";
import BgScreen2 from "../../assets/images/Web_Login.jpg";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import OtpVerification from "./OtpVerification";
import { getUserDetailApi } from "../../slices/userSlice";

const OtpVerificationPage = ({emailOrPhone}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const ui = useAppSelector((state: RootState) => state.login.ui);
  const {session} = useAppSelector((state: RootState) => state.login);
  const { accountExists } = ui;
  const from = location.state?.from?.pathname || "/";
 
  useEffect(() => {
    if (ui.apiState.error && ui.apiState.message) {
      //TODO toast
      // toast.show(ui.apiState.message, {
      //   dangerColor: colors.rose[700],
      //   textStyle: {
      //     color: colors.rose[700],
      //   },
      // });
      // dispatch(clearLoginError());
    }
    if (ui.requestId != null) {
      // setStage(1);
    }
  }, [ui]);

  const getOTP = async (req: GetOTPRequest) => {
    dispatch(getOtpApi(req));
  };

  const verifyOTP = (req: VerifyOTPRequest) => {
    dispatch(verifyOtpApi(req));
  };
  const resendOTP = () => {
    getOTP({
      countryCode: ui.countryCode,
      emailId: ui.email,
      phoneNo: ui.phoneNo,
    });
  };

  useEffect(() => {
    if (accountExists) {
      // navigate("/tab");
      const fetchData = async () => {
        try {
           await dispatch( getUserDetailApi({ userId: session.id, })
          );

          // setUser(payload.data.data.userDetails);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();
      navigate(from, { replace: true })
      // navigate("/tab")
    } else if (accountExists !== null) {
      navigate("/signup");
    }
  }, [accountExists]);
  return (
    <>
      <div >
        {/* <header className="w-full flex justify-center p-3">
          <section className="w-[95%] mx-auto flex justify-start items-center">
            <img src={LogoLight} alt="LinkCxO" className="h-12" />
          </section>
        </header> */}
        <div className="sm:px-[20%] ">
               <OtpVerification
                  emailOrPhone={emailOrPhone}
                  onResend={resendOTP}
                  onContinue={verifyOTP}
                  ui={ui}
                  onBack={() => {}}
                />
        </div>

        {/* <main className="xl:relative xl:top-[50%] xl:left-[50%] xl:translate-y-[-60%] xl:translate-x-[-50%]">
          <section className="w-[50%] mx-auto flex md:flex-col xl:flex-row gap-5">
            <section className="flex items-center justify-center flex-1 text-lxgray-500">
              <article className="max-[1280px]:max-w-[550px] w-[77%] p-11 rounded-2xl bg-lxgray-700 flex flex-col items-center justify-center gap-6">
                
              </article>
            </section>
          </section>
        </main> */}
      </div>
    </>
  );
};

export default OtpVerificationPage;
