
import useReadMoreToggle from "../utils/hooks/useReadMoreToggle";
import { useAppDispatch, useAppSelector } from "../config/hooks";
import profile from "../assets/images/Blank_Placeholders.png";
import { openCreatePostModalType } from "../slices/homeFeedSlice";
import { baseUrl, contentUrl } from "../utils/contant";

const ImagePost = ({ userContent, attachments }) => {
  const dispatch = useAppDispatch();
  const { contentRef, showMore, isOverflowing, textToggleHandler } =
    useReadMoreToggle(userContent?.content);
  const session = useAppSelector((state) => state.login.session);

  const openImagehandler = (index: number) => {
    dispatch(
      openCreatePostModalType(`openImage?=${userContent.id}#index=${index}`)
    );
  };

  return (
    <>
      {userContent?.content && (
        <div
          className={`text-sm text-lxgray-100 font-normal space-y-1 ${
            !isOverflowing && "mb-2"
          }`}
        >
          <p
            className={
              !showMore
                ? "line-clamp-3 whitespace-pre-wrap"
                : "line-clamp-none whitespace-pre-wrap"
            }
            ref={contentRef}
          >
            {userContent?.content}
          </p>
          {isOverflowing && (
            <div className="flex">
              <button
                className="text-xs text-lxgray-400 ml-auto mr-0"
                onClick={textToggleHandler}
              >
                {!showMore ? "... See More" : "... See Less"}
              </button>
            </div>
          )}
        </div>
      )}
      {attachments &&
        attachments.length === 1 &&
        attachments[0].contentType &&
        attachments[0].contentType.includes("image") && (
          <div
            className="w-full bg-lxgray-700 cursor-pointer"
            onClick={() => openImagehandler(0)}
          >
            <img
              className="min-h-[400px] max-h-[550px] object-cover mx-auto"
              src={
                attachments[0].url
                  ? `${baseUrl}/${contentUrl}/${attachments[0].url}?t=${session.token}`
                  : profile
              }
              alt=""
            />
          </div>
        )}

      {attachments &&
        attachments.length === 2 &&
        attachments[0].contentType &&
        attachments[0].contentType.includes("image") && (
          <div className="w-full bg-lxgray-700 flex gap-2">
            <div
              className="h-[550px] flex-1 cursor-pointer"
              onClick={() => openImagehandler(0)}
            >
              <img
                className="h-full w-full object-cover mx-auto"
                src={
                  attachments[0].url
                    ? `${baseUrl}/${contentUrl}/${attachments[0].url}?t=${session.token}`
                    : profile
                }
                alt=""
              />
            </div>
            <div
              className="h-[550px] flex-1 cursor-pointer"
              onClick={() => openImagehandler(1)}
            >
              <img
                className="h-full w-full object-cover ml-0 mr-auto"
                src={
                  attachments[1].url
                    ? `${baseUrl}/${contentUrl}/${attachments[1].url}?t=${session.token}`
                    : profile
                }
                alt=""
              />
            </div>
          </div>
        )}

      {attachments &&
        (attachments.length === 3 || attachments.length >= 4) &&
        attachments[0].contentType &&
        attachments[0].contentType.includes("image") && (
          <div className="w-full bg-lxgray-700 space-y-2">
            <div
              className="h-[300px] cursor-pointer"
              onClick={() => openImagehandler(0)}
            >
              <img
                className="h-full w-full object-cover mx-auto"
                src={
                  attachments[0].url
                    ? `${baseUrl}/${contentUrl}/${attachments[0].url}?t=${session.token}`
                    : profile
                }
                alt=""
              />
            </div>
            <div className="flex gap-3">
              <div
                className="h-[250px] flex-1 cursor-pointer"
                onClick={() => openImagehandler(1)}
              >
                <img
                  className="h-full w-full object-cover ml-0 mr-auto"
                  src={
                    attachments[1].url
                      ? `${baseUrl}/${contentUrl}/${attachments[1].url}?t=${session.token}`
                      : profile
                  }
                  alt=""
                />
              </div>
              <div
                className="h-[250px] flex-1 cursor-pointer"
                onClick={() => openImagehandler(2)}
              >
                <img
                  className="h-full w-full object-cover ml-0 mr-auto"
                  src={
                    attachments[2].url
                      ? `${baseUrl}/${contentUrl}/${attachments[2].url}?t=${session.token}`
                      : profile
                  }
                  alt=""
                />
              </div>
              {attachments.length >= 4 && (
                <div
                  className="relative h-[250px] flex-1 cursor-pointer"
                  onClick={() => openImagehandler(3)}
                >
                  <img
                    className="h-full w-full object-cover ml-0 mr-auto"
                    src={
                      attachments[3].url
                        ? `${baseUrl}/${contentUrl}/${attachments[3].url}?t=${session.token}`
                        : profile
                    }
                    alt=""
                  />
                  {attachments.length > 4 && (
                    <div className="absolute text-center top-0 w-full h-full bg-black/50 flex items-center justify-center text-white text-3xl font-bold">
                      <div className="w-fit">
                        <h1 className="cursor-pointer">{`+ ${
                          attachments.length - 4
                        }`}</h1>
                        <h1 className="cursor-pointer">Images</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
    </>
  );
};

export default ImagePost;
