import ExperienceCard from "../components/ExperienceCard";

import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getOtherUserDetailApi } from "../../../slices/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";

interface WorkExperience {
  id: string;
  userId: string;
  organization: string;
  organizationId: string;
  userExperienceDetails: UserExperienceDetails[];
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}

interface UserExperienceDetails {
  location: string;
  locationId: string;
  locationType: string;
  employmentType: string;
  title: string;
  description: string;
  startDate: StartEndDate;
  endDate: StartEndDate;
  isCurrentRole: boolean;
  skills: string[] | null;
  media: string[] | null;
}
interface StartEndDate {
  month: string;
  year: string;
}

const UserExperience = () => {
  const dispatch = useAppDispatch();
  const { profileId } = useParams();

  const [otherUserDetails, setOtherUserDetails] = useState(null);

  const userData = useAppSelector((state) => state.user);

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(
        getOtherUserDetailApi({
          userId: profileId,
        })
      ).then(unwrapResult);
      setOtherUserDetails(response.data.data.userExperience);
    };
    if (profileId) fetchData();
    else setOtherUserDetails(null);
  }, [profileId]);

  console.log("profileId :>> ", profileId);

  const { userExperience } = userData;

  if (profileId) {
    return (
      otherUserDetails && (
        <div className="w-full bg-lxgray-700 mt-5 self-start pb-4">
          <div className="w-[95%] mx-auto">
            <div className="w-full my-7">
              <h1 className="text-white text-xl font-bold">Experience</h1>
            </div>

            <div className="flex flex-col gap-4">
              {otherUserDetails.map(
                (useExperienceData: WorkExperience, index: number) => (
                  <ExperienceCard
                    key={index}
                    editExpDetails={false}
                    useExperienceData={useExperienceData}
                  />
                )
              )}
            </div>
          </div>
        </div>
      )
    );
  }

  return (
    <div className="w-full bg-lxgray-700 mt-5 self-start pb-4">
      <div className="w-[95%] mx-auto">
        <div className="w-full my-7">
          <h1 className="text-white text-xl font-bold">Experience</h1>
        </div>

        <div className="flex flex-col gap-4">
          {userExperience.map(
            (useExperienceData: WorkExperience, index: number) => (
              <ExperienceCard
                key={index}
                editExpDetails={false}
                useExperienceData={useExperienceData}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default UserExperience;
