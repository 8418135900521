import { Controller, useForm } from "react-hook-form";
import SurveyPost from "../../../../components/SurveyPost";
import RepostCard from "../../../../components/RepostCard";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { useMemo, useRef } from "react";
import ImagePost from "../../../../components/ImagePost";
import PollCard from "../../../../components/PollCard";
import QueryPost from "../../../../components/QueryPost";
import ArticlePost from "../../../../components/ArticlePost";
import TextArea from "../basic_components/TextArea";
import { createPostApi } from "../../../../slices/postSlice";
import { closeCreatePostModalType } from "../../../../slices/homeFeedSlice";
import Repost from "../../../../components/Repost";
type FormValues = {
  description: string;
};

const RepostForm = ({authorType,authorId}:{authorType?:string,authorId?:string}) => {
  const form = useForm<FormValues>({
    defaultValues: {
      description: "",
    },
  });
  const dispatch = useAppDispatch();
  const openComments = useRef(null);
  const buttonRef = useRef(null);
  const modalState = useAppSelector((state) => state.createHomeFeedsData);
  const user = useAppSelector((state) => state.user);
  const session = useAppSelector((state) => state.login.session);
  const { userPosts } = user;

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = form;
  const { createPostModalType } = modalState;

  const submitButtonHandler = () => {
    buttonRef.current.click();
  };

  const repostId =
    createPostModalType &&
    createPostModalType.includes("repost?=") &&
    createPostModalType.match(/repost\?=(\d+)/)[1];
  console.log("repostId :>> ", repostId);

  const onSubmit = (formData: FormValues) => {
    dispatch(
      createPostApi({
        authorType: authorType?authorType:"USER",
        authorId: authorId?authorId:session.userId,
        postType: "REPOST",
        metadata: {
          content: formData.description,
          postId: repostId,
        },
        id: "",
      })
    );
    dispatch(closeCreatePostModalType());
  };

  const repostComponent = useMemo(() => {
    if (!repostId || !userPosts?.posts) {
      return null;
    }

    const filteredPosts = userPosts.posts.filter(
      (post) => post.id === repostId
    );
    console.log("filteredPosts :>> ", filteredPosts);

    if (filteredPosts.length === 0) {
      return null;
    }

    const userContent = filteredPosts[0];
    const { postType, id, metadata } = userContent;
    const { attachments } = metadata;

    switch (postType) {
      case "POST":
        return (
          <RepostCard userContent={userContent} key={id}>
            <ImagePost userContent={userContent} attachments={attachments} />
          </RepostCard>
        );
      case "POLL":
        return (
          <RepostCard userContent={userContent} key={id}>
            <PollCard userContent={userContent} />
          </RepostCard>
        );
      case "QUERY":
        return (
          <RepostCard userContent={userContent} key={id}>
            <QueryPost userContent={userContent} openComments={openComments} />
          </RepostCard>
        );
      case "SURVEY":
        return (
          <RepostCard userContent={userContent} key={id}>
            <SurveyPost userContent={userContent} />
          </RepostCard>
        );
      case "ARTICLE":
        return (
          <RepostCard userContent={userContent} key={id}>
            <ArticlePost userContent={userContent} />
          </RepostCard>
        );
      case "REPOST":
        console.log("itsrepost :>> ");
        return (
          <Repost
            userContent={userContent}
            openComments={openComments}
            feeds={false}
          />
        );
      default:
        return null;
    }
  }, [repostId, userPosts, openComments]);

  return (
    <>
      <div className="max-h-[70vh] overflow-y-auto scrollbar-hidden px-4 pb-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-2">
            <Controller
              name="description"
              control={control}
              rules={{
                maxLength: {
                  value: 2600,
                  message: "Only 2600 characters are allowed",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextArea
                  rows={4}
                  name="description"
                  value={value || ""}
                  title="Enter Your Thoughts"
                  maxLength={2600}
                  onChange={onChange}
                  placeholder="Eg. What's your favorite productivity hack ?"
                  error={errors?.description?.message}
                />
              )}
            />
          </div>
          <div className="pointer-events-none">{repostComponent}</div>
          <button type="submit" className="hidden" ref={buttonRef}>
            Post
          </button>
        </form>
      </div>
      <div
        className="mt-2 px-4"
        style={{ boxShadow: "0px -11px 8px -3px #00000033" }}
      >
        <div className="w-fit ml-auto mr-0 py-2">
          <button
            className="text-lxwhite-100 text-lg text-center py-2 px-14 bg-golden rounded-lg font-semibold"
            onClick={submitButtonHandler}
          >
            Post
          </button>
        </div>
      </div>
    </>
  );
};

export default RepostForm;
