import React, { useEffect } from 'react'
import CommonCard from '../BothCommon/CommonCard'
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../config/hooks';
import LinksCard from '../BothCommon/LinksCard';
import FilteredCard from '../BothCommon/FilteredCard';
// import { getAllEventApi } from '../../../slices/eventSlice';
import { getAllClubsApi } from '../../../slices/clubSlice';

const DiscoverClubs = () => {
  const dispatch = useAppDispatch();
  const { clubs } = useAppSelector((state) => state?.clubs.clubs);
  useEffect(() => {
    dispatch(getAllClubsApi());
  }, []);

  console.log(clubs,"DISCOVER")
  
  return (
    <div className="flex flex-row justify-between flex-wrap py-2">
      <div className="w-2/3 ">
        <div className="flex justify-between text-white">
          <h1 className="text-xl">Discover Latest</h1>
          <Link to="/tab/clubs/all">
            {" "}
            <span className="text-sm cursor-pointer">View all</span>
          </Link>
        </div>
        <div className="py-2 no-scrollbar flex justify-start gap-2 overflow-x-scroll">
          {clubs?.slice(0, 3).map((club) => (
            <Link  key={club.id} to={ `/tab/clubs/${club.id}`}>
            <CommonCard
              details={{ title: club?.title, detail: club?.details ,bannerImage:club?.bannerImage,members:club.members,cardType:"club"}}
            />
            </Link>
          ))}
        </div>
        <div className="flex overflow-x-scroll no-scrollbar">
          <LinksCard title="LinkCxo's Clubs" />
          <LinksCard title="New Clubs" />
          <LinksCard title="Game Clubs" />
          <LinksCard title="LinkCxo Clubs" />
          <LinksCard title="LinkCxo Clubs" />
          <LinksCard title="LinkCxo Clubs" />

        </div>
        <div className="px-1 mt-1">
          <h1 className="text-white font-semibold text-lg py-4">
            You May Also Like This
          </h1>
          <div className="">
            <FilteredCard />
            <FilteredCard />
            <FilteredCard />
            <FilteredCard />
          </div>
        </div>
      </div>
      <div className="shadow-sm shadow-gray-300 h-fit">
        <CommonCard details={{ title: "Advertisement", detail: "New Advertisement HELLO" ,cardType:"club"}} />
      </div>
    </div>
  );
}

export default DiscoverClubs
