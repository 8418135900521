import React, { useState } from 'react'
import Accordian from './Accordian'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from '../../../config/hooks'
import { getEventMembersApi, inviteMembersApi } from '../../../slices/eventSlice'
import { getClubMemberApi, inviteClubMembersApi } from '../../../slices/clubSlice'

const AddParticipantsandMembers = ({type,eventId,clubId,setIsOpen}:{type:string,eventId?:string,clubId?:string,setIsOpen:Function}) => {
    
  const dispatch=useAppDispatch()
  const [showIndex,setShowIndex]=useState(0)
  const {handleSubmit,control,register,watch,setValue}=useForm()

  const onSubmitEvent = async (data) => {
    console.log(data,eventId)
    dispatch(inviteMembersApi({eventId:eventId,data:{userIds:data.Connections?data.Connections:[],clubIds:data.Clubs?data.Clubs:[]}}))
    dispatch(getEventMembersApi({eventId}))
    setIsOpen(false)
  }

  const onSubmitClub = async (data) => {
    console.log(data)
    await dispatch(inviteClubMembersApi({clubId:clubId,data:{userIds:data.Connections?data.Connections:[]}}))
    await dispatch(getClubMemberApi({ clubId }));
    setIsOpen(false)
  }
  return (
    <div className='p-10'>
        {type==="event" && <form onSubmit={handleSubmit(onSubmitEvent)}>
            <div>
                    {
                        ["Connections","Clubs"].map((ele,index)=>{
                        return(
                            <div>
                            <Accordian key={`${index}accor`} heading={ele} showItems={index===showIndex?true:false} setShowIndex={()=>{setShowIndex(index)}}  control={control} register={register} watch={watch} setValue={setValue}/>             
                            </div>
                        )
                        })
                    }
            </div>
            <button className='bg-golden text-white px-4 py-2 rounded'>Invite</button>
         </form>}

        {type==="club" &&  <form onSubmit={handleSubmit(onSubmitClub)}>
            <div>
                    {
                        ["Connections"].map((ele,index)=>{
                        return(
                            <div>
                            <Accordian key={`${index}accor`} heading={ele} showItems={index===showIndex?true:false} setShowIndex={()=>{setShowIndex(index)}}  control={control} register={register} watch={watch} setValue={setValue}/>             
                            </div>
                        )
                        })
                    }
            </div>
            <button className='bg-golden text-white px-4 py-2 rounded'>Invite Members</button>
         </form>}
    </div>
  )
}

export default AddParticipantsandMembers


