import apiClient from "../config/axiosConfig";
import { AxiosPromise } from "axios";
// import Constants from "../../appconfig.json";

class NocoService {
  getAllDesignations(): AxiosPromise<DesignationResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/designations?limit=500&fields=Id,Title`
    );
  }

  getAllIndustries(): AxiosPromise<IndustryResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/industries?limit=500&fields=Id,Name`
    );
  }

  getAllCategories(): AxiosPromise<CategoryResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/categories?limit=500&fields=Id,Categories`
    );
  }

  getAllFunctions(): AxiosPromise<FunctionsResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/functions?limit=500&fields=Id,Name`
    );
  }

  getAllCountries(): AxiosPromise<CountrisResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/Countries?limit=500&fields=Id,CountryName,PhoneCode,Iso3`
    );
  }
  getAllCurrencyType(): AxiosPromise<CurrencyResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/Currency-Type?limit=500&fields=Id,Title`
    );
  }
  getSalaryRange(): AxiosPromise<SalaryMinRangeResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/Salary-Range-Min?limit=500&fields=Id,Title`
    );
  }
  getMaxSalaryRange(): AxiosPromise<SalaryMaxRangeResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/Salary-Range-Max?limit=500&fields=Id,Title`
    );
  }
  getAllJobType(): AxiosPromise<JobTypeResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/Jobtypes?limit=500&fields=Id,Title`
    );
  }
  getAllWorkplaceType(): AxiosPromise<WorkplaceTypeResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/Workplace-Type?limit=500&fields=Id,Title`
    );
  }
  getExperienceMinRange(): AxiosPromise<ExperienceMinResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/Experience-Range-Min?limit=500&fields=Id,Title`
    );
  }
  getExperienceMaxRange(): AxiosPromise<ExperienceMaxResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/Experience-Range-Max?limit=500&fields=Id,Title`
    );
  }
  getAllCities(): AxiosPromise<CityResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/Cities?limit=1500&fields=Id,Name,State`
    );
  }
  getLanguageList(): AxiosPromise<LanguageResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/Language_list?limit=500&fields=Id,Title`
    );
  }
  getPostDuration(): AxiosPromise<PostDurationResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/Poll-Survey-Duration?limit=500&fields=Id,Title,Value`
    );
  }
  getPrivacyTerms(): AxiosPromise<PrivacyPolicyTerms> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/Privacy_Policy?limit=100&fields=Id,Name,Title,Description`
    );
  }
  getFaqs(): AxiosPromise<FaqResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/FAQ?limit=100&fields=Id,Question,Answer`
    );
  }

  getReportReason(): AxiosPromise<ReportReasonResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/report_Summary?limit=100&fields=Id,Title,key`
    );
  }

  getPreferedLocations(): AxiosPromise<PreferredLocationResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/Preffered_Location?limit=100&fields=Id,Title`
    );
  }

  getNoticePeriod(): AxiosPromise<NoticePeriodResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/Notice-PeriodTypes?limit=100&fields=Id,Title`
    );
  }

  getCompanyTypes(): AxiosPromise<CompanyTypeResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/Company_Types?limit=100&fields=Id,Title`
    );
  }

  getInterests(): AxiosPromise<InterestResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/Interest_List?limit=100&fields=Id,Name`
    );
  }

  getPronoun(): AxiosPromise<PronounResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/Pronoun_Types?limit=100&fields=Id,Name`
    );
  }
  getSkills(): AxiosPromise<SkillsResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/Skills?limit=100&fields=Id,Name`
    );
  }

  getQualification(): AxiosPromise<QualificationResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/Qualifications?limit=100&fields=Id,Title`
    );
  }

  getEmployeeSize(): AxiosPromise<EmployeeSizeResponse> {
    return apiClient.get(
      `/v1/configuration/api/v1/db/data/v1/linkcxo/Employee_Size?limit=100&fields=Title`
    );
  }
}

export interface DesignationResponse {
  list: Designation[];
}
export interface Designation {
  Title: string;
  Id: string;
}
export interface IndustryResponse {
  list: Industry[];
}

export interface Industry {
  Name: string;
  Id: number;
}

export interface CategoryResponse {
  list: Category[];
}

export interface Category {
  Categories: string;
  Id: number;
}

export interface FunctionsResponse {
  list: Functions[];
}

export interface Functions {
  Name: string;
  Id: number;
}

export interface CountrisResponse {
  list: Country[];
}
export interface Country {
  Id: number;
  CountryName: string;
  PhoneCode: string;
  Iso3: string;
}

export interface CurrencyResponse {
  list: Currency[];
}

export interface Currency {
  Id: number;
  Title: string;
}
export interface JobTypeResponse {
  list: JobType[];
}
export interface JobType {
  Id: number;
  Title: string;
}
export interface WorkplaceTypeResponse {
  list: WorkplaceType[];
}
export interface WorkplaceType {
  Id: number;
  Title: string;
}
export interface SalaryMinRangeResponse {
  list: MinSalary[];
}
export interface MinSalary {
  Id: number;
  Title: string;
}

export interface SalaryMaxRangeResponse {
  list: MinSalary[];
}
export interface ExperienceMinResponse {
  list: Experience[];
}

export interface Experience {
  Id: number;
  Title: string;
}

export interface ExperienceMaxResponse {
  list: Experience[];
}

export interface PostDurationResponse {
  list: PostDuration[];
}

export interface CityResponse {
  list: City[];
}
export interface City {
  Id: number;
  Name: string;
  State: string;
}

export interface LanguageResponse {
  list: Language[];
}
export interface Language {
  Id: number;
  Title: string;
}
export interface PostDuration {
  Id: number;
  Title: string;
  Value: number;
}

export interface PrivacyPolicyTerms {
  list: PrivacyTerms[];
}
export interface PrivacyTerms {
  Id: string;
  Name: string;
  Title: string;
  Description: string;
}

export interface ReportReasonResponse {
  list: ReportReason[];
}
export interface ReportReason {
  Id: string;
  Title: string;
  key: string;
}

export interface FaqResponse {
  list: Faq[];
}
export interface Faq {
  Id: string;
  Question: string;
  Answer: string;
}

export interface PronounResponse {
  list: Pronouns[];
}
export interface Pronouns {
  Title: string;
  Id: string;
}

export interface InterestResponse {
  list: Interest[];
}
export interface Interest {
  Name: string;
  Id: string;
}

export interface CompanyTypeResponse {
  list: CompanyType[];
}
export interface CompanyType {
  Title: string;
  Id: string;
}

export interface NoticePeriodResponse {
  list: NoticePeriod[];
}
export interface NoticePeriod {
  Title: string;
  Id: string;
}

export interface PreferredLocationResponse {
  list: Location[];
}
export interface Location {
  Title: string;
  Id: string;
}

export interface SkillsResponse {
  list: Skill[];
}
export interface Skill {
  Name: string;
  Id: string;
}
export interface QualificationResponse {
  list: Qualification[];
}
export interface Qualification {
  Id: string;
  Title: string;
}
export interface EmployeeSizeResponse{
  list:EmployeeSize[]
}
export interface EmployeeSize {
  Title: string;
}

const nocoService = new NocoService();
export default nocoService;
