import ElipsProfile from "../../../../assets/images/Blank_Placeholders.png";

const userDetails = [
  {
    userImageUrl: ElipsProfile,
    userName: "Davline Cousins",
    userDesignation: "Customer Service relationships.",
    userDetails: "September 12, 2020, Devlin and ⚡️Zander studied togethe",
    describtion:
      "Norem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus.",
  },
  {
    userImageUrl: ElipsProfile,
    userName: "Davline Cousins",
    userDesignation: "Customer Service relationships.",
    userDetails: "September 12, 2020, Devlin and ⚡️Zander studied togethe",
    describtion:
      " Norem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. ",
  },
];

const TopVoice = () => {
  return (
    <>
      <div>
        <div className="w-full flex flex-col xl:flex-row gap-5 mt-7">
          {userDetails.map((userData, index) => (
            <div
              key={index}
              className="flex flex-1 items-start justify-center gap-2 min-h-[172px]"
            >
              <div className="w-[20%]">
                <div className="h-[70px] w-[70px] mx-auto">
                  <img
                    className="h-full w-full rounded-full"
                    src={userData.userImageUrl}
                    alt=""
                  />
                </div>
              </div>
              <div className="w-[70%] flex flex-col gap-4">
                <h1 className="text-xl text-white font-bold">
                  {userData.userName}
                </h1>
                <div>
                  <p className="text-lxgray-200 text-base font-normal">
                    {userData.userDesignation}
                  </p>
                  <p className="text-lxgray-200 text-base font-normal">
                    {userData.userDetails}
                  </p>
                </div>
                <p className="text-white text-base font-normal">
                  {userData.describtion}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-5 text-end">
          <h1 className="w-fit text-lxgray-400 text-lg font-normal ml-auto mr-0 cursor-pointer">
            Show All
          </h1>
        </div>
      </div>
    </>
  );
};

export default TopVoice;
