import React from 'react'
import defaultBanner from "../../assets/images/default_profile_banner.png"
import { baseUrl ,contentUrl} from '../../utils/contant'
import { useAppSelector } from '../../config/hooks'

interface Props{
  bannerImage:string,
  profileImage:string,
  location:string,
  title:string,
  tagline:string
}

const CompanyCard = ({bannerImage,profileImage,location,title,tagline}:Props) => {
 
  const { token } = useAppSelector((state) => state.login.session);

  return (
    <div className="text-white w-[351px] h-[328px] rounded-lg bg-lxgray-600 m-1 flex-shrink-0 cursor-pointer">
        <div className="relative">
              <img
                src={`${bannerImage? `${baseUrl}/${contentUrl}/${bannerImage}?t=${token}`:defaultBanner}`}
                alt="Banner Image"
                className="object-cover h-28 w-[351px] rounded-lg"
              />
            
              <div className="absolute left-[35%] top-[50%]">
              <img
                src={`${profileImage? `${baseUrl}/${contentUrl}/${profileImage}?t=${token}`:defaultBanner}`}
                alt="Banner Image"
                className=" h-28 w-28 rounded-full object-cover border border-white"
              />
              </div>
          </div>

          <div className='flex flex-col items-center mt-[20%]'>
            <h1 className="text-xl font-semibold">{title}</h1>
            <h1 className="text-center text-gray-400">{location}</h1>
            <h2 className='text-lxgray-100 mt-4'>{tagline}</h2>
          </div>
    </div>
  )
}

export default CompanyCard
