import React from "react";

const MessageIcon = () => {
  return (
    <div className="hover:scale-95 cursor-pointer">
      <svg
        width="40"
        height="40"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.25 49.5C38.6429 49.5 49.5 38.6429 49.5 25.25C49.5 11.8571 38.6429 1 25.25 1C11.8571 1 1 11.8571 1 25.25C1 38.6429 11.8571 49.5 25.25 49.5Z"
          stroke="#AA933E"
        />
        <path
          d="M14.8938 30.9973L14.2195 33.3525C13.9827 34.1799 14.2133 35.0697 14.8212 35.6784C15.4299 36.2871 16.3197 36.5177 17.1472 36.2817L19.5055 35.6074C21.2069 36.554 23.1658 37.0933 25.2493 37.0933C31.7857 37.0933 37.0923 31.7867 37.0923 25.2502C37.0923 18.7138 31.7857 13.4072 25.2493 13.4072C18.7128 13.4072 13.4062 18.7138 13.4062 25.2502C13.4062 27.3346 13.9462 29.295 14.8937 30.9972L14.8938 30.9973ZM16.5037 31.1102C16.5638 30.9009 16.5345 30.676 16.4232 30.4888C15.5097 28.9556 14.9854 27.1633 14.9854 25.2504C14.9854 19.5856 19.5846 14.9864 25.2494 14.9864C30.9142 14.9864 35.5134 19.5856 35.5134 25.2504C35.5134 30.9152 30.9142 35.5143 25.2494 35.5143C23.3371 35.5143 21.5465 34.9909 20.0141 34.0782C19.8269 33.9668 19.6028 33.9384 19.3936 33.9977C19.3936 33.9977 18.0577 34.379 16.7139 34.7636C16.4376 34.8425 16.1415 34.765 15.9384 34.5622C15.7356 34.3593 15.6589 34.0624 15.7379 33.7869L16.5038 31.1104L16.5037 31.1102Z"
          fill="#AA933E"
        />
      </svg>
    </div>
  );
};

export default MessageIcon;
