
//AppStore Link
// export  const AppLink="https://play.google.com/store/apps/details?id=com.linkcxo.linkcxo&hl=en-IN"
export  const AppLink="/login-signup"


export const Sig_Events_Background="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/desktop-view/Sig_Events_Background.jpg"
export const Branding_Addvertisment_DkBanner="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/desktop-view/Branding_Addvertisment_DkBanner.png"
export const MobileBanner="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/mobile-view/MobileBanner.png"
export const Branding_Addvertisment_Banner="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/mobile-view/Branding_Addvertisment_Banner.png"
export const AboutUsBg="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/mobile-view/AboutUsBg.png"
export const Bg_Counter="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/mobile-view/Bg_Counter.png"
export const DesktopBanner="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/desktop-view/DesktopBanner.png"
export const TabletBanner="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/tab-view/TabletBanner.png"
export const CareerProgressionBgImage="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/mobile-view/CareerProgressionBgImage.png"


//Rewards and Privileges
export const Collaboration="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Rewards/collaboration.png"
export const Inves="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Rewards/Investment.png"
export const Car="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Rewards/RewardsAutomobile.png"


// Rewards
export const Car1="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/PartnerImages/Automobile.jpg"
export const Car2="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/PartnerImages/Automobile%202.png"

export const Building="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Rewards/Building.png"
export const Building2="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/PartnerImages/Hotel.jpg"

export const Electronics="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/PartnerImages/Electronics.jpg"
export const Electronics2="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/PartnerImages/electronic3.jpg"
export const Electronics3="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/PartnerImages/electronic4.jpg"

export const Health="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Rewards/Health.png"
export const Health2="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/PartnerImages/health.jpg"

export const Hotel="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/Rewards/Table.png"
export const Hotel2="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/PartnerImages/Hotel.jpg"

export const Apparels="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/PartnerImages/Apparel.png"
export const Apparels2="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/PartnerImages/Apparel2.jpeg"

export const Hospitality1="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/PartnerImages/Hospitality1.png"
export const Hospitality2="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/PartnerImages/Hospitality2.png"
// Video Url
export const videoUrl="https://stagebucket.blob.core.windows.net/linkcxo/www.linkcxo.com/www.linkcxo.com/desktop-view/LinkCxO%20Promo%20Updated%20Video.mp4"

export const Statictoken="DHBMRWcAgKvOGQw531KFFF93raNueBkajh4vpQyvdWFd3UeE7HroIlig9sWpzTkZzzWvTxDhB0Wu3lFpy0m9MUbE5pmsn8RKZf2rgUe49GcWKxoyBbWBsSLVZpqflISx"
