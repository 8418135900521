import CheckedIcon from "../../../../assets/icons/CheckedIcon";

const CustomOption = ({
  option,
  checked,
  questionId,
  question,
  questionType,
  onChange,
}: {
  option: string;
  question: string;
  questionType: string;
  questionId: string;
  checked: boolean;
  onChange: (
    questionId: string,
    selectedOption: string,
    question: string,
    questionType: string
  ) => void;
}) => {
  return (
    <div className="border-2 border-lxgray-400 rounded-lg py-3 px-2">
      <div className="flex items-center gap-2">
        {questionType === "SINGLE" ? (
          <div
            className={`h-4 w-4 rounded-full border  cursor-pointer flex items-center justify-center ${
              checked ? "border-golden" : "border-lxgray-400"
            }`}
            onClick={() => onChange(questionId, option, question, questionType)}
          >
            {checked && <div className="h-2 w-2 rounded-full bg-golden"></div>}
          </div>
        ) : (
          <div
            className={`h-4 w-4 rounded-sm border  cursor-pointer flex items-center justify-center ${
              checked ? "border-golden bg-golden" : "border-lxgray-400"
            }`}
            onClick={() => onChange(questionId, option, question, questionType)}
          >
            {checked && <CheckedIcon />}
          </div>
        )}
        <h1 className="text-sm text-white text-start font-normal leading-none self-start">
          {option}
        </h1>
      </div>
    </div>
  );
};

export default CustomOption;
