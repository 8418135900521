import {
  EntityState,
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { PaginatedMetadata } from "../models/base";
import {
  CreateJobRequest,
  GetFilterJobRequest,
  GetJobApplicationsRequest,
  GetJobDetailRequest,
  GetRecommendedJobRequest,
  Job,
  JobApplication,
  JobApplyRequest,
  JobSaveRequest,
  UpdateJobApplication,
  JobApiRejectedPayload,
  GetAllAppliedJObs,
  JobStatusUpdate,
  ApplicationComment,
  GetComments,
  SendComment,
  ProfanityCheck
} from "../models/job";
import jobService from "../services/job.service";

import { toast ,ToastOptions} from "react-toastify";
import { MessagePosition } from "../components/Notify/MessagePosition";
import { UploadFileRequest } from "../models/content";
import contentService from "../services/content.service";
import { sendMessageApi } from "./chatSlice";
import { logout } from "./loginSlice";

const jobAdaptor = createEntityAdapter<Job>({});
const jobNewAdaptor=createEntityAdapter<JobApplication>({})

export interface JobReduxState {
  createdJobList: EntityState<Job, string>;
  inActiveJobList: EntityState<Job, string>;
  closedJobList: EntityState<Job, string>;
  appliedJobList: EntityState<JobApplication, string>;
  recommendedJobList: EntityState<Job, string>;
  savedJobList: EntityState<Job, string>;
  filteredJobList:EntityState<Job, string>;
  appliedJobApplications: Array<JobApplication>;
  shortlistedJobApplications: Array<JobApplication>;
  holdJobApplications: Array<JobApplication>;
  rejectedJobApplications: Array<JobApplication>;
  jobApplicationComments:{
    comments:any,
    metadata:PaginatedMetadata
  }
  metadataJob: PaginatedMetadata;
  metadataSavedJob: PaginatedMetadata;
  jobDes: Job;
  popup: {
    createJob:Boolean,
    applyJob:Boolean
  };
}
const initialState: JobReduxState = {
  createdJobList: jobAdaptor.getInitialState(),
  inActiveJobList: jobAdaptor.getInitialState(),
  closedJobList: jobAdaptor.getInitialState(),
  appliedJobList: jobNewAdaptor.getInitialState(),
  recommendedJobList: jobAdaptor.getInitialState(),
  savedJobList: jobAdaptor.getInitialState(),
  filteredJobList:jobAdaptor.getInitialState(),
  appliedJobApplications: [],
  shortlistedJobApplications: [],
  holdJobApplications: [],
  rejectedJobApplications: [],
  jobApplicationComments:{
    comments:[],
    metadata:null
  },
  metadataJob: {
    maxId: null,
    minId: null,
    size: null,
    nextResultURL: null,
    previousResultURL: null,
  },
  metadataSavedJob: {
    maxId: null,
    minId: null,
    size: null,
    nextResultURL: null,
    previousResultURL: null,
  },
  jobDes: null,
  popup: {
    createJob:false,
    applyJob:false
  },
};

export const createJobApi = createAsyncThunk(
  "job/createJobApi",
  async (data: CreateJobRequest, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.createJob(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getRecommendedJobApi = createAsyncThunk(
  "job/getRecommendedJobApi",
  async (
    _data: GetRecommendedJobRequest,
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await jobService.getRecommendedJobs(_data);
      dispatch(addJobs(response.data.data.jobs));
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getFilteredJobApi = createAsyncThunk(
  "job/getFilteredJobApi",
  async (
    _data: GetFilterJobRequest,
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await jobService.getRecommendedJobs(_data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNextJobApi = createAsyncThunk(
  "job/getNextFeedApis",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state: any = getState();
      const response = await jobService.getJobByUrl(
        state.job.metadataJob.nextResultURL
      );
      dispatch(updateJobs(response.data.data.jobs));
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getNextSaveJobApi = createAsyncThunk(
  "job/getNextSaveJobApi",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state: any = getState();
      const response = await jobService.getJobByUrl(
        state.job.metadataSavedJob.nextResultURL
      );
      dispatch(updateJobs(response.data.data.jobs));
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getHiringJobs = createAsyncThunk(
  "job/getHiringJobs",
  async (_data: GetFilterJobRequest, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.getRecommendedJobs(_data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAppliedJobApi = createAsyncThunk(
  "job/getAppliedJobApi",
  async (_data: null, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.getAppliedJob();
      // console.log(response);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getSavedJobApi = createAsyncThunk(
  "jobs/getSavedJobApi",
  async (_data: null, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.getSavedJob();
      // console.log(response);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const saveJobApi = createAsyncThunk(
  "jobs/saveJobApi",
  async (_data: JobSaveRequest, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.saveJob(_data);
      console.log(response);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const unsaveJobApi = createAsyncThunk(
  "jobs/unsaveJobApi",
  async (_data: JobSaveRequest, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.unsaveJob(_data);
      console.log(response);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const applyToJobApi = createAsyncThunk(
  "jobs/applyToJobApi",
  async (_data: JobApplyRequest, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.applyToJob(_data);
      console.log(response);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getJobDetailApi = createAsyncThunk(
  "jobs/getJobDetailApi",
  async (_data: GetJobDetailRequest, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.getJobDetail(_data);
      //  console.log("Response",response);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getApplicantsApi = createAsyncThunk(
  "jobs/getApplicantsApi",
  async (data: GetJobApplicationsRequest, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.getApplicants(data);
      // console.log(response);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateJobApi = createAsyncThunk(
  "job/updateJobApi",
  async (data: CreateJobRequest, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.updateJob(data);
      console.log(response);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateApplication = createAsyncThunk(
  "job/updateApplication",
  async (data: UpdateJobApplication, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.updateApplication(data);
      console.log(response);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateJobStatusApi = createAsyncThunk(
  "job/updateJobStatusApi",
  async (data: JobStatusUpdate, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.updateJobStatus(data);
      console.log(response);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const uploadFiles = createAsyncThunk(
  "post/uploadFilesApi",
  async (data: UploadFileRequest, { getState, rejectWithValue }) => {
    try {
      const response = await contentService.uploadFile(data);
      console.log(response)
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getAllApplicationMessageApi = createAsyncThunk(
  "job/getAllApplicationMessageApi",
  async (data: GetComments, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.getAllAplicationMessage(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const sendApplicationMessageApi = createAsyncThunk(
  "job/sendApplicationMessageApi",
  async (data: SendComment, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.sendApplicationMessage(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);




export const jobJDAutoFilling = createAsyncThunk(
  "jobs/jobDetailAiModule",
  async (data: ProfanityCheck, { getState, rejectWithValue }) => {
    try {
      const response = await jobService.getJDAutoFilling(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const jobSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    addJobs: (state: JobReduxState, action: PayloadAction<Job[]>) => {
      const jobs = action.payload;
      jobAdaptor.setAll(state.recommendedJobList, jobs);
    },
    updateJobs: (state: JobReduxState, action: PayloadAction<Job[]>) => {
      const jobs = action.payload;
      jobAdaptor.addMany(state.recommendedJobList, jobs);
    },
    popupShowCreate: (state) => {
      state.popup.createJob = !state.popup.createJob;
    },
    popupShowApply:(state) => {
      state.popup.applyJob = !state.popup.applyJob;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createJobApi.fulfilled, (state: JobReduxState, action) => {
       
        const jobData=action.payload.data.data
        toast.success("Job Created SuccessFully")
        console.log(action.payload)
        console.log(action.payload.data.data)
        //jobArray.push();
        jobAdaptor.addOne(state.createdJobList,jobData);
        //console.log(action.payload.data, 'action.payload.data')
      })
      .addCase(createJobApi.rejected,(state,action)=>{
        if (action.payload) {
          const { error } = action.payload as JobApiRejectedPayload;
          console.log("REJECTED", error.message);
          toast.error(error.message,MessagePosition as ToastOptions)
        }
      })
      .addCase(getJobDetailApi.fulfilled, (state: JobReduxState, action) => {
        // console.log("Payloadddd",action.payload.data.data[0])
        state.jobDes = action.payload.data.data[0];
      })
      .addCase(
        getRecommendedJobApi.fulfilled,
        (state: JobReduxState, action) => {
          //jobAdaptor.setAll(state.recommendedJobList, action.payload.data.data.jobs);
          // state.metadata = action.payload.data.data.metadata;
        }
      )
    
      .addCase(getHiringJobs.fulfilled, (state: JobReduxState, action) => {
        let jobList=action.payload.data.data.jobs
        if(jobList){
          jobAdaptor.setAll(state.inActiveJobList,jobList.filter((ele)=>ele.jobStatus==="INACTIVE"))
          jobAdaptor.setAll(state.createdJobList, jobList.filter((ele)=>ele.jobStatus==="OPEN"));
          jobAdaptor.setAll(state.closedJobList, jobList.filter((ele)=>ele.jobStatus==="CLOSED"));
        }
        else{
          state.inActiveJobList=jobAdaptor.getInitialState()
          state.createdJobList=jobAdaptor.getInitialState()
          state.closedJobList=jobAdaptor.getInitialState()
        }
      })
      .addCase(getFilteredJobApi.fulfilled,(state:JobReduxState,action)=>{
        let jobList=action.payload.data.data.jobs
        console.log(jobList)
        console.log("job",action.meta.arg.authorId)
        if(jobList){
          jobAdaptor.setAll(state.filteredJobList,jobList) 
          state.metadataJob=action.payload.data.data.metadata
        }else{
          jobAdaptor.setAll(state.filteredJobList,[]) 

        }
      })
      .addCase(getAppliedJobApi.fulfilled, (state: JobReduxState, action) => {
        let jobList= action?.payload?.data.data;
        // console.log(jobList,"JobLIst")
        if(jobList){
           jobNewAdaptor.setAll(state.appliedJobList, jobList);
        }
      })
      .addCase(getSavedJobApi.fulfilled, (state: JobReduxState, action) => {
        let jobList= action?.payload?.data?.data?.jobs;
        // console.log(jobList,"jOBSSAVED")
        if(jobList!==null){
          jobAdaptor.setAll(state.savedJobList, jobList);
          state.metadataSavedJob = action.payload.data.data.metadata;
        }
      })
      .addCase(saveJobApi.fulfilled, (state: JobReduxState, action) => {
        let removeJob = state.recommendedJobList[action.meta.arg.jobId];
        jobAdaptor.removeOne(state.recommendedJobList, removeJob); // state.savedJobList = [...state.savedJobList, { ...saveJobApi }]
        jobAdaptor.addOne(state.savedJobList, action.payload.data.data);
        
      })
      .addCase(getApplicantsApi.fulfilled, (state: JobReduxState, action) => {
        // console.log(action.payload.data.data.applications);
        let jobApplications = action.payload.data.data.applications;
        //state.appliedJobApplications =
        if (jobApplications) {
          state.appliedJobApplications = jobApplications.filter(
            (application) => application.status === "APPLIED"
          );
          state.holdJobApplications = jobApplications.filter(
            (application) => application.status === "HOLD"
          );
          state.rejectedJobApplications = jobApplications.filter(
            (application) => application.status === "REJECTED"
          );
          state.shortlistedJobApplications = jobApplications.filter(
            (application) => application.status === "SHORTLISTED"
          );
        }
        else{
          state.appliedJobApplications=[]
          state.holdJobApplications=[]
          state.holdJobApplications=[]
          state.rejectedJobApplications=[]
        }
      })
      .addCase(applyToJobApi.fulfilled, (state: JobReduxState, action) => {
        let removeJob = state.recommendedJobList[action.meta.arg.jobId];
        jobAdaptor.removeOne(state.recommendedJobList, removeJob);
        // jobNewAdaptor.addOne(state.appliedJobList, removeJob);
        toast.success("Job Applied SuccessFully")
      })
      .addCase(applyToJobApi.pending, (state: JobReduxState, action) => {
        // toast.dark("Applying Job")
      })
      .addCase(applyToJobApi.rejected, (state: JobReduxState, action) => {
        toast.error("❌ ERROR!!! Please Apply Again ")
      })
      .addCase(updateApplication.fulfilled, (state: JobReduxState, action) => {
        console.log(action.payload.data.data, "action.payload.data");
        state.appliedJobApplications = state.appliedJobApplications.filter(
          (application) => application.id !== action.payload.data.data.id
        );
        state.holdJobApplications = state.holdJobApplications.filter(
          (application) => application.id !== action.payload.data.data.id
        );
        state.shortlistedJobApplications =
          state.shortlistedJobApplications.filter(
            (application) => application.id !== action.payload.data.data.id
          );
        state.rejectedJobApplications = state.rejectedJobApplications.filter(
          (application) => application.id !== action.payload.data.data.id
        );
        if (action.payload.data.data.status === "HOLD") {
          let holdApplications = state.holdJobApplications;
          holdApplications.push(action.payload.data.data);
          state.holdJobApplications = holdApplications;
        } else if (action.payload.data.data.status === "SHORTLISTED") {
          let shortlistedApplications = state.shortlistedJobApplications;
          shortlistedApplications.push(action.payload.data.data);
          state.shortlistedJobApplications = shortlistedApplications;
        } else if (action.payload.data.data.status === "REJECTED") {
          let rejectedApplications = state.rejectedJobApplications;
          rejectedApplications.push(action.payload.data.data);
          state.rejectedJobApplications = rejectedApplications;
        }
      })
      .addCase(getAllApplicationMessageApi.fulfilled,(state,action)=>{
        console.log(action.payload.data.data,"FULFILLED")
          state.jobApplicationComments=action.payload.data.data
      })
      .addCase(sendApplicationMessageApi.fulfilled,(state,action)=>{
        
            state.jobApplicationComments.comments.unshift(action.payload.data.data)
      })
      
      .addCase(getNextJobApi.fulfilled, (state: JobReduxState, action) => {
        if (action.payload.data.data.jobs != null) {
          jobAdaptor.addMany(state.filteredJobList, action.payload.data.data.jobs);
        }
        //let allJobs = state.recommendedJobList as Array<Job>;
        //state.recommendedJobList = [...state.recommendedJobList, ...allJobs];
        // console.log(allJobs);
        state.metadataJob = action.payload.data.data.metadata;
      })
      .addCase(getNextSaveJobApi.fulfilled, (state: JobReduxState, action) => {
        if (action.payload.data.data.jobs != null) {
          jobAdaptor.addMany(state.savedJobList, action.payload.data.data.jobs);
        }
        state.metadataSavedJob = action.payload.data.data.metadata;
      })
      .addCase(logout, (state) => {
        // Reset this slice's state to initial state
        return initialState;
      });
  },
});

export const { addJobs, updateJobs, popupShowCreate,popupShowApply } = jobSlice.actions;

export default jobSlice.reducer;
