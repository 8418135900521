import apiClient from "../config/axiosConfig";
import { AxiosPromise } from "axios";
import { ApiResponse } from "../models/base";
import {
  AddLanguages,
  AddUserAwardRequest,
  AdditionalDetail,
  BasicUser,
  CreateEducationRequest,
  CreateEducationResponse,
  CreateExperienceRequest,
  CreatePublication,
  EmailUpdate,
  GetUserDetailsRequest,
  GetUserDetailsResponse,
  Language,
  PhoneUpdate,
  UpdateAwardRequest,
  UpdateEducationRequest,
  UpdateExperienceRequest,
  UpdatePublication,
  UpdateUserDetail,
  UpdateUserDetailResponse,
  UserDeleteInfoRequest,
  Verify,
} from "../models/user";
import { PaginatedPostResponse } from "../models/post";
import { GetOTPResponse } from "../models/auth";

class UserService {
  getUserDetail(
    userDetail: GetUserDetailsRequest
  ): AxiosPromise<ApiResponse<GetUserDetailsResponse>> {
    return apiClient.get(`v1/users/${userDetail.userId}`);
  }

  addExperience(
    experience: CreateExperienceRequest
  ): AxiosPromise<ApiResponse<CreateExperienceRequest>> {
    return apiClient.post(
      `v1/users/${experience.userId}/experience`,
      experience
    );
  }

  addEducation(
    education: CreateEducationRequest
  ): AxiosPromise<ApiResponse<CreateEducationResponse>> {
    return apiClient.post(`v1/users/${education.userId}/education`, education);
  }

  addPublication(
    publication: CreatePublication
  ): AxiosPromise<ApiResponse<CreatePublication>> {
    return apiClient.post(
      `v1/users/${publication.userId}/publications`,
      publication.publicationData
    );
  }

  addAward(
    award: AddUserAwardRequest
  ): AxiosPromise<ApiResponse<AddUserAwardRequest>> {
    return apiClient.post(`v1/users/${award.userId}/awards`, award);
  }

  addLanguages(
    language: AddLanguages
  ): AxiosPromise<ApiResponse<Array<Language>>> {
    return apiClient.patch(
      `v1/users/${language.userId}/languages`,
      language.languages
    );
  }

  postLanguages(
    language: AddLanguages
  ): AxiosPromise<ApiResponse<Array<Language>>> {
    return apiClient.post(
      `v1/users/${language.userId}/languages`,
      language.languages
    );
  }

  updateUserDetail(
    userDetail: UpdateUserDetail
  ): AxiosPromise<ApiResponse<UpdateUserDetailResponse>> {
    return apiClient.patch(`v1/users/${userDetail.userId}`, userDetail);
  }

  deleteUserInfo(
    info: UserDeleteInfoRequest
  ): AxiosPromise<ApiResponse<UpdateAwardRequest>> {
    return apiClient.delete(
      `v1/users/${info.userId}/${info.infoType}/${info.id}`
    );
  }

  updateExperience(
    experience: UpdateExperienceRequest
  ): AxiosPromise<ApiResponse<CreateEducationResponse>> {
    return apiClient.put(
      `v1/users/${experience.userId}/experience/${experience.experienceId}`,
      experience
    );
  }

  updateEducation(
    education: UpdateEducationRequest
  ): AxiosPromise<ApiResponse<CreateEducationResponse>> {
    return apiClient.put(
      `v1/users/${education.userId}/education/${education.educationId}`,
      education
    );
  }

  updateAward(
    award: UpdateAwardRequest
  ): AxiosPromise<ApiResponse<UpdateAwardRequest>> {
    return apiClient.put(
      `v1/users/${award.userId}/awards/${award.awardId}`,
      award
    );
  }

  updatePublication(
    publication: UpdatePublication
  ): AxiosPromise<ApiResponse<UpdatePublication>> {
    return apiClient.put(
      `v1/users/${publication.userId}/publications/${publication.publicationId}`,
      publication
    );
  }

  getAllPost(req: {
    authorId: string;
  }): AxiosPromise<ApiResponse<PaginatedPostResponse>> {
    return apiClient.get(`/v1/posts?authorId=${req.authorId}`);
  }

  addAdditionalDetail(
    data: AdditionalDetail
  ): AxiosPromise<ApiResponse<AdditionalDetail>> {
    return apiClient.post(`v1/users/${data.userId}/additional-info`,
      data
    );
  }

  updateEmail(
    data: EmailUpdate
  ): AxiosPromise<ApiResponse<GetOTPResponse>> {
    return apiClient.post(`v1/users/settings/email`,
      data
    );
  }

  updatePhone(
    data: PhoneUpdate
  ): AxiosPromise<ApiResponse<GetOTPResponse>> {
    return apiClient.post(`v1/users/settings/phone`,
      data
    );
  }

  verifyEmailPhone(
    data: Verify
  ): AxiosPromise<ApiResponse<BasicUser>> {
    return apiClient.post(`v1/users/settings/${data.type}/verify`,
      data
    );
  }
}
const userService = new UserService();
export default userService;
