import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '../../config/hooks'
import { updateJobStatusApi } from '../../slices/jobSlice'
import { useNavigate } from 'react-router-dom'
import CommonPopup from '../company/CommonPopup'
import UpdateJob from './components/UpdateJob'
interface Props{
  data:string[],
  jobId:string,
  pathName:string,
  setShowDotData?:Function
}

const EditDotPopup:React.FC<Props> = ({data,jobId,pathName,setShowDotData}) => {

    const dispatch=useAppDispatch()
    const navigate=useNavigate()
    const[isOpen,setIsOpen]=useState(false)

    const [updatedData,setUpdatedData]=useState([])
   
    useEffect(()=>{
     
      setUpdatedData(data)
        if(pathName.includes("Open")){
          setUpdatedData(["Edit",...data,])
        }
    },[])

    const handleClick=(e:React.MouseEvent<HTMLDivElement>,ele)=>{
        e.preventDefault()
        console.log(ele,jobId)

        if(ele==="Inactive"){
          dispatch(updateJobStatusApi({jobId:jobId,status:"INACTIVE"})) 
          navigate(`/tab/jobs/hiring`)      
        }
        if(ele==="Open"){
          dispatch(updateJobStatusApi({jobId:jobId,status:"OPEN"}))  
          navigate(`/tab/jobs/hiring`)     
        }
        if(ele==="Close"){
          dispatch(updateJobStatusApi({jobId:jobId,status:"CLOSED"})) 
          navigate(`/tab/jobs/hiring`)      
        }
        if(ele==="Edit"){
          setIsOpen(true)
        }
        // navigate(`/tab/jobs/hiring`)
    }
    
  return (
    <div>

    
    <div className='absolute bg-lxgray-700 text-center text-lxgray-100 py-1 right-8 top-0 rounded-lg'>
        {
          updatedData?.map((ele,i)=>{
            return (<p onClick={(e)=>handleClick(e,ele)} className={`text-sm  p-1 ${updatedData.length-1===i?"":"border-b border-lxgray-200"} hover:bg-lxgray-400 `} key={`${i}Popup`}>{ele}</p>)
          })
        } 
    </div>
   {isOpen &&  <div>
      <CommonPopup setIsOpen={setIsOpen} isOpen={isOpen}><UpdateJob setIsOpen={setIsOpen} /></CommonPopup>
    </div>}
    </div>
  )
}

export default EditDotPopup
