import apiClient from "../config/axiosConfig";
import { AxiosPromise } from "axios";
import { ApiResponse } from "../models/base";
import {
  CreateSurveyResponse,
  GetSurveyRequest,
  SubmitSurveyAnswer,
  Survey,
} from "../models/survey";

class SurveyService {
  createNewSurvey(
    createSurveyReq: Survey
  ): AxiosPromise<ApiResponse<CreateSurveyResponse>> {
    return apiClient.post(`/v1/surveys`, createSurveyReq);
  }

  getSurveyDetail(
    getSurvey: GetSurveyRequest
  ): AxiosPromise<ApiResponse<CreateSurveyResponse>> {
    return apiClient.get(`/v1/surveys/${getSurvey.surveyId}`);
  }

  submitSurveyAnswer(
    submitAnswer: SubmitSurveyAnswer
  ): AxiosPromise<ApiResponse<CreateSurveyResponse>> {
    return apiClient.post(
      `/v1/surveys/${submitAnswer.surveyId}/answers`,
      submitAnswer
    );
  }
}

const surveyService = new SurveyService();
export default surveyService;
