import { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { registerApi } from "../../slices/loginSlice";
import { NavigationURLs } from "../../models/screen";

import LxInput from "./basic-components/LxInput";
import LxDropDown from "./basic-components/LxDropDown";
import LxCountryDropdown from "./basic-components/LxCountryDropdown";

import CheckedIcon from "../../assets/icons/CheckedIcon";
import { baseUrl } from "../../utils/contant";
import { Statictoken } from "../../assets/images/url/url";
import { Link } from "react-router-dom";

type FormValues = {
  firstName: string;
  lastName: string;
  companyName: string;
  emailId: string;
  designation: {
    Id: string;
    Title: string;
    __isNew__?: boolean;
    value?: string;
  };
  countrycode: {
    Id: number;
    CountryName: string;
    Iso3: string;
    PhoneCode: string;
  };
  yearsOfExperience: string;
  linkedInLink: string;
  phoneNo: string;
};

export default function SignupForm() {
  const buttonRef = useRef(null);
  const dispatch = useAppDispatch();
  const [toggle, settoggle] = useState(false);
  // const currentLocation = useAppSelector((state) => state.appConfig.location);
  const currentLocation = "India";
  const [countryCodes, setCountryCodes] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { requestId, email, phoneNo } = useAppSelector(
    (state) => state.login.ui
  );

  // const options = useAppSelector((state) => state.appConfig.designations);
  const [options, setOptions] = useState([]);

  // const countryCodes = useAppSelector((state) => state.appConfig.countries);
  const userId = useAppSelector((state) => state.login.userId);
  const from = location.state?.from?.pathname || "/tab";

  useEffect(() => {
    fetchCountries();
    fetchDesignations();
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/v1/configuration/api/v1/db/data/v1/linkcxo/Countries?limit=500&fields=Id,CountryName,PhoneCode,Iso3`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${Statictoken}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      console.log("Response.json", data);
      setCountryCodes(data.list);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  const fetchDesignations = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/v1/configuration/api/v1/db/data/v1/linkcxo/designations?limit=500&fields=Id,Title`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${Statictoken}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      console.log("Response.json", data);
      setOptions(data.list);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  console.log(countryCodes);

  const defualtCouontryCode =
    currentLocation &&
    countryCodes.find(
      (countryDetail) =>
        currentLocation.toLowerCase() ===
        countryDetail.CountryName.toLowerCase()
    );

  const modifiedCountryCodes = countryCodes?.map((country) => ({
    ...country,
    PhoneCode: `${country.Iso3} +${country.PhoneCode}`,
  }));

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      countrycode: {
        CountryName: "India",
        Iso3: "IND",
        PhoneCode: "IND +91",
        Id: 96,
        // PhoneCode: `${"IND"} +${"91"}`,
      },

      // {
      //   // PhoneCode: `${defualtCouontryCode.Iso3} +${defualtCouontryCode.PhoneCode}`,
      //   PhoneCode: `${"IND"} +${"91"}`,
      // },
    },
  });

  // useEffect(() => {
  //   if (!email && !phoneNo) {
  //     console.log("navigate to email start page");
  //     navigate("/login-signup");
  //   }
  // }, [email, navigate, phoneNo]);

  useEffect(() => {
    if (userId !== null) {
      // console.log("navigate to home page");
      // navigate(NavigationURLs.Tab);
      navigate(from, { replace: true });
    }
  }, [navigate, userId]);

  const submitButtonHandler = () => {
    buttonRef.current.click();
  };

  const toggleCheckBoxHandler = () => {
    settoggle(!toggle);
  };

  const onSubmit = (formData: FormValues) => {
    console.log("formData :>> ", formData);

    if (!email && phoneNo) {
      dispatch(
        registerApi({
          firstName: formData.firstName,
          lastName: formData.lastName,
          organization: formData.companyName,
          emailId: formData.emailId,
          requestId: requestId,
          designation: formData.designation.__isNew__
            ? formData.designation.value
            : formData.designation.Title,
          yearsOfExperience: formData.yearsOfExperience,
          socialInfo: {         
            "Linkedin":{
              type: "linkedin",
              profileURL: formData.linkedInLink, 
             } 
          },
        })
      );
    } 
    else if(email && !phoneNo){
      const numbers = formData.countrycode.PhoneCode.match(/\d+/g);

      if (numbers) {
        const selectedCountryCode = parseInt(numbers[0]);
        dispatch(
          registerApi({
            firstName: formData.firstName,
            lastName: formData.lastName,
            organization: formData.companyName,
            countryCode: selectedCountryCode,
            phoneNo: parseInt(formData.phoneNo),
            requestId: requestId,
            designation: formData.designation.__isNew__
              ? formData.designation.value
              : formData.designation.Title,
            yearsOfExperience: formData.yearsOfExperience,
            socialInfo: {             
              "Linkedin":{
                type: "linkedin",
                profileURL: formData.linkedInLink, 
               }            
            },
          })
        );
      }
    }
    else{
      const numbers = formData.countrycode.PhoneCode.match(/\d+/g);

      if (numbers) {
        const selectedCountryCode = parseInt(numbers[0]);
        dispatch(
          registerApi({
            firstName: formData.firstName,
            lastName: formData.lastName,
            organization: formData.companyName,
            countryCode: selectedCountryCode,
            phoneNo: parseInt(formData.phoneNo),
            // emailId: formData.emailId,
            requestId: requestId,
            designation: formData.designation.__isNew__
              ? formData.designation.value
              : formData.designation.Title,
            yearsOfExperience: formData.yearsOfExperience,
            socialInfo: {
              "Linkedin":{
                type: "linkedin",
                profileURL: formData.linkedInLink, 
               } 
            },
          })
        );
      }

    }
  };

  return (
    <>
      <div
        className="text-center py-4 mb-4"
        style={{ boxShadow: "0px 11px 8px -3px #00000033" }}
      >
        <h1 className="text-2xl text-lxwhite-100 font-semibold">
          Tell Us About Yourself
        </h1>
        <p className="text-lg text-lxgray-400 tracking-wider">
          Let's know you more
        </p>
      </div>
      <div>
        <form
          className="space-y-2 max-h-[61vh] overflow-y-auto scrollbar-hidden px-8"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="">
            <Controller
              name="firstName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter valid first name",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <LxInput
                  className={`h-[50px] border-2 ${
                    errors?.firstName?.message
                      ? "border-red-600"
                      : "border-lxgray-400"
                  }`}
                  placeholder={"Enter Here"}
                  title="First name *"
                  name="firstName"
                  value={value || ""}
                  onChange={onChange}
                  error={errors?.firstName?.message}
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="lastName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter valid last name",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <LxInput
                  className={`h-[50px] border-2 ${
                    errors?.lastName?.message
                      ? "border-red-600"
                      : "border-lxgray-400"
                  }`}
                  placeholder={"Enter Here"}
                  title="Last Name *"
                  name="lastName"
                  value={value || ""}
                  onChange={onChange}
                  error={errors?.lastName?.message}
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="companyName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter valid company name",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <LxInput
                  className={`h-[50px] border-2 ${
                    errors?.companyName?.message
                      ? "border-red-600"
                      : "border-lxgray-400"
                  }`}
                  placeholder={"Add your company name"}
                  title="Current Company *"
                  name="companyName"
                  value={value || ""}
                  onChange={onChange}
                  error={errors?.companyName?.message}
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="designation"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please select valid designantion",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <LxDropDown
                  title="Designation *"
                  onChange={onChange}
                  placeholder="Search & Pick Your Designation"
                  value={value}
                  optionValue="Id"
                  optionLabel="Title"
                  options={options}
                  dropDownHeight="50px"
                  dropDownBorderWidth="2px"
                  dropDownBorderColor="#696969"
                  error={errors?.designation?.message}
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="yearsOfExperience"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Experience is required",
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Please enter a valid experience",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <LxInput
                  className={`h-[50px] border-2 ${
                    errors?.yearsOfExperience?.message
                      ? "border-red-600"
                      : "border-lxgray-400"
                  }`}
                  placeholder={"Enter Here"}
                  title="Years Of Experience *"
                  name="yearsOfExperience"
                  value={value || ""}
                  onChange={onChange}
                  error={errors?.yearsOfExperience?.message}
                />
              )}
            />
          </div>
          {!email && phoneNo && (
            <div>
              <Controller
                name="emailId"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter valid email id",
                  },
                  pattern: {
                    value:
                      /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                    message: "Please enter a valid email id",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <LxInput
                    className={`h-[50px] border-2 ${
                      errors?.emailId?.message
                        ? "border-red-600"
                        : "border-lxgray-400"
                    }`}
                    placeholder={"Enter your email id"}
                    title="Email *"
                    name="emailId"
                    value={value || ""}
                    onChange={onChange}
                    error={errors?.emailId?.message}
                  />
                )}
              />
            </div>
          )}
          {!phoneNo && email && (
            <div className="flex items-center justify-center gap-2">
              <div className="self-baseline">
                <Controller
                  name="countrycode"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <LxCountryDropdown
                      title="Country Code *"
                      onChange={onChange}
                      placeholder="Please Select"
                      value={value || null}
                      optionValue="Id"
                      optionLabel="PhoneCode"
                      options={modifiedCountryCodes}
                      dropDownHeight="50px"
                      dropDownBorderWidth="2px"
                      dropDownBorderColor="#696969"
                      error={errors?.countrycode?.message}
                    />
                  )}
                />
              </div>

              <div className="flex-1 self-baseline">
                <Controller
                  name="phoneNo"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter valid mobile number",
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid mobile number",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <LxInput
                      className={`h-[50px] border-2 ${
                        errors?.phoneNo?.message
                          ? "border-red-600"
                          : "border-lxgray-400"
                      }`}
                      placeholder={"Enter Your Mobile Number"}
                      title="Mobile Number *"
                      name="phoneNo"
                      value={value || ""}
                      onChange={onChange}
                      error={errors?.phoneNo?.message}
                    />
                  )}
                />
              </div>
            </div>
          )}
          {!phoneNo && !email && (
            <div className="flex items-center justify-center gap-2">
              <div className="self-baseline">
                <Controller
                  name="countrycode"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <LxCountryDropdown
                      title="Country Code *"
                      onChange={onChange}
                      placeholder="Please Select"
                      value={value || null}
                      optionValue="Id"
                      optionLabel="PhoneCode"
                      options={modifiedCountryCodes}
                      dropDownHeight="50px"
                      dropDownBorderWidth="2px"
                      dropDownBorderColor="#696969"
                      error={errors?.countrycode?.message}
                    />
                  )}
                />
              </div>

              <div className="flex-1 self-baseline">
                <Controller
                  name="phoneNo"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter valid mobile number",
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid mobile number",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <LxInput
                      className={`h-[50px] border-2 ${
                        errors?.phoneNo?.message
                          ? "border-red-600"
                          : "border-lxgray-400"
                      }`}
                      placeholder={"Enter Your Mobile Number"}
                      title="Mobile Number *"
                      name="phoneNo"
                      value={value || ""}
                      onChange={onChange}
                      error={errors?.phoneNo?.message}
                    />
                  )}
                />
              </div>
            </div>
          )}
          <div>
            <Controller
              name="linkedInLink"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Social Media Link is required",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <LxInput
                  className={`h-[50px] border-2 ${
                    errors?.linkedInLink?.message
                      ? "border-red-600"
                      : "border-lxgray-400"
                  }`}
                  placeholder={"Enter Here"}
                  title="Social Media Link *"
                  name="linkedInLink"
                  value={value || ""}
                  onChange={onChange}
                  error={errors?.linkedInLink?.message}
                />
              )}
            />
          </div>
          <div className="py-2 flex items-center justify-start gap-2">
            <div
              className={`h-5 w-6 border-2  rounded-md cursor-pointer ${
                toggle ? "bg-golden border-golden" : "border-lxgray-400"
              } flex items-center justify-center`}
              onClick={toggleCheckBoxHandler}
            >
              {toggle && (
                <div className="mt-[1px]">
                  <CheckedIcon />
                </div>
              )}
            </div>
            <p className="text-white leading-none self-baseline">
              <span>By continuing you agree to LinkCxO's </span>
              <Link to={"/terms-and-conditions"}>
                <span className="text-golden underline underline-offset-2">
                  Terms of Service
                </span>{" "}
                &
              </Link>
              <Link to={"/privacy-policy"}>
                <span className="text-golden underline underline-offset-2">
                  {" "}
                  Privacy Policy.
                </span>
              </Link>
            </p>
          </div>
          <button type="submit" className="hidden" ref={buttonRef}>
            Sumbit
          </button>
        </form>
      </div>
      <div
        className="mt-4 mb-2 pb-4 pt-3"
        style={{ boxShadow: "0px -11px 8px -3px #00000033" }}
      >
        <div className="w-1/2 mx-auto">
          <button
            disabled={!toggle}
            type="submit"
            className={`w-full bg-golden ${
              !toggle && "opacity-70"
            } rounded-lg py-3 text-white text-xl tracking-wider`}
            onClick={submitButtonHandler}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
}
