import { useState, useEffect, useRef } from "react";
import AudioControls from "./AudioControls";
import "../../../../App.css";
import RemoveImageIcon from "../../../../assets/icons/RemoveImageIcon";

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-an-audio-player-with-react-hooks
 */
const AudioPlayer = ({ audioSrc, fileName, removePdfHandler = () => {} }) => {
  // State
  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [formattedTime, setFormattedTime] = useState("00:00");

  // Destructure for conciseness

  // Refs
  const audioRef = useRef(new Audio(audioSrc));
  const isReady = useRef(false);
  const intervalRef = useRef(null);

  // Destructure for conciseness
  const { duration } = audioRef.current;

  const currentPercentage = duration
    ? `${(trackProgress / duration) * 100}%`
    : "0%";

  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #AA933E), color-stop(${currentPercentage}, #777))
  `;

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      setTrackProgress(audioRef.current.currentTime);
    }, 1000);
  };

  const onScrub = (value) => {
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      startTimer();
    } else {
      console.log("pause");
      audioRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    const currentTime = audioRef.current.currentTime;
    const minutes = Math.floor(currentTime / 60);
    const seconds = Math.floor(currentTime % 60);
    const formattedTime = `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
    setFormattedTime(formattedTime);
  }, [audioRef.current.currentTime]);

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  // Handles cleanup and setup when changing tracks
  useEffect(() => {
    audioRef.current.pause();

    audioRef.current = new Audio(audioSrc);
    setTrackProgress(audioRef.current.currentTime);

    if (isReady.current) {
      audioRef.current.play();
      setIsPlaying(true);
      startTimer();
    } else {
      // Set the isReady ref as true for the next pass
      isReady.current = true;
    }
  }, [audioSrc]);

  useEffect(() => {
    audioRef.current.ended && onEnded();
  }, [audioRef.current.ended]);

  const onEnded = () => {
    // When audio ends, reset the player
    audioRef.current.currentTime = 0;
    setTrackProgress(0);
    setIsPlaying(false);
  };

  return (
    <div className="audio-player">
      <div className="flex items-center justify-center gap-4 p-3 bg-lxgray-600 rounded-xl shadow-sm shadow-black">
        <AudioControls isPlaying={isPlaying} onPlayPauseClick={setIsPlaying} />
        <div className="flex-1">
          <div className="flex items-center justify-between">
            <h1 className="text-white font-bold text-lg tracking-widest">
              {formattedTime}
            </h1>
            <p className="text-lxgray-400 font-light text-base">{fileName}</p>
          </div>
          <input
            type="range"
            value={trackProgress}
            step="1"
            min="0"
            max={duration ? duration : `${duration}`}
            onChange={(e) => onScrub(e.target.value)}
            style={{ background: trackStyling }}
          />
        </div>
        <div onClick={removePdfHandler} className="cursor-pointer">
          <RemoveImageIcon width="26" height="26" />
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
