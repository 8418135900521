const SendButtonIcon = ({ width = "34", height = "34", color = "white" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="17" cy="17" r="17" fill="#AA933E" />
      <path
        d="M11.2102 23.8636C11.1991 23.8525 11.1886 23.8412 11.178 23.8292C10.9755 23.5987 10.9398 23.2655 11.089 22.9972L14.0423 17.6799L11.0895 12.2692C10.9427 12.0007 10.9804 11.669 11.183 11.4398C11.386 11.2105 11.7106 11.1334 11.9951 11.2462L26.3807 16.9643C26.6573 17.0747 26.8387 17.3415 26.8386 17.6394C26.8387 17.9371 26.6571 18.2045 26.3803 18.3143L11.9918 24.025C11.721 24.1326 11.4141 24.0676 11.2102 23.8636ZM13.3282 13.3395L15.5088 17.3352C15.6281 17.5536 15.6272 17.8182 15.5061 18.0357L13.3461 21.9247L24.1448 17.6389L13.3282 13.3395Z"
        fill={color}
      />
    </svg>
  );
};

export default SendButtonIcon;
