import * as React from "react"

function VoiceIcon() {
  return (
    <svg
      width={21}
      height={28}
      viewBox="0 0 21 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.529 27.986H7.385a.999.999 0 11.03-1.997h2.033v-2.55A10.398 10.398 0 01.277 13.008a1.01 1.01 0 011.002-1.017h.03a1.007 1.007 0 011.005 1.017 8.16 8.16 0 1016.305 0v-.028a1.017 1.017 0 012.033.028 10.398 10.398 0 01-9.17 10.431v2.55h2.066a.999.999 0 11-.003 1.997h-.016zm-8.152-13.98V5.017a5.097 5.097 0 0110.19 0v8.987a5.097 5.097 0 01-10.19 0zM7.41 5.017v8.987a3.058 3.058 0 006.115 0V5.018a3.058 3.058 0 00-6.115 0z"
        fill="#8C8C8C"
      />
    </svg>
  )
}

export default VoiceIcon
